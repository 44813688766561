import { useComponentList } from "../../../../lib/use-component-list";
import { useAppContext } from "../../context";
import { ComponentList } from "../../../ComponentList";
import { TooltipProvider } from "@/components/ui/tooltip";

export const Components = () => {
  const { config, state } = useAppContext();

  const componentList = useComponentList(config, state.ui);

  return (
    <TooltipProvider delayDuration={0}>
      {componentList ? componentList : <ComponentList id="all" />}
    </TooltipProvider>
  );
};
