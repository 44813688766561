import React from "react";

const NotFreeCourseContentDialog: React.FC = () => {
  return (
    <div>
      <h2 className="mb-4 text-xl font-semibold text-gray-800">
        Satın alanlara özel içerik
      </h2>
      <p className="text-gray-600">
        Bu içeriğe ve daha fazlasına erişmek için dersi satın al!
      </p>
    </div>
  );
};

export default NotFreeCourseContentDialog;
