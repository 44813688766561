"use client";

import React from "react";
import { useToast } from "@/components/ui/use-toast";
import { graphql, useMutation } from "react-relay";
import { DeleteWebPageComponentTemplateDialogMutation } from "@generated/DeleteWebPageComponentTemplateDialogMutation.graphql";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

const DeleteWebPageComponentTemplateDialog: React.FC<{
  webPageComponentTemplateId: string;
  onWebPageComponentTemplateDeleted: () => void;
  organizationId: string;
  componentType: string;
}> = ({
  webPageComponentTemplateId,
  onWebPageComponentTemplateDeleted,
  organizationId,
  componentType,
}) => {
  const { toast } = useToast();

  const [
    commitDeleteWebPageComponentTemplate,
    isDeleteWebPageComponentTemplateFlight,
  ] = useMutation<DeleteWebPageComponentTemplateDialogMutation>(graphql`
    mutation DeleteWebPageComponentTemplateDialogMutation(
      $input: DeleteWebPageComponentTemplateInput!
    ) {
      deleteWebPageComponentTemplate(input: $input) {
        deletedWebPageComponentTemplateId
        userErrors {
          code
          field
          message
        }
      }
    }
  `);

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Şablonun Silmek İstediğine Emin Misin?</DialogTitle>
        <DialogDescription>
          Eğer vazgeçersen bu şablonu tekrar yaratman gerekir.
        </DialogDescription>
      </DialogHeader>

      <DialogFooter>
        <Button
          className="w-full"
          variant="destructive"
          loading={isDeleteWebPageComponentTemplateFlight}
          onClick={() => {
            if (isDeleteWebPageComponentTemplateFlight) {
              return;
            }

            commitDeleteWebPageComponentTemplate({
              variables: {
                input: {
                  webPageComponentTemplateId: webPageComponentTemplateId,
                },
              },
              updater: (store, mutationData) => {
                if (
                  mutationData === null ||
                  mutationData === undefined ||
                  !mutationData.deleteWebPageComponentTemplate
                    .deletedWebPageComponentTemplateId
                ) {
                  return;
                }

                const organizationRecord = store.get(organizationId);

                if (!organizationRecord) {
                  return;
                }

                const webPageComponentTemplates =
                  organizationRecord.getLinkedRecords(
                    "webPageComponentTemplates",
                    { component: componentType },
                  );

                if (!webPageComponentTemplates) {
                  return;
                }

                const newWebPageComponentTemplates =
                  webPageComponentTemplates.filter(
                    (webPageComponentTemplate) =>
                      webPageComponentTemplate &&
                      webPageComponentTemplate.getDataID() !==
                        mutationData.deleteWebPageComponentTemplate
                          .deletedWebPageComponentTemplateId,
                  );

                organizationRecord.setLinkedRecords(
                  newWebPageComponentTemplates,
                  "webPageComponentTemplates",
                  { component: componentType },
                );
              },
              onCompleted: (data) => {
                if (data.deleteWebPageComponentTemplate.userErrors.length > 0) {
                  toast({
                    title: "Şablonu silinemedi.",
                    description: `Lütfen tekrar dene. Hata mesajı: ${
                      data.deleteWebPageComponentTemplate.userErrors[0]!.message
                    }`,
                  });
                } else {
                  onWebPageComponentTemplateDeleted();
                }
              },
            });
          }}
        >
          Şablonu Sil
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default DeleteWebPageComponentTemplateDialog;
