"use client";

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { DragDropContext, DragStart, DragUpdate } from "@hello-pangea/dnd";

import type { AppState, Config, Data, UiState } from "../../types/Config";
import { Button } from "../Button";

import { Plugin } from "../../types/Plugin";
import { usePlaceholderStyle } from "../../lib/use-placeholder-style";

import { SidebarSection } from "../SidebarSection";
import {
  ChevronDown,
  ChevronUp,
  Globe,
  PanelLeft,
  PanelRight,
} from "lucide-react";
import { Heading } from "../Heading";
import { IconButton } from "../IconButton/IconButton";
import { DropZoneProvider } from "../DropZone";
import { ItemSelector, getItem } from "../../lib/get-item";
import { StateReducer, createReducer } from "../../reducer";
import { flushZones } from "../../lib/flush-zones";
import getClassNameFactory from "../../lib/get-class-name-factory";
import { AppProvider, defaultAppState } from "./context";
import { useResolvedData } from "../../lib/use-resolved-data";
import { MenuBar } from "../MenuBar";
import styles from "./styles.module.css";
import { Fields } from "./components/Fields";
import { Components } from "./components/Components";
import { Preview } from "./components/Preview";
import { Outline } from "./components/Outline";
import { Overrides } from "../../types/Overrides";
import { loadOverrides } from "../../lib/load-overrides";
import { usePuckHistory } from "../../lib/use-puck-history";
import { useHistoryStore } from "../../lib/use-history-store";
import InsertDialog from "@/website-editor/core/components/InsertDialog";
import { cn, hex2hsl } from "@/lib/utils";

const getClassName = getClassNameFactory("Puck", styles);
const getLayoutClassName = getClassNameFactory("PuckLayout", styles);

const defaultRender: React.FC<React.PropsWithChildren> = React.memo(
  ({ children }) => <>{children}</>,
);

defaultRender.displayName = "DefaultRender";

export function Puck<
  UserConfig extends Config<any, any, any> = Config<any, any, any>,
>({
  children,
  config,
  data: initialData = { content: [], root: { props: {} } },
  ui: initialUi = defaultAppState.ui,
  onChange,
  onPublish,
  plugins = [],
  overrides = {},
  headerTitle,
  headerPath,
}: {
  children?: ReactNode;
  config: UserConfig;
  data: Partial<Data>;
  ui?: Partial<UiState>;
  onChange?: (data: Data) => void;
  onPublish?: (data: Data) => void;
  plugins?: Plugin[];
  overrides?: Partial<Overrides>;
  headerTitle?: string;
  headerPath?: string;
}) {
  const historyStore = useHistoryStore();

  const [reducer] = useState(() =>
    createReducer<UserConfig>({ config, record: historyStore.record }),
  );

  const rootProps = initialData?.root?.props || {};

  const defaultedRootProps = {
    ...config.root?.defaultProps,
    ...rootProps,
  };

  const [initialAppState] = useState<AppState>(() => ({
    ...defaultAppState,
    data: {
      ...initialData,
      root: { ...initialData?.root, props: defaultedRootProps },
      content: initialData.content || [],
    },
    ui: {
      ...defaultAppState.ui,
      ...initialUi,
      // Store categories under componentList on state to allow render functions and plugins to modify
      componentList: config.categories
        ? Object.entries(config.categories).reduce(
            (acc, [categoryName, category]) => {
              return {
                ...acc,
                [categoryName]: {
                  title: category.title,
                  components: category.components,
                  expanded: category.defaultExpanded,
                  visible: category.visible,
                },
              };
            },
            {},
          )
        : {},
    },
  }));

  const [appState, dispatch] = useReducer<StateReducer>(
    reducer,
    flushZones(initialAppState),
  );

  const { data, ui } = appState;

  const history = usePuckHistory({ dispatch, initialAppState, historyStore });

  const { resolveData, componentState } = useResolvedData(
    appState,
    config,
    dispatch,
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const { itemSelector, leftSideBarVisible, rightSideBarVisible } = ui;

  const setItemSelector = useCallback(
    (newItemSelector: ItemSelector | null) => {
      if (newItemSelector === itemSelector) return;

      dispatch({
        type: "setUi",
        ui: { itemSelector: newItemSelector },
        recordHistory: true,
      });
    },
    [itemSelector],
  );

  const selectedItem = itemSelector ? getItem(itemSelector, data) : null;

  useEffect(() => {
    if (onChange) onChange(data);
  }, [data]);

  const { onDragStartOrUpdate, placeholderStyle } = usePlaceholderStyle();

  const [draggedItem, setDraggedItem] = useState<
    DragStart & Partial<DragUpdate>
  >();

  const toggleSidebars = useCallback(
    (sidebar: "left" | "right") => {
      const widerViewport = window.matchMedia("(min-width: 638px)").matches;
      const sideBarVisible =
        sidebar === "left" ? leftSideBarVisible : rightSideBarVisible;
      const oppositeSideBar =
        sidebar === "left" ? "rightSideBarVisible" : "leftSideBarVisible";

      dispatch({
        type: "setUi",
        ui: {
          [`${sidebar}SideBarVisible`]: !sideBarVisible,
          ...(!widerViewport ? { [oppositeSideBar]: false } : {}),
        },
      });
    },
    [dispatch, leftSideBarVisible, rightSideBarVisible],
  );

  useEffect(() => {
    if (!window.matchMedia("(min-width: 638px)").matches) {
      dispatch({
        type: "setUi",
        ui: {
          leftSideBarVisible: false,
          rightSideBarVisible: false,
        },
      });
    }

    const handleResize = () => {
      if (!window.matchMedia("(min-width: 638px)").matches) {
        dispatch({
          type: "setUi",
          ui: (ui) => ({
            ...ui,
            ...(ui.rightSideBarVisible ? { leftSideBarVisible: false } : {}),
          }),
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Load all plugins into the overrides
  const loadedOverrides = useMemo(() => {
    return loadOverrides({ overrides, plugins });
  }, [plugins]);

  const CustomPuck = useMemo(
    () => loadedOverrides.puck || defaultRender,
    [loadedOverrides],
  );

  const CustomPreview = useMemo(
    () => loadedOverrides.preview || defaultRender,
    [loadedOverrides.preview],
  );
  const CustomHeader = useMemo(
    () => loadedOverrides.header || defaultRender,
    [loadedOverrides.header],
  );
  const CustomHeaderActions = useMemo(
    () => loadedOverrides.headerActions || defaultRender,
    [loadedOverrides.headerActions],
  );

  const disableZoom = children || loadedOverrides.puck ? true : false;

  const [elementToInsert, setElementToInsert] = useState<{
    componentType: string;
    destinationIndex: number;
    destinationZone: string;
  } | null>(null);

  const resetElementToInsert = useCallback(() => {
    setElementToInsert(null);
  }, []);

  const colors: Record<string, string> =
    appState.data.root.props && "colors" in appState.data.root.props
      ? (appState.data.root.props.colors as Record<string, string>)
      : {};

  const fonts: Record<string, string> =
    appState.data.root.props && "fonts" in appState.data.root.props
      ? (appState.data.root.props.fonts as Record<string, string>)
      : {};

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (fonts.sans) {
      const cssId = "google-font-" + fonts.sans.replace(/ /g, "-");
      if (!document.getElementById(cssId) && fonts.sans !== "Inter") {
        const link = document.createElement("link");
        link.id = cssId;
        link.rel = "stylesheet";
        link.href =
          "https://fonts.googleapis.com/css2?family=" +
          fonts.sans +
          ":wght@400;600;700&display=swap";
        document.head.appendChild(link);
      }
    }
    if (fonts.display) {
      const cssId = "google-font-" + fonts.display.replace(/ /g, "-");
      if (!document.getElementById(cssId) && fonts.display !== "Inter") {
        const link = document.createElement("link");
        link.id = cssId;
        link.rel = "stylesheet";
        link.href =
          "https://fonts.googleapis.com/css2?family=" +
          fonts.display +
          ":wght@400;600;700&display=swap";
        document.head.appendChild(link);
      }
    }
  }, [fonts.sans, fonts.display]);

  return (
    <div className={`Puck ${getClassName()}`}>
      <AppProvider
        value={{
          state: appState,
          dispatch,
          config,
          componentState,
          resolveData,
          plugins,
          overrides: loadedOverrides,
          history,
        }}
      >
        <DragDropContext
          onDragUpdate={(update) => {
            setDraggedItem({ ...draggedItem, ...update });
            onDragStartOrUpdate(update);
          }}
          onBeforeDragStart={(start) => {
            onDragStartOrUpdate(start);
            setItemSelector(null);
            dispatch({ type: "setUi", ui: { isDragging: true } });
          }}
          onDragEnd={(droppedItem) => {
            setDraggedItem(undefined);
            dispatch({ type: "setUi", ui: { isDragging: false } });

            // User cancel drag
            if (!droppedItem.destination) {
              return;
            }

            // New component
            if (
              droppedItem.source.droppableId.startsWith("component-list") &&
              droppedItem.destination
            ) {
              const [_, componentType] = droppedItem.draggableId.split("::");

              setElementToInsert({
                componentType: componentType || droppedItem.draggableId,
                destinationIndex: droppedItem.destination!.index,
                destinationZone: droppedItem.destination.droppableId,
              });

              return;
            } else {
              const { source, destination } = droppedItem;

              if (source.droppableId === destination.droppableId) {
                dispatch({
                  type: "reorder",
                  sourceIndex: source.index,
                  destinationIndex: destination.index,
                  destinationZone: destination.droppableId,
                });
              } else {
                dispatch({
                  type: "move",
                  sourceZone: source.droppableId,
                  sourceIndex: source.index,
                  destinationIndex: destination.index,
                  destinationZone: destination.droppableId,
                });
              }

              setItemSelector({
                index: destination.index,
                zone: destination.droppableId,
              });
            }
          }}
        >
          <DropZoneProvider
            value={{
              data,
              itemSelector,
              setItemSelector,
              config,
              dispatch,
              draggedItem,
              placeholderStyle,
              mode: "edit",
              areaId: "root",
              disableZoom,
            }}
          >
            <CustomPuck>
              {children || (
                <div
                  className={getLayoutClassName({
                    leftSideBarVisible,
                    menuOpen,
                    mounted,
                    rightSideBarVisible,
                    disableZoom,
                  })}
                >
                  <div className={getLayoutClassName("inner")}>
                    <CustomHeader
                      actions={
                        <CustomHeaderActions>
                          <Button
                            onClick={() => {
                              onPublish && onPublish(data);
                            }}
                            icon={<Globe size="14px" />}
                          >
                            Publish
                          </Button>
                        </CustomHeaderActions>
                      }
                    >
                      <header className={getLayoutClassName("header")}>
                        <div className={getLayoutClassName("headerInner")}>
                          <div className={getLayoutClassName("headerToggle")}>
                            <div
                              className={getLayoutClassName(
                                "leftSideBarToggle",
                              )}
                            >
                              <IconButton
                                onClick={() => {
                                  toggleSidebars("left");
                                }}
                                title="Toggle left sidebar"
                              >
                                <PanelLeft focusable="false" />
                              </IconButton>
                            </div>
                            <div
                              className={getLayoutClassName(
                                "rightSideBarToggle",
                              )}
                            >
                              <IconButton
                                onClick={() => {
                                  toggleSidebars("right");
                                }}
                                title="Toggle right sidebar"
                              >
                                <PanelRight focusable="false" />
                              </IconButton>
                            </div>
                          </div>
                          <div className={getLayoutClassName("headerTitle")}>
                            <Heading rank={2} size="xs">
                              {headerTitle || "Page"}
                              {headerPath && (
                                <>
                                  {" "}
                                  <code
                                    className={getLayoutClassName("headerPath")}
                                  >
                                    {headerPath}
                                  </code>
                                </>
                              )}
                            </Heading>
                          </div>
                          <div className={getLayoutClassName("headerTools")}>
                            <div className={getLayoutClassName("menuButton")}>
                              <IconButton
                                onClick={() => {
                                  return setMenuOpen(!menuOpen);
                                }}
                                title="Toggle menu bar"
                              >
                                {menuOpen ? (
                                  <ChevronUp focusable="false" />
                                ) : (
                                  <ChevronDown focusable="false" />
                                )}
                              </IconButton>
                            </div>
                            <MenuBar
                              appState={appState}
                              data={data}
                              dispatch={dispatch}
                              menuOpen={menuOpen}
                              renderHeaderActions={() => (
                                <CustomHeaderActions>
                                  <Button
                                    onClick={() => {
                                      onPublish && onPublish(data);
                                    }}
                                    icon={<Globe size="14px" />}
                                  >
                                    Publish
                                  </Button>
                                </CustomHeaderActions>
                              )}
                              setMenuOpen={setMenuOpen}
                            />
                          </div>
                        </div>
                      </header>
                    </CustomHeader>
                    <div className={getLayoutClassName("leftSideBar")}>
                      <SidebarSection title="Bloklar" noBorderTop>
                        <Components />
                      </SidebarSection>
                      <SidebarSection title="Sayfa Özeti">
                        <Outline />
                      </SidebarSection>
                    </div>
                    <div
                      className={getClassName("frame")}
                      onClick={() => setItemSelector(null)}
                    >
                      <div
                        className={cn(
                          getClassName("root"),
                          "font-sans [&_dt]:font-display [&_h1]:font-display [&_h2]:font-display [&_h3]:font-display [&_h4]:font-display [&_h5]:font-display [&_h6]:font-display",
                        )}
                        style={{
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          "--primary": colors.primary
                            ? hex2hsl(colors.primary)
                            : undefined,
                          "--background": colors.background
                            ? hex2hsl(colors.background)
                            : undefined,
                          "--foreground": colors.foreground
                            ? hex2hsl(colors.foreground)
                            : undefined,
                          "--border": colors.border
                            ? hex2hsl(colors.border)
                            : undefined,
                          "--primary-dark": colors.primaryDark
                            ? hex2hsl(colors.primaryDark)
                            : undefined,
                          "--background-dark": colors.backgroundDark
                            ? hex2hsl(colors.backgroundDark)
                            : undefined,
                          "--foreground-dark": colors.foregroundDark
                            ? hex2hsl(colors.foregroundDark)
                            : undefined,
                          "--border-dark": colors.borderDark
                            ? hex2hsl(colors.borderDark)
                            : undefined,
                          "--font-sans":
                            fonts.sans && fonts.sans !== "Inter"
                              ? fonts.sans
                              : undefined,
                          "--font-display":
                            fonts.display && fonts.display !== "Inter"
                              ? fonts.display
                              : undefined,
                        }}
                      >
                        <CustomPreview>
                          <Preview />
                        </CustomPreview>
                      </div>
                      {/* Fill empty space under root */}
                      <div
                        style={{
                          background: "var(--puck-color-grey-11)",
                          height: "100%",
                          flexGrow: 1,
                        }}
                      ></div>
                    </div>
                    <div className={getLayoutClassName("rightSideBar")}>
                      <SidebarSection
                        noPadding
                        noBorderTop
                        showBreadcrumbs
                        title={selectedItem ? selectedItem.type : "Page"}
                      >
                        <Fields />
                      </SidebarSection>
                    </div>
                  </div>
                </div>
              )}
            </CustomPuck>
          </DropZoneProvider>
        </DragDropContext>
        <InsertDialog
          elementToInsert={elementToInsert}
          resetElementToInsert={resetElementToInsert}
        />
      </AppProvider>
      <div id="puck-portal-root" />
    </div>
  );
}

Puck.Components = Components;
Puck.Fields = Fields;
Puck.Outline = Outline;
Puck.Preview = Preview;
