import { cva } from "class-variance-authority";

export type FontWeight =
  | "thin"
  | "extralight"
  | "light"
  | "normal"
  | "medium"
  | "semibold"
  | "bold"
  | "extrabold"
  | "black";

export const FontWeightField = {
  type: "select" as const,
  label: "Font Ağırlığı",
  options: [
    {
      value: "thin",
      label: "En İnce",
    },
    {
      value: "extralight",
      label: "Ekstra İnce",
    },
    {
      value: "light",
      label: "İnce",
    },
    {
      value: "normal",
      label: "Normal",
    },
    {
      value: "medium",
      label: "Orta",
    },
    {
      value: "semibold",
      label: "Yarı Kalın",
    },
    {
      value: "bold",
      label: "Kalın",
    },
    {
      value: "extrabold",
      label: "Ekstra Kalın",
    },
    {
      value: "black",
      label: "En Kalın",
    },
  ],
};

export const fontWeightVariants = cva(undefined, {
  variants: {
    fontWeight: {
      thin: "font-thin",
      extralight: "font-extralight",
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
      extrabold: "font-extrabold",
      black: "font-black",
    },
  },
  defaultVariants: {
    fontWeight: "normal",
  },
});
