"use client";

import { CustomField } from "@/website-editor/core";
import { DateTimePicker } from "@/components/ui/date-time-picker/date-time-picker";
import { CalendarIcon } from "@radix-ui/react-icons";
import React from "react";

interface DateCustomFieldProps {}

const FieldRenderer: React.FC<
  Parameters<CustomField<DateCustomFieldProps>["render"]>[0]
> = ({ id, field, name, value, onChange, readOnly }) => {
  return (
    <label>
      <div className="mb-2 flex items-center">
        <CalendarIcon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      <DateTimePicker
        hourCycle={24}
        granularity="minute"
        value={value ?? null}
        isReadOnly={readOnly}
        onChange={(value) =>
          onChange(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value
              .toDate(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .toISOString(),
          )
        }
        label={name || "Tarih"}
      />
    </label>
  );
};

export const DateCustomField = {
  type: "custom",
  render: (props) => <FieldRenderer {...props} />,
} satisfies CustomField<DateCustomFieldProps>;
