import { ComponentConfig } from "@/website-editor/core";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import { Padding, PaddingField } from "@/website-editor/custom-fields/padding";

import React from "react";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { ButtonProps, ButtonsArrayField } from "@/website-editor/blocks/button";
import { Rounded, RoundedField } from "@/website-editor/custom-fields/rounded";
import NavigationSectionComponent from "./NavigationSection";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";

type NavigationBarItem = {
  title: string;
  isMenu?: boolean;
  href?: string;
  show: "always" | "logged-in" | "logged-out";
  subItems?: Array<{
    title: string;
    href: string;
    show: "always" | "logged-in" | "logged-out";
  }>;
};

export type NavigationSectionProps = {
  sectionId?: string;
  logo: {
    url: string;
    alt?: string;
  } | null;
  logoRoundness: Rounded;
  navigationBarItems: Array<NavigationBarItem>;
  buttons: Array<ButtonProps & { show: "always" | "logged-in" | "logged-out" }>;
  theme: Theme;
  logoUrl: string;
  isFloating?: boolean;
  harmony: HarmonyValue;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const NavigationSection = {
  displayName: "Navigasyon Barı",
  fields: {
    sectionId: SectionIdField,
    logo: {
      ...ImageCustomField,
      label: "Logo",
    },
    logoRoundness: {
      ...RoundedField,
      label: "Logo Yuvarlaklığı",
    },
    logoUrl: {
      type: "text",
      label: "Logo URL",
    },
    isFloating: {
      type: "radio",
      label: "Yukarı scroll edildiğinde otomatik görünür olsun mu?",
      options: [
        { label: "Evet", value: true },
        { label: "Hayır", value: false },
      ],
    },
    navigationBarItems: {
      type: "array",
      label: "Navigasyon Barı Öğeleri",
      getItemSummary: (item, idx) => item.title || `Öğe ${(idx ?? 0) + 1}`,
      arrayFields: {
        title: {
          type: "textarea",
          label: "Başlık",
        },
        isMenu: {
          type: "radio",
          label: "Alt linkleri var mı?",
          options: [
            { label: "Evet", value: true },
            { label: "Hayır", value: false },
          ],
        },
        href: {
          type: "text",
          label: "URL Linki",
          showIf: (d) => {
            const data = d as unknown as NavigationBarItem;

            return !data.isMenu;
          },
        },
        subItems: {
          type: "array",
          label: "Alt Öğeler",
          showIf: (d) => {
            const data = d as unknown as NavigationBarItem;

            return Boolean(data.isMenu);
          },
          getItemSummary: (item, idx) => item.title || `Öğe ${(idx ?? 0) + 1}`,
          arrayFields: {
            title: {
              type: "textarea",
              label: "Başlık",
            },
            href: {
              type: "text",
              label: "URL Linki",
            },
          },
        },
        show: {
          type: "radio",
          label: "Görünüm",
          options: [
            { label: "Her Zaman", value: "always" },
            { label: "Giriş Yapıldığında", value: "logged-in" },
            { label: "Çıkış Yapıldığında", value: "logged-out" },
          ],
        },
      },
      defaultItemProps: {
        title: "Yeni Öğe",
        href: "#",
        show: "always",
      },
    },
    buttons: {
      ...ButtonsArrayField,
      arrayFields: {
        ...ButtonsArrayField.arrayFields,
        show: {
          type: "radio",
          label: "Görünüm",
          options: [
            { label: "Her Zaman", value: "always" },
            { label: "Giriş Yapıldığında", value: "logged-in" },
            { label: "Çıkış Yapıldığında", value: "logged-out" },
          ],
        },
      },
      defaultItemProps: {
        ...ButtonsArrayField.defaultItemProps,
        show: "always",
      },
    },
    theme: ThemeField,
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      defaultExpanded: true,
      fields: [
        "sectionId",
        "logo",
        "logoRoundness",
        "logoUrl",
        "navigationBarItems",
        "buttons",
        "isFloating",
        "theme",
        "harmony",
      ],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: ["paddingTop", "paddingBottom", "marginLeft", "marginRight"],
    },
  },
  defaultProps: {
    sectionId: "",
    logo: null,
    logoRoundness: "sm",
    logoUrl: "",
    navigationBarItems: [
      {
        title: "Hakkımızda",
        href: "#",
        show: "always",
        isMenu: false,
      },
      {
        title: "Derslerimiz",
        href: "#",
        show: "always",
        isMenu: false,
      },
      {
        title: "Öğrencilerimiz",
        href: "#",
        show: "always",
        isMenu: false,
      },
    ],
    buttons: [
      {
        url: "#",
        size: "lg",
        label: "Bize Ulaşın",
        variant: "default",
        show: "always",
      },
      {
        url: "#",
        size: "lg",
        label: "Kayıt Ol",
        variant: "default",
        show: "logged-out",
      },
    ],
    theme: "light",
    isFloating: true,
    harmony: [],
    paddingTop: "md",
    paddingBottom: "md",
    marginLeft: "md",
    marginRight: "md",
  },
  render: ({ puck: { isEditing }, ...props }) => {
    return <NavigationSectionComponent {...props} isEditing={isEditing} />;
  },
} satisfies ComponentConfig<NavigationSectionProps>;
