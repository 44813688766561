/**
 * @generated SignedSource<<a146d5e162077dea5d518cfd0badf883>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "AED" | "EUR" | "GBP" | "KWD" | "QAR" | "SAR" | "TRY" | "USD" | "%future added value";
export type PurchasePriceAndActionsQuery$variables = {
  id: string;
};
export type PurchasePriceAndActionsQuery$data = {
  readonly node: {
    readonly course?: {
      readonly organization: {
        readonly slug: string;
      };
      readonly slug: string;
    };
    readonly discountedPrice?: {
      readonly amount: string;
      readonly currency: Currency;
    } | null | undefined;
    readonly id?: string;
    readonly originalPrice?: {
      readonly amount: string;
      readonly currency: Currency;
    } | null | undefined;
    readonly remainingCapacity?: number | null | undefined;
    readonly slug?: string;
    readonly viewerCanOrder?: boolean | null | undefined;
    readonly viewerNextItem?: {
      readonly slug: string;
    } | null | undefined;
    readonly viewerStudent?: {
      readonly accessExpiresAt: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type PurchasePriceAndActionsQuery = {
  response: PurchasePriceAndActionsQuery$data;
  variables: PurchasePriceAndActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanOrder",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Money",
  "kind": "LinkedField",
  "name": "originalPrice",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Money",
  "kind": "LinkedField",
  "name": "discountedPrice",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessExpiresAt",
  "storageKey": null
},
v9 = [
  (v3/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingCapacity",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchasePriceAndActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseAcademicTermStudent",
                "kind": "LinkedField",
                "name": "viewerStudent",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "viewerNextItem",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "course",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "CourseAcademicTerm",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchasePriceAndActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseAcademicTermStudent",
                "kind": "LinkedField",
                "name": "viewerStudent",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "viewerNextItem",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "course",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "CourseAcademicTerm",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c9b2ef997d304f994a94563732e3721",
    "id": null,
    "metadata": {},
    "name": "PurchasePriceAndActionsQuery",
    "operationKind": "query",
    "text": "query PurchasePriceAndActionsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CourseAcademicTerm {\n      id\n      slug\n      viewerCanOrder\n      originalPrice {\n        amount\n        currency\n      }\n      discountedPrice {\n        amount\n        currency\n      }\n      viewerStudent {\n        accessExpiresAt\n        id\n      }\n      viewerNextItem {\n        __typename\n        slug\n        id\n      }\n      course {\n        organization {\n          slug\n          id\n        }\n        slug\n        id\n      }\n      remainingCapacity\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ec8434f413406ea5fcf6d9e7038757a";

export default node;
