import React, { CSSProperties } from "react";
import { rootDroppableId } from "../../lib/root-droppable-id";
import { Config, Data } from "../../types/Config";
import { setupZone } from "../../lib/setup-zone";
import { hex2hsl } from "@/lib/utils";
import Script from "next/script";

type DropZoneRenderProps = {
  zone: string;
  data: Data;
  config: Config;
  areaId?: string;
  style?: CSSProperties;
  className?: string;
};

function DropZoneRender({
  zone,
  data,
  areaId = "root",
  config,
  className,
  style,
}: DropZoneRenderProps) {
  let zoneCompound = rootDroppableId;
  let content = data?.content || [];

  if (!data || !config) {
    return null;
  }

  if (areaId && zone && zone !== rootDroppableId) {
    zoneCompound = `${areaId}:${zone}`;
    content = setupZone(data, zoneCompound).zones[zoneCompound]!;
  }

  return (
    <div className={className} style={style}>
      {content.map((item) => {
        const Component = config.components[item.type];

        if (Component) {
          return (
            <Component.render
              key={item.props.id}
              {...item.props}
              puck={{
                renderDropZone: ({
                  zone,
                  className,
                }: {
                  zone: string;
                  className?: string;
                }) => (
                  <DropZoneRender
                    zone={zone}
                    className={className}
                    data={data}
                    areaId={item.props.id}
                    config={config}
                  />
                ),
                isEditing: false,
              }}
            />
          );
        }

        return null;
      })}
    </div>
  );
}

export function Render<
  UserConfig extends Config<any, any, any> = Config<any, any, any>,
>({ config, data }: { config: UserConfig; data: Data }) {
  const colors: Record<string, string> =
    data.root.props && "colors" in data.root.props
      ? (data.root.props.colors as Record<string, string>)
      : {};

  const fonts: Record<string, string> =
    data.root.props && "fonts" in data.root.props
      ? (data.root.props.fonts as Record<string, string>)
      : {};

  return (
    <>
      <Script
        id={`load-fonts-webpage-builder-${fonts.sans}-${fonts.display}`}
        dangerouslySetInnerHTML={{
          __html: ` (function(f){
        for (var i = 0; i < f.length; i++) {
          if (f[i]) {
            const cssId = 'google-font-' + f[i].replace(/ /g, '-');
            if (!document.getElementById(cssId) && f[i] !== 'Inter') {
              const link = document.createElement('link');
              link.id = cssId;
              link.rel = 'stylesheet';
              link.href = 'https://fonts.googleapis.com/css2?family=' + f[i] + ':wght@400;600;700&display=swap';
              document.head.appendChild(link);
            }
          }
        }
    })(${JSON.stringify([fonts.sans, fonts.display])});`,
        }}
      />
      {config.root?.render ? (
        <config.root.render
          {...data.root.props}
          puck={{
            renderDropZone: ({
              zone,
              className,
            }: {
              zone: string;
              className?: string;
            }) => (
              <DropZoneRender
                zone={zone}
                data={data}
                config={config}
                className={className}
              />
            ),
          }}
          title={data.root.props?.title || ""}
          id={"puck-root"}
          style={
            {
              "--primary": colors.primary ? hex2hsl(colors.primary) : undefined,
              "--background": colors.background
                ? hex2hsl(colors.background)
                : undefined,
              "--foreground": colors.foreground
                ? hex2hsl(colors.foreground)
                : undefined,
              "--border": colors.border ? hex2hsl(colors.border) : undefined,
              "--primary-dark": colors.primaryDark
                ? hex2hsl(colors.primaryDark)
                : undefined,
              "--background-dark": colors.backgroundDark
                ? hex2hsl(colors.backgroundDark)
                : undefined,
              "--foreground-dark": colors.foregroundDark
                ? hex2hsl(colors.foregroundDark)
                : undefined,
              "--border-dark": colors.borderDark
                ? hex2hsl(colors.borderDark)
                : undefined,
              "--font-sans":
                fonts.sans && fonts.sans !== "Inter" ? fonts.sans : undefined,
              "--font-display":
                fonts.display && fonts.display !== "Inter"
                  ? fonts.display
                  : undefined,
            } as CSSProperties
          }
          className="font-sans [&_dt]:font-display [&_h1]:font-display [&_h2]:font-display [&_h3]:font-display [&_h4]:font-display [&_h5]:font-display [&_h6]:font-display"
        >
          <DropZoneRender config={config} data={data} zone={rootDroppableId} />
        </config.root.render>
      ) : (
        <DropZoneRender
          config={config}
          data={data}
          zone={rootDroppableId}
          style={
            {
              "--primary": colors.primary ? hex2hsl(colors.primary) : undefined,
              "--background": colors.background
                ? hex2hsl(colors.background)
                : undefined,
              "--foreground": colors.foreground
                ? hex2hsl(colors.foreground)
                : undefined,
              "--border": colors.border ? hex2hsl(colors.border) : undefined,
              "--primary-dark": colors.primaryDark
                ? hex2hsl(colors.primaryDark)
                : undefined,
              "--background-dark": colors.backgroundDark
                ? hex2hsl(colors.backgroundDark)
                : undefined,
              "--foreground-dark": colors.foregroundDark
                ? hex2hsl(colors.foregroundDark)
                : undefined,
              "--border-dark": colors.borderDark
                ? hex2hsl(colors.borderDark)
                : undefined,
              "--font-sans":
                fonts.sans && fonts.sans !== "Inter" ? fonts.sans : undefined,
              "--font-display":
                fonts.display && fonts.display !== "Inter"
                  ? fonts.display
                  : undefined,
            } as CSSProperties
          }
          className="font-sans [&_dt]:font-display [&_h1]:font-display [&_h2]:font-display [&_h3]:font-display [&_h4]:font-display [&_h5]:font-display [&_h6]:font-display"
        />
      )}
    </>
  );
}
