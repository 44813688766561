/**
 * @generated SignedSource<<235836f56e5af709d15be0589079a834>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type OtpChannel = "EMAIL" | "SMS" | "WHATSAPP" | "%future added value";
export type SendLoginOtpInput = {
  channel: OtpChannel;
  identifier: string;
  organizationId: string;
};
export type EmailOtpLoginSendLoginOtpMutation$variables = {
  input: SendLoginOtpInput;
};
export type EmailOtpLoginSendLoginOtpMutation$data = {
  readonly sendLoginOtp: {
    readonly loginOtp: {
      readonly channel: OtpChannel;
      readonly email: string | null | undefined;
      readonly validUntil: string;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type EmailOtpLoginSendLoginOtpMutation = {
  response: EmailOtpLoginSendLoginOtpMutation$data;
  variables: EmailOtpLoginSendLoginOtpMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendLoginOtpPayload",
    "kind": "LinkedField",
    "name": "sendLoginOtp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LoginOtp",
        "kind": "LinkedField",
        "name": "loginOtp",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "channel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validUntil",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "userErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailOtpLoginSendLoginOtpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailOtpLoginSendLoginOtpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "89b5383f24422a8e6935230378297548",
    "id": null,
    "metadata": {},
    "name": "EmailOtpLoginSendLoginOtpMutation",
    "operationKind": "mutation",
    "text": "mutation EmailOtpLoginSendLoginOtpMutation(\n  $input: SendLoginOtpInput!\n) {\n  sendLoginOtp(input: $input) {\n    loginOtp {\n      email\n      channel\n      validUntil\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "005614f8580859669bef559da838612c";

export default node;
