import { FieldLabelInternal, type InputProps } from "../..";
import { ExternalField as ExternalFieldType } from "../../../../types/Fields";
import { ExternalInput } from "../../../ExternalInput";
import { Link } from "lucide-react";

export const ExternalField = ({
  field,
  onChange,
  value,
  name,
  label,
  id,
}: InputProps) => {
  const validField = field as ExternalFieldType<any>;

  if (field.type !== "external") {
    return null;
  }

  return (
    <FieldLabelInternal
      label={label || name}
      icon={<Link size={16} />}
      el="div"
    >
      <ExternalInput
        name={name}
        field={{
          ...validField,
          placeholder: validField.placeholder || "Select data",
          mapProp: validField.mapProp,
          mapRow: validField.mapRow,
          fetchList: validField.fetchList,
        }}
        onChange={onChange}
        value={value}
        id={id}
      />
    </FieldLabelInternal>
  );
};
