import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export const Loader: React.FC<
  HTMLAttributes<HTMLSpanElement> & {
    color?: string;
    size?: number;
  }
> = ({ color, size = 16, className, ...props }) => {
  return (
    <span
      className={cn(
        "inline-block animate-spin rounded-full border-2 border-solid border-current border-b-transparent bg-transparent fill-mode-both",
        className,
      )}
      style={{
        width: size,
        height: size,
        color,
      }}
      aria-label="loading"
      {...props}
    />
  );
};
