import { cva } from "class-variance-authority";

export type Gap =
  | "none"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";

export const GapField = {
  type: "select" as const,
  label: "Gap",
  options: [
    {
      value: "none",
      label: "0",
    },
    {
      value: "xs",
      label: "xs",
    },
    {
      value: "sm",
      label: "Küçük",
    },
    {
      value: "md",
      label: "Orta",
    },
    {
      value: "lg",
      label: "Büyük",
    },
    {
      value: "xl",
      label: "XL",
    },
    {
      value: "2xl",
      label: "XXL",
    },
    {
      value: "3xl",
      label: "3XL",
    },
    {
      value: "4xl",
      label: "4XL",
    },
    {
      value: "5xl",
      label: "5XL",
    },
    {
      value: "6xl",
      label: "6XL",
    },
    {
      value: "7xl",
      label: "7XL",
    },
    {
      value: "8xl",
      label: "8XL",
    },
    {
      value: "9xl",
      label: "9XL",
    },
  ],
};

export const gapVariants = cva(undefined, {
  variants: {
    size: {
      none: "",
      sm: "",
      xs: "",
      md: "",
      lg: "",
      xl: "",
      "2xl": "",
      "3xl": "",
      "4xl": "",
      "5xl": "",
      "6xl": "",
      "7xl": "",
      "8xl": "",
      "9xl": "",
    },
    side: {
      x: "",
      y: "",
    },
  },
  compoundVariants: [
    {
      size: "none",
      side: "x",
      className: "gap-x-0", // keep empty mx-auto
    },
    {
      size: "none",
      side: "y",
      className: "gap-y-0",
    },
    {
      size: "xs",
      side: "x",
      className: "gap-x-0.5 sm:gap-x-1 xl:gap-x-1.5",
    },
    {
      size: "xs",
      side: "y",
      className: "gap-y-0.5 sm:gap-y-1 xl:gap-y-1.5",
    },
    {
      size: "sm",
      side: "x",
      className: "gap-x-1 sm:gap-x-1.5 xl:gap-x-2",
    },
    {
      size: "sm",
      side: "y",
      className: "gap-y-1 sm:gap-y-1.5 xl:gap-y-2",
    },
    {
      size: "md",
      side: "x",
      className: "gap-x-2 sm:gap-x-3 xl:gap-x-4",
    },
    {
      size: "md",
      side: "y",
      className: "gap-y-2 sm:gap-y-3 xl:gap-y-4",
    },
    {
      size: "lg",
      side: "x",
      className: "gap-x-4 sm:gap-x-6 lg:gap-x-8 xl:gap-x-12",
    },
    {
      size: "lg",
      side: "y",
      className: "gap-y-4 sm:gap-y-6 lg:gap-y-8 xl:gap-y-12",
    },
    {
      size: "xl",
      side: "x",
      className: "gap-x-6 sm:gap-x-10 lg:gap-x-12 xl:gap-x-16",
    },
    {
      size: "xl",
      side: "y",
      className: "gap-y-6 sm:gap-y-10 lg:gap-y-12 xl:gap-y-16",
    },
    {
      size: "2xl",
      side: "x",
      className: "gap-x-8 sm:gap-x-12 lg:gap-x-16 xl:gap-x-20",
    },
    {
      size: "2xl",
      side: "y",
      className: "gap-y-8 sm:gap-y-12 lg:gap-y-16 xl:gap-y-20",
    },
    {
      size: "3xl",
      side: "x",
      className: "gap-x-12 sm:gap-x-16 lg:gap-x-20 xl:gap-x-24",
    },
    {
      size: "3xl",
      side: "y",
      className: "gap-y-12 sm:gap-y-16 lg:gap-y-20 xl:gap-y-24",
    },
    {
      size: "4xl",
      side: "x",
      className: "gap-x-20 sm:gap-x-28 lg:gap-x-36 xl:gap-x-44",
    },
    {
      size: "4xl",
      side: "y",
      className: "gap-y-20 sm:gap-y-28 lg:gap-y-36 xl:gap-y-44",
    },
    {
      size: "5xl",
      side: "x",
      className: "gap-x-24 sm:gap-x-36 lg:gap-x-48",
    },
    {
      size: "5xl",
      side: "y",
      className: "gap-y-24 sm:gap-y-36 lg:gap-y-48",
    },
    {
      size: "6xl",
      side: "x",
      className: "gap-x-28 sm:gap-x-44 lg:gap-x-64",
    },
    {
      size: "6xl",
      side: "y",
      className: "gap-y-28 sm:gap-y-44 lg:gap-y-64",
    },
    {
      size: "7xl",
      side: "x",
      className: "gap-x-32 sm:gap-x-52 lg:gap-x-72",
    },
    {
      size: "7xl",
      side: "y",
      className: "gap-y-32 sm:gap-y-52 lg:gap-y-72",
    },
    {
      size: "8xl",
      side: "x",
      className: "gap-x-36 sm:gap-x-60 lg:gap-x-80",
    },
    {
      size: "8xl",
      side: "y",
      className: "gap-y-36 sm:gap-y-60 lg:gap-y-80",
    },
    {
      size: "9xl",
      side: "x",
      className: "gap-x-40 sm:gap-x-72 lg:gap-x-96",
    },
    {
      size: "9xl",
      side: "y",
      className: "gap-y-40 sm:gap-y-72 lg:gap-y-96",
    },
  ],
  defaultVariants: {},
});
