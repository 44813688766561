import { Type } from "lucide-react";
import { FieldLabelInternal, type InputProps } from "../..";
import dynamic from "next/dynamic";

const PlateTextOnlyEditor = dynamic(
  () => import("@/components/plate-text-only-editor"),
  {
    ssr: false,
    loading: () => <div>Editör Yükleniyor...</div>,
  },
);

export const RichTextareaField = ({
  onChange,
  readOnly,
  value,
  name,
  label,
  id,
}: InputProps) => {
  return (
    <FieldLabelInternal
      label={label || name}
      icon={<Type size={16} />}
      el="div"
      readOnly={readOnly}
    >
      <PlateTextOnlyEditor
        id={id}
        onChangeHtml={onChange}
        initialHtmlValue={value}
        readOnly={readOnly}
        className="mt-3 min-h-48 w-full"
      />
    </FieldLabelInternal>
  );
};
