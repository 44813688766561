import { ComponentConfig } from "@/website-editor/core";
import {
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { cn } from "@/lib/utils";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import * as React from "react";
import { nanoid } from "nanoid/non-secure";
import CarouselComponent from "./Carousel";
import { CarouselItem } from "@/components/ui/carousel";

export type CarouselProps = {
  sectionId?: string;
  title: string;
  theme: Theme;
  maxWidth: "7xl" | "none";
  paddingTop: Padding;
  paddingBottom: Padding;
  spacing: Padding;
  marginLeft: Margin;
  marginRight: Margin;
  slidesOnScreen: 1 | 2 | 3 | 4 | 5 | 6 | "1" | "2" | "3" | "4" | "5" | "6";
  slides: Array<{
    id: string;
  }>;
  arrowsPosition:
    | "none"
    | "inside"
    | "outside"
    | "above-left"
    | "above"
    | "above-right"
    | "below-left"
    | "below"
    | "below-right";
  autoPlay?: boolean;
  autoPlayDelay?: number;
  autoPlayJump?: boolean;
};

export const Carousel = {
  displayName: "Karosel (Carousel)",
  tooltip:
    "Bu bloğu kullanarak birden fazla bloğu kaydırılabilir bir şekilde gösterebilirsiniz. Dilerseniz otomatik kaydırma özelliğini de aktif edebilirsiniz.",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    title: {
      type: "richtextarea",
      label: "Başlık",
    },
    slides: {
      type: "array",
      label: "Bölümler",
      getItemSummary: (item, idx) => `Bölüm ${(idx ?? 0) + 1}`,
      arrayFields: {
        id: {
          type: "custom",
          label: "ID",
          render: () => <></>,
        },
      },
      getDefaultItemProps: () => ({
        id: `Slide-${nanoid()}`,
      }),
    },
    maxWidth: {
      type: "radio",
      label: "Maksimum Genişliği Sınırla",
      options: [
        {
          value: "7xl",
          label: "Evet",
        },
        {
          value: "none",
          label: "Hayır",
        },
      ],
    },
    arrowsPosition: {
      type: "select",
      label: "Slayt Geçiş Okları",
      options: [
        {
          value: "none",
          label: "Gösterme",
        },
        {
          value: "inside",
          label: "İçeride",
        },
        {
          value: "outside",
          label: "Dışarıda",
        },
        {
          value: "above-left",
          label: "Üst Sol",
        },
        {
          value: "above",
          label: "Üstte Ortalı",
        },
        {
          value: "above-right",
          label: "Üst Sağ",
        },
        {
          value: "below-left",
          label: "Alt Sol",
        },
        {
          value: "below",
          label: "Altta Ortalı",
        },
        {
          value: "below-right",
          label: "Alt Sağ",
        },
      ],
    },
    autoPlay: {
      type: "radio",
      label: "Otomatik Zamanlı Slayt Geçişi",
      options: [
        {
          value: true,
          label: "Evet",
        },
        {
          value: false,
          label: "Hayır",
        },
      ],
    },
    autoPlayDelay: {
      type: "number",
      label: "Otomatik Geçiş Süresi (ms)",
      showIf: (props) => props.autoPlay === true,
    },
    autoPlayJump: {
      type: "radio",
      label: "Otomatik Geçiş Animasyonu",
      options: [
        {
          value: true,
          label: "Anında Geçiş",
        },
        {
          value: false,
          label: "Kayarak Geçiş",
        },
      ],
      showIf: (props) => props.autoPlay === true,
    },
    slidesOnScreen: {
      type: "select",
      label: "Ekrandaki slayt sayısı",
      options: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
      ],
    },
    spacing: {
      ...PaddingField,
      label: "Slaytlar arası boşluk",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    title: "Karosel",
    maxWidth: "7xl",
    paddingTop: "lg",
    paddingBottom: "lg",
    slidesOnScreen: 4,
    spacing: "md",
    marginLeft: "none",
    marginRight: "none",
    arrowsPosition: "outside",
    autoPlay: true,
    autoPlayDelay: 5000,
    autoPlayJump: false,
    slides: [
      {
        id: `Slide-${nanoid()}`,
      },
      {
        id: `Slide-${nanoid()}`,
      },
      {
        id: `Slide-${nanoid()}`,
      },
    ],
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    paddingTop,
    paddingBottom,
    slidesOnScreen,
    spacing,
    marginLeft,
    marginRight,
    title,
    maxWidth,
    theme,
    slides,
    arrowsPosition = "outside",
    autoPlay = true,
    autoPlayDelay = 5000,
    autoPlayJump = false,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn("relative isolate overflow-clip bg-background", {
          dark: theme === "dark",
        })}
      >
        <div
          className={cn(
            "relative",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            paddingVariants({ side: "left", size: marginLeft }),
            paddingVariants({ side: "right", size: marginRight }),
            {
              "max-w-7xl mx-auto": maxWidth !== "none",
            },
          )}
        >
          <div
            className={cn(
              textColorVariants({ theme }),
              "flex-wrap font-bold text-lg whitespace-pre-line break-words",
            )}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <CarouselComponent
            spacing={spacing}
            autoPlay={autoPlay}
            autoPlayDelay={autoPlayDelay}
            autoPlayJump={autoPlayJump}
            arrowsPosition={arrowsPosition}
            carouselItems={
              <>
                {slides.map((slide) => (
                  <CarouselItem
                    key={slide.id}
                    className={cn(
                      paddingVariants({
                        side: "left",
                        size: spacing,
                      }),
                      {
                        "basis-1/3 lg:basis-1/6": slidesOnScreen == 6,
                        "basis-1/3 lg:basis-1/5": slidesOnScreen == 5,
                        "basis-1/2 lg:basis-1/4": slidesOnScreen == 4,
                        "basis-1/2 lg:basis-1/3": slidesOnScreen == 3,
                        "lg:basis-1/2": slidesOnScreen == 2,
                      },
                    )}
                  >
                    {renderDropZone({
                      zone: slide.id,
                    })}
                  </CarouselItem>
                ))}
              </>
            }
          />
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<CarouselProps>;
