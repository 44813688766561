import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { cn, hsv2hex } from "@/lib/utils";
import Image from "next/image";
import {
  backgroundVariants,
  mutedTextColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { ButtonProps, ButtonsArrayField } from "@/website-editor/blocks/button";
import { ComponentConfig } from "@/website-editor/core";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import BackgroundGradient from "@/components/background-gradient";
import {
  FontSize,
  FontSizeField,
} from "@/website-editor/custom-fields/fontSize";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import CTASectionComponent from "./CTASection";
import {
  Blur,
  BlurField,
  blurVariants,
} from "@/website-editor/custom-fields/blur";
import {
  Brightness,
  BrightnessField,
  brightnessVariants,
} from "@/website-editor/custom-fields/brightness";

export type CTASectionProps = {
  sectionId?: string;
  image?: {
    url: string;
    alt?: string;
  };
  mediaPosition: "left" | "right";
  contentMediaRatio:
    | "1/4"
    | "1/3"
    | "1/2"
    | "2/3"
    | "3/4"
    | "1/1"
    | "3/2"
    | "4/3"
    | "2/1"
    | "3/1"
    | "4/1";
  imageOverflowType: "overflow" | "contain";
  videoUrl: string;
  theme: Theme;
  background: "none" | "glowing-gradient";
  type: "simple" | "panel";
  align: "left" | "center";
  title: string;
  titleFontSize: FontSize;
  titleColor: HarmonyValue;
  description: string;
  descriptionColor: HarmonyValue;
  buttons: Array<ButtonProps>;
  buttonsMarginTop: Margin;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  backgroundImageBlur: Blur;
  backgroundImageBrightness: Brightness;
  harmony: HarmonyValue;
  customColorIsGradient: boolean;
  revealAnimation: "none" | "fade-in-to-center";
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

const CTAWrapper: React.FC<
  React.PropsWithChildren<{
    theme: Theme;
    type: "simple" | "panel";
    paddingTop: Padding;
    paddingBottom: Padding;
  }>
> = ({ theme, type, children, paddingBottom, paddingTop }) => {
  if (type === "panel") {
    return (
      <div
        className={cn(
          "px-4",
          backgroundVariants({ theme: theme === "dark" ? "light" : "dark" }),
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
        )}
      >
        <div className={cn("mx-auto max-w-7xl")}>{children}</div>
      </div>
    );
  }

  return children;
};

export const CTASection = {
  displayName: "CTA Bölümü",
  tooltip:
    "CTA bölümü (Call to Action) genellikle bir eyleme çağrıda bulunmak için kullanılır. Örneğin, bir ürünü satın almak, bir formu doldurmak, bir eğitime kaydolmak gibi.",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "richtextarea",
      label: "Başlık",
    },
    titleColor: {
      ...HarmonyCustomField,
      label: "Başlık Özel Rengi",
    },
    titleFontSize: {
      ...FontSizeField,
      label: "Başlık Font Boyutu",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    descriptionColor: {
      ...HarmonyCustomField,
      label: "Açıklama Özel Rengi",
    },
    buttons: ButtonsArrayField,
    image: {
      ...ImageCustomField,
      label: "Fotoğraf",
    },
    mediaPosition: {
      type: "radio",
      label: "Medya Pozisyonu",
      options: [
        {
          value: "left",
          label: "Sol",
        },
        {
          value: "right",
          label: "Sağ",
        },
      ],
    },
    contentMediaRatio: {
      type: "select",
      label: "İçerik/Medya Genişlik Oranı",
      options: [
        {
          value: "1/4",
          label: "1/4",
        },
        {
          value: "1/3",
          label: "1/3",
        },
        {
          value: "1/2",
          label: "1/2",
        },
        {
          value: "2/3",
          label: "2/3",
        },
        {
          value: "3/4",
          label: "3/4",
        },
        {
          value: "1/1",
          label: "1/1",
        },
        {
          value: "4/3",
          label: "4/3",
        },
        {
          value: "3/2",
          label: "3/2",
        },
        {
          value: "2/1",
          label: "2/1",
        },
        {
          value: "3/1",
          label: "3/1",
        },
        {
          value: "4/1",
          label: "4/1",
        },
      ],
    },
    imageOverflowType: {
      type: "radio",
      label: "Resim Tarzı",
      options: [
        {
          value: "overflow",
          label: "Ekrandan Taşan",
        },
        {
          value: "contain",
          label: "Sığdırılmış",
        },
      ],
    },
    videoUrl: {
      type: "text",
      label: "Video URL",
    },
    theme: ThemeField,
    align: {
      type: "radio",
      label: "Hizala",
      options: [
        {
          value: "left",
          label: "Sola Hizala",
        },
        {
          value: "center",
          label: "Ortala",
        },
      ],
    },
    type: {
      type: "radio",
      label: "Tarz",
      options: [
        {
          value: "simple",
          label: "Basit",
        },
        {
          value: "panel",
          label: "Panel",
        },
      ],
    },
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
      showIf: (props) => !props.backgroundImage?.url,
    },
    customColorIsGradient: {
      type: "radio",
      label: "Arkaplan Rengi Tarzı",
      options: [
        {
          value: true,
          label: "Gradient",
        },
        {
          value: false,
          label: "Solid",
        },
      ],
      showIf: (props) => props.harmony.length > 0,
    },
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    backgroundImageBlur: {
      ...BlurField,
      showIf: (props) => Boolean(props.backgroundImage?.url),
    },
    backgroundImageBrightness: {
      ...BrightnessField,
      showIf: (props) => Boolean(props.backgroundImage?.url),
    },
    background: {
      type: "radio",
      label: "Arkaplan",
      options: [
        {
          value: "none",
          label: "Yok",
        },
        {
          value: "glowing-gradient",
          label: "Parlayan",
        },
      ],
    },
    buttonsMarginTop: {
      ...MarginField,
      label: "Butonlar Üst Kenar Boşluğu",
    },
    revealAnimation: {
      type: "radio",
      label: "Gösterim Animasyonu",
      options: [
        {
          value: "none",
          label: "Yok",
        },
        {
          value: "fade-in-to-center",
          label: "Ortaya Doğru",
        },
      ],
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      fields: [
        "sectionId",
        "title",
        "titleFontSize",
        "titleColor",
        "description",
        "descriptionColor",
        "buttons",
        "type",
        "align",
        "theme",
      ],
      defaultExpanded: true,
    },
    media: {
      title: "Medya",
      defaultExpanded: true,
      fields: [
        "image",
        "mediaPosition",
        "contentMediaRatio",
        "imageOverflowType",
        "videoUrl",
      ],
    },
    background: {
      title: "Arkaplan",
      defaultExpanded: false,
      fields: [
        "backgroundImage",
        "backgroundImageBlur",
        "backgroundImageBrightness",
        "harmony",
        "customColorIsGradient",
        "background",
      ],
    },
    animation: {
      title: "Animasyon",
      defaultExpanded: false,
      fields: ["revealAnimation"],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: [
        "buttonsMarginTop",
        "paddingTop",
        "paddingBottom",
        "paddingLeft",
        "paddingRight",
      ],
    },
  },
  defaultProps: {
    sectionId: "",
    type: "panel",
    align: "center",
    image: {
      url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/tu43ietv9uigwmxyqcl9eg6i/finansal+okuryazarlik.jpg",
    },
    backgroundImageBlur: "sm",
    backgroundImageBrightness: 50,
    mediaPosition: "right",
    contentMediaRatio: "1/1",
    imageOverflowType: "overflow",
    videoUrl: "",
    theme: "light",
    background: "none",
    backgroundImage: null,
    title:
      "Finansal Okuryazarlık: Paranın ve Piyasaların Dilini Konuşmanın Vakti Geldi",
    titleFontSize: "4xl",
    titleColor: [],
    description:
      "Amacına uygun Kursları ve Paketler. Ezber yok. Ölçülebilir. Şimdi.",
    descriptionColor: [],
    buttons: [
      {
        url: "#",
        size: "lg",
        label: "Dersleri İncele",
        variant: "default",
      },
    ],
    buttonsMarginTop: "lg",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingTop: "xl",
    paddingBottom: "xl",
    harmony: [],
    customColorIsGradient: true,
    revealAnimation: "none",
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    image,
    mediaPosition = "right",
    contentMediaRatio = "1/1",
    imageOverflowType = "overflow",
    videoUrl = "",
    title,
    titleFontSize = "4xl",
    titleColor = [],
    description,
    descriptionColor = [],
    buttons,
    background,
    backgroundImage,
    backgroundImageBlur = "sm",
    backgroundImageBrightness = 50,
    theme,
    type,
    align,
    buttonsMarginTop = "lg",
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    id,
    harmony = [],
    customColorIsGradient = true,
    revealAnimation = "none",
  }) => {
    return (
      <CTAWrapper
        theme={theme}
        type={type}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      >
        <div
          id={sectionId || undefined}
          className={cn(
            "relative isolate overflow-clip",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
            {
              "shadow-2xl rounded-3xl": type === "panel",
              "bg-background": customColorIsGradient || harmony.length === 0,
              dark: theme === "dark",
            },
          )}
          style={
            !customColorIsGradient && harmony[0]
              ? {
                  backgroundColor: hsv2hex(
                    harmony[0].hue,
                    harmony[0].saturation,
                    harmony[0].value,
                  ),
                }
              : undefined
          }
        >
          {backgroundImage ? (
            <Image
              src={backgroundImage?.url ?? ""}
              alt={backgroundImage?.alt ?? ""}
              width={1080}
              height={1080}
              unoptimized={!backgroundImage?.url.includes(".pi.education/")}
              className={cn(
                "absolute inset-0 -z-10 h-full w-full object-cover",
                mutedTextColorVariants({ theme }),
                blurVariants({ blur: backgroundImageBlur }),
                brightnessVariants({ brightness: backgroundImageBrightness }),
              )}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          ) : customColorIsGradient && harmony.length > 0 ? (
            <BackgroundGradient id={id} harmony={harmony} />
          ) : (
            <>
              {theme === "light" && background === "glowing-gradient" && (
                <svg
                  viewBox="0 0 1024 1024"
                  className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                  aria-hidden="true"
                >
                  <circle
                    cx={512}
                    cy={512}
                    r={512}
                    fill={`url(#pattern-${id})`}
                    fillOpacity="0.7"
                  />
                  <defs>
                    <radialGradient id={`pattern-${id}`}>
                      <stop stopColor="#7775D6" />
                      <stop offset={1} stopColor="#E935C1" />
                    </radialGradient>
                  </defs>
                </svg>
              )}
              {theme === "dark" && background === "glowing-gradient" && (
                <svg
                  viewBox="0 0 1024 1024"
                  className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                  aria-hidden="true"
                >
                  <circle
                    cx={512}
                    cy={512}
                    r={512}
                    fill={`url(#pattern-${id})`}
                    fillOpacity="0.7"
                  />
                  <defs>
                    <radialGradient id={`pattern-${id}`}>
                      <stop stopColor="#7775D6" />
                      <stop offset={1} stopColor="#E935C1" />
                    </radialGradient>
                  </defs>
                </svg>
              )}
            </>
          )}
          <CTASectionComponent
            image={image}
            mediaPosition={mediaPosition}
            contentMediaRatio={contentMediaRatio}
            imageOverflowType={imageOverflowType}
            videoUrl={videoUrl}
            theme={theme}
            title={title}
            titleFontSize={titleFontSize}
            titleColor={titleColor}
            description={description}
            descriptionColor={descriptionColor}
            buttons={buttons}
            buttonsMarginTop={buttonsMarginTop}
            align={align}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            revealAnimation={revealAnimation}
          />
        </div>
      </CTAWrapper>
    );
  },
} satisfies ComponentConfig<CTASectionProps>;
