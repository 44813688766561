"use client";

import { useCallback } from "react";
import { InputIcon } from "@radix-ui/react-icons";
import { Input } from "@/components/ui/input";
import { useAppContext } from "@/website-editor/core/components/Puck/context";
import { CustomField } from "@/website-editor/core";

interface TierCustomFieldProps {}

const FieldRenderer: React.FC<
  Parameters<CustomField<TierCustomFieldProps>["render"]>[0]
> = ({ field, value, onChange, readOnly }) => {
  const appContext = useAppContext();

  const findMatchingTier = useCallback(
    (tierId: any) => {
      return Array.isArray(value)
        ? value.find((t) => t.tierId === tierId)
        : null;
    },
    [value],
  );

  return (
    <label className="block max-h-96 overflow-y-auto">
      <div className="flex items-center">
        <InputIcon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      {appContext.selectedItem?.props.tiers.map((tier: any) => {
        const matchingTier = findMatchingTier(tier.id);

        return (
          <div key={tier.id} className="mt-2 flex items-center">
            <div className="flex-1">
              <strong>{tier.name}</strong>
            </div>
            <div className="flex-1">
              <Input
                value={matchingTier?.value || ""}
                onChange={(e) => {
                  const newValue = Array.isArray(value) ? [...value] : [];
                  const tierIndex = newValue.findIndex(
                    (t) => t.tierId === tier.id,
                  );
                  if (tierIndex !== -1) {
                    newValue[tierIndex] = {
                      ...newValue[tierIndex],
                      value: e.target.value,
                    };
                  } else {
                    newValue.push({ tierId: tier.id, value: e.target.value });
                  }
                  onChange(newValue);
                }}
                placeholder="Value"
                readOnly={readOnly}
              />
            </div>
          </div>
        );
      })}
    </label>
  );
};

export default FieldRenderer;
