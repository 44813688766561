import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { getCourseAcademicTermField } from "@/website-editor/custom-fields/courseAcademicTerm";
import loadSerializableQuery from "@/relay/loadSerializableQuery";
import CourseContentQueryNode, {
  CourseContentQuery,
} from "@generated/CourseContentQuery.graphql";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import ResolveDataComponentHelper from "@/website-editor/components/ResolveDataComponentHelper";
import CourseContent from "@/website-editor/blocks/course-content-section/CourseContent";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { HarmonyCustomField } from "@/website-editor/custom-fields/harmony";

export type CourseContentSectionProps = {
  sectionId?: string;
  theme: Theme;
  variant: "default" | "dense";
  customFreeBadgeColor?: any;
  customFreeBadgeTextColor?: any;
  courseAcademicTerm: { id?: string; __preloadedQuery?: any };
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export function getCourseContentSection(organization: { slug: string }) {
  return {
    displayName: "Ders İçeriği Bölümü",
    fields: {
      sectionId: SectionIdField,
      courseAcademicTerm: getCourseAcademicTermField(organization),
      theme: ThemeField,
      variant: {
        type: "radio",
        label: "Boşluk Tarzı",
        options: [
          { label: "Normal", value: "default" },
          { label: "Sıkışık", value: "dense" },
        ],
      },
      customFreeBadgeColor: {
        ...HarmonyCustomField,
        label: "Özel Ücretsiz İçerik Rozet Rengi",
      },
      customFreeBadgeTextColor: {
        ...HarmonyCustomField,
        label: "Özel Ücretsiz İçerik Yazı Rengi",
      },
      paddingLeft: {
        ...PaddingField,
        label: "Sol Kenar Boşluğu",
      },
      paddingRight: {
        ...PaddingField,
        label: "Sağ Kenar Boşluğu",
      },
      paddingTop: {
        ...PaddingField,
        label: "Üst Kenar Boşluğu",
      },
      paddingBottom: {
        ...PaddingField,
        label: "Alt Kenar Boşluğu",
      },
    },
    defaultProps: {
      sectionId: "",
      theme: "light",
      variant: "default",
      courseAcademicTerm: {},
      paddingLeft: "none",
      paddingRight: "none",
      paddingTop: "none",
      paddingBottom: "none",
    },
    displayDefaultPropOverrides: {
      courseAcademicTerm: {
        id: "Q291cnNlQWNhZGVtaWNUZXJtOm1pdDQ2ODR3aHYwZmVqMmlzcHY0OXo5Nw==",
      },
    },
    resolveData: async (data, { changed }) => {
      if (!data.props.courseAcademicTerm?.id) {
        return data;
      }

      if (!changed.courseAcademicTerm) {
        return data;
      }

      const preloadedQuery = await loadSerializableQuery<
        typeof CourseContentQueryNode,
        CourseContentQuery
      >(CourseContentQueryNode.params, {
        id: data.props.courseAcademicTerm.id,
      });

      return {
        ...data,
        props: {
          ...data.props,
          courseAcademicTerm: {
            ...data.props.courseAcademicTerm,
            __preloadedQuery: preloadedQuery,
          },
        },
      };
    },
    render: ({
      puck: { renderDropZone },
      sectionId,
      theme,
      variant = "default",
      customFreeBadgeColor,
      customFreeBadgeTextColor,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      courseAcademicTerm,
    }) => {
      return (
        <section
          id={sectionId || undefined}
          className={cn(
            "relative isolate overflow-clip bg-background",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
          )}
        >
          <ResolveDataComponentHelper
            data={courseAcademicTerm}
            Empty={
              <p
                className={cn(
                  "text-lg leading-8",
                  textColorVariants({ theme }),
                )}
              >
                Bir ders seçin
              </p>
            }
            Loading={
              <p
                className={cn(
                  "text-lg leading-8",
                  mutedTextColorVariants({ theme }),
                )}
              >
                Yükleniyor...
              </p>
            }
          >
            <CourseContent
              preloadedQuery={courseAcademicTerm?.__preloadedQuery}
              theme={theme}
              variant={variant}
              customFreeBadgeColor={customFreeBadgeColor}
              customFreeBadgeTextColor={customFreeBadgeTextColor}
            />
          </ResolveDataComponentHelper>
        </section>
      );
    },
  } satisfies ComponentConfig<CourseContentSectionProps>;
}
