"use client";

import { DropZone, dropZoneContext } from "../../../DropZone";
import { rootDroppableId } from "../../../../lib/root-droppable-id";
import { useCallback, useContext } from "react";
import { useAppContext } from "../../context";
import { useMeasure, useWindowSize } from "@uidotdev/usehooks";

export const Preview = ({ id = "puck-preview" }: { id?: string }) => {
  const { config, dispatch, state } = useAppContext();
  const [ref, { width }] = useMeasure();
  const { width: windowWidth } = useWindowSize();

  const Page = useCallback(
    (pageProps: any) =>
      config.root?.render
        ? config.root?.render({
            ...pageProps,
            puck: { renderDropZone: DropZone, isEditing: true },
          })
        : pageProps.children,
    [config.root],
  );

  const rootProps = state.data.root.props;

  const { disableZoom = false } = useContext(dropZoneContext) || {};

  const zoom = disableZoom ? 1 : width && windowWidth ? width / windowWidth : 1;

  return (
    <div ref={ref}>
      <div
        id={id}
        onClick={() => {
          dispatch({ type: "setUi", ui: { ...state.ui, itemSelector: null } });
        }}
        className="overflow-hidden"
        style={{
          zoom: zoom,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "--puck-zoom": zoom,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "--puck-zoom-reversed": 1 / zoom,
        }}
      >
        <Page dispatch={dispatch} state={state} {...rootProps}>
          <DropZone zone={rootDroppableId} />
        </Page>
      </div>
    </div>
  );
};
