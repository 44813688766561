/**
 * @generated SignedSource<<769941c12c5877ac3a84f2bd0e224f94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CreateWebPageComponentTemplateInput = {
  component: string;
  content: Record<string, any>;
  description?: string | null | undefined;
  organizationId: string;
  title: string;
};
export type CreateWebPageComponentTemplateDialogMutation$variables = {
  input: CreateWebPageComponentTemplateInput;
};
export type CreateWebPageComponentTemplateDialogMutation$data = {
  readonly createWebPageComponentTemplate: {
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
    readonly webPageComponentTemplate: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"WebPageComponentTemplate">;
    } | null | undefined;
  };
};
export type CreateWebPageComponentTemplateDialogMutation = {
  response: CreateWebPageComponentTemplateDialogMutation$data;
  variables: CreateWebPageComponentTemplateDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateWebPageComponentTemplateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateWebPageComponentTemplatePayload",
        "kind": "LinkedField",
        "name": "createWebPageComponentTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebPageComponentTemplate",
            "kind": "LinkedField",
            "name": "webPageComponentTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WebPageComponentTemplate"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateWebPageComponentTemplateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateWebPageComponentTemplatePayload",
        "kind": "LinkedField",
        "name": "createWebPageComponentTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebPageComponentTemplate",
            "kind": "LinkedField",
            "name": "webPageComponentTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "component",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPublicTemplate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0830f41a1b3fd0a373afb7c34297ec9",
    "id": null,
    "metadata": {},
    "name": "CreateWebPageComponentTemplateDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateWebPageComponentTemplateDialogMutation(\n  $input: CreateWebPageComponentTemplateInput!\n) {\n  createWebPageComponentTemplate(input: $input) {\n    webPageComponentTemplate {\n      id\n      ...WebPageComponentTemplate\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment WebPageComponentTemplate on WebPageComponentTemplate {\n  id\n  title\n  content\n  component\n  description\n  isPublicTemplate\n}\n"
  }
};
})();

(node as any).hash = "8b68a461320a940bddb878bdf3e48299";

export default node;
