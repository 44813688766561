"use client";

import {
  graphql,
  useMutation,
  usePreloadedQuery,
  useRelayEnvironment,
} from "react-relay";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
} from "@/website-editor/custom-fields/theme";
import { Badge } from "@/components/ui/badge";
import { cn, formatMoney } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useOrganization } from "@/student/context/OrganizationContext";
import { useToast } from "@/components/ui/use-toast";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import getPiHref from "@/components/utils/getPiHref";
import PiLink from "@/components/PiLink";
import { CoachingSessionPlansAndActionsCreateOrderMutation } from "@generated/CoachingSessionPlansAndActionsCreateOrderMutation.graphql";
import CoachingSessionPlansAndActionsQueryNode, {
  CoachingSessionPlansAndActionsQuery,
} from "@generated/CoachingSessionPlansAndActionsQuery.graphql";
import { ok } from "assert";
import React from "react";

const CoachingSessionPlansAndActions: React.FC<{
  preloadedQuery: SerializablePreloadedQuery<
    typeof CoachingSessionPlansAndActionsQueryNode,
    CoachingSessionPlansAndActionsQuery
  >;
  plansStyle: "card" | "borderless";
  unauthorizedRedirectUrl?: string;
  theme: Theme;
  zones: Record<string, React.ReactNode>;
}> = ({
  preloadedQuery,
  zones,
  plansStyle,
  unauthorizedRedirectUrl,
  theme,
}) => {
  const organization = useOrganization();
  const environment = useRelayEnvironment();
  const { toast } = useToast();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const data = usePreloadedQuery(
    graphql`
      query CoachingSessionPlansAndActionsQuery($id: ID!) {
        node(id: $id) {
          ... on CoachingSession {
            id
            slug
            plans {
              id
              sessionCount
              viewerCanOrder
              originalPrice {
                amount
                currency
              }
              discountedPrice {
                amount
                currency
              }
            }
            viewerStudent {
              remainingSessionCount
            }
            organization {
              slug
            }
          }
        }
      }
    `,
    queryRef,
  );

  const [commitCreateOrder, isCreateOrderInFlight] =
    useMutation<CoachingSessionPlansAndActionsCreateOrderMutation>(graphql`
      mutation CoachingSessionPlansAndActionsCreateOrderMutation(
        $input: CreateOrderInput!
      ) {
        createOrder(input: $input) {
          order {
            id
            slug
            ...OrderPage_order
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const [orderedId, setOrderedId] = React.useState<string | null>(null);

  if (!data) {
    return <p>No data</p>;
  }

  const coachingSession = data.node;
  if (!coachingSession) {
    return <p>No node</p>;
  }

  const validPlans = coachingSession.plans?.filter(
    (plan) => plan.discountedPrice && plan.originalPrice,
  );

  if (!validPlans || validPlans.length === 0) {
    return <p>No plan with price set</p>;
  }

  const alreadyHasAccess = Boolean(
    coachingSession.viewerStudent &&
      coachingSession.viewerStudent.remainingSessionCount > 0,
  );

  const numberOfGridColumns =
    validPlans.length > 3 ? 4 : validPlans.length > 2 ? 3 : 2;

  return (
    <div
      className={cn("mt-16 grid gap-6 grid-cols-2", {
        "sm:grid-cols-4": numberOfGridColumns == 2,
        "sm:grid-cols-6": numberOfGridColumns == 3,
        "sm:grid-cols-8": numberOfGridColumns == 4,
      })}
    >
      {validPlans.map((plan) => {
        ok(plan.discountedPrice && plan.originalPrice);

        const isAuthorized = Boolean(plan.viewerCanOrder);

        const hasDiscount =
          parseFloat(plan.discountedPrice.amount) <
          parseFloat(plan.originalPrice.amount);

        return (
          <div
            key={plan.id}
            className={cn("col-span-2 flex flex-col items-start", {
              "rounded-xl border bg-card text-card-foreground shadow p-4 overflow-clip":
                plansStyle === "card",
              "sm:last:odd:col-start-2": numberOfGridColumns == 2,
            })}
          >
            {zones[plan.id]}
            <p
              className={cn(
                "text-sm font-bold break-words whitespace-pre-line",
                mutedTextColorVariants({ theme }),
              )}
            >
              Birebir Görüşme
            </p>
            <h3
              className={cn(
                "mt-1 mb-2 text-lg font-semibold leading-6 break-words whitespace-pre-line",
                textColorVariants({ theme }),
              )}
            >
              {plan.sessionCount} seans
            </h3>
            {hasDiscount && <Badge variant="default">İndirim</Badge>}
            <div className="flex items-center">
              <p
                className={cn(
                  "font-display font-semibold text-2xl xl:text-4xl",
                  textColorVariants({ theme }),
                )}
              >
                {formatMoney(plan.discountedPrice)}
              </p>
              {hasDiscount && (
                <div className="pl-3">
                  <p
                    className={cn(
                      "font-display text-base line-through",
                      mutedTextColorVariants({ theme }),
                    )}
                  >
                    {formatMoney(plan.originalPrice)}
                  </p>
                  <p className="font-display text-xs text-primary xl:text-sm">
                    %
                    {Math.round(
                      ((parseFloat(plan.originalPrice.amount) -
                        parseFloat(plan.discountedPrice.amount)) /
                        parseFloat(plan.originalPrice.amount)) *
                        100,
                    )}{" "}
                    indirim
                  </p>
                </div>
              )}
            </div>
            {alreadyHasAccess && (
              <Button size="lg" className="mt-3 w-full" asChild>
                <PiLink
                  href={`/${coachingSession?.organization?.slug}/birebir/${coachingSession?.slug}`}
                >
                  Birebir Görüşme Sayfasına Git
                </PiLink>
              </Button>
            )}
            {isAuthorized ? (
              <Button
                size="lg"
                className="mt-3 w-full"
                onClick={() => {
                  if (isCreateOrderInFlight) {
                    return;
                  }

                  setOrderedId(plan.id);

                  commitCreateOrder({
                    variables: {
                      input: {
                        orderableId: plan.id,
                        organizationId: organization.id,
                      },
                    },
                    updater: (store, mutationData) => {
                      if (!mutationData) {
                        return;
                      }

                      if (mutationData.createOrder.userErrors.length > 0) {
                        toast({
                          title: "Sipariş oluşturulamadı",
                          description:
                            mutationData.createOrder.userErrors[0]?.message,
                        });

                        return;
                      }

                      if (mutationData.createOrder.order) {
                        toast({
                          title: "Sipariş sayfasına yönlendiriliyorsunuz...",
                        });

                        const order = store.get(
                          mutationData.createOrder.order.id,
                        )!;

                        store.getRoot().setLinkedRecord(order, "order", {
                          slug: mutationData.createOrder.order.slug,
                        });

                        router.push(
                          getPiHref(
                            `/${organization.slug}/siparis/${mutationData.createOrder.order.slug}`,
                          ),
                        );
                      }
                    },
                  });
                }}
                disabled={isCreateOrderInFlight}
                loading={isCreateOrderInFlight && orderedId === plan.id}
              >
                Hemen Al
              </Button>
            ) : (
              <Button size="lg" className="mt-3 w-full" asChild>
                <PiLink
                  href={`${unauthorizedRedirectUrl}?next=${encodeURIComponent(
                    pathname + (searchParams ? `?${searchParams}` : ""),
                  )}`}
                >
                  Almak İçin Giriş Yap
                </PiLink>
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CoachingSessionPlansAndActions;
