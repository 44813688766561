import React from "react";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import { cn } from "@/lib/utils";
import { ComponentConfig } from "@/website-editor/core";
import Image from "next/image";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import PiLink from "@/components/PiLink";
import { Button, ButtonProps } from "@/website-editor/blocks/button";
import { Button as ButtonComponent } from "@/components/ui/button";
import { Icon } from "@/website-editor/custom-fields/icon";

export type CoursesSectionProps = {
  sectionId?: string;
  title: string;
  description: string;
  courses: Array<{
    title: string;
    description: string;
    image: {
      url: string;
      alt?: string;
    } | null;
    rounded: Rounded;
    buttonStartIcon?: ButtonProps["startIcon"];
    buttonEndIcon?: ButtonProps["endIcon"];
    buttonLabel: ButtonProps["label"];
    buttonVariant: ButtonProps["variant"];
    buttonSize: ButtonProps["size"];
    avatar: {
      url: string;
      alt?: string;
    } | null;
    avatarName: string;
    avatarTitle: string;
    url: string;
    type: string;
  }>;
  coursesStyle: "card" | "borderless";
  theme: Theme;
  numberOfGridColumns: 1 | 2 | 3 | 4 | "1" | "2" | "3" | "4";
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const CoursesSection = {
  displayName: "Dersler Bölümü",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    courses: {
      type: "array",
      label: "Kurslar",
      getItemSummary: (item, idx) => item.title || `Kurs ${(idx ?? 0) + 1}`,
      arrayFields: {
        type: { type: "textarea", label: "Tip" },
        title: {
          type: "richtextarea",
          label: "Kurs Başlığı",
        },
        description: {
          type: "richtextarea",
          label: "Kurs Açıklaması",
        },
        url: {
          type: "text",
          label: "URL (Derse özel sayfa yoksa boş bırakın)",
        },
        image: {
          ...ImageCustomField,
          label: "Kurs Resmi",
        },
        rounded: RoundedField,
        buttonStartIcon: {
          ...Button.fields.startIcon,
          label: "Buton Sol İkon",
        },
        buttonEndIcon: { ...Button.fields.endIcon, label: "Buton Sağ İkon" },
        buttonLabel: { ...Button.fields.label, label: "Buton Metni" },
        buttonVariant: { ...Button.fields.variant, label: "Buton Stili" },
        buttonSize: { ...Button.fields.size, label: "Buton Boyutu" },
        avatar: {
          ...ImageCustomField,
          label: "Avatar",
        },
        avatarName: {
          type: "textarea",
          label: "Avatar İsmi",
        },
        avatarTitle: {
          type: "textarea",
          label: "Avatar Ünvanı",
        },
      },
      defaultItemProps: {
        type: "Ders Tipi",
        title: "Ders Adı",
        description: "Ders Tanımı",
        image: {
          url: "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.87170709.1707609600&semt=ais",
          alt: "",
        },
        avatar: {
          url: "https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.87170709.1707609600&semt=ais",
          alt: "",
        },
        avatarName: "İsim",
        avatarTitle: "Ünvan",
        url: "",
        rounded: "lg",
        buttonLabel: "",
        buttonVariant: "default",
        buttonSize: "default",
      },
    },
    coursesStyle: {
      type: "radio",
      label: "Ders Tarzı",
      options: [
        {
          value: "card",
          label: "Kart",
        },
        {
          value: "borderless",
          label: "Kenarlıksız",
        },
      ],
    },
    theme: ThemeField,
    numberOfGridColumns: {
      type: "radio",
      label: "Sütun Sayısı",
      options: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
      ],
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    title: "Finansal Okuryazarlığa Giriş Yap",
    courses: [
      {
        url: "#",
        type: "Kurs",
        image: {
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/n9jdxw5ynr3hqrx4bsd5l8bs/borsa+kurs.jpg",
        },
        title: "Borsa Eğitimi",
        avatar: null,
        rounded: "lg",
        avatarName: "",
        buttonSize: "default",
        avatarTitle: "",
        buttonLabel: "Derse Git",
        description: "Piyasayı anla, paranı yönet",
        buttonVariant: "default",
      },
      {
        url: "#",
        type: "Kurs",
        image: {
          alt: "",
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/fhusi12u1z8kmph9uiuxch3k/doviz+kurs.jpg",
        },
        title: "Döviz Eğitimi",
        avatar: null,
        rounded: "lg",
        avatarName: "",
        buttonSize: "default",
        avatarTitle: "",
        buttonLabel: "Derse Git",
        description:
          "Uluslararası para birimlerini öğren, makropolitikaları takip et",
        buttonVariant: "default",
      },
      {
        url: "#",
        type: "Kurs",
        image: {
          alt: "",
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/zp446kkb1j1jdutg95vkcoye/kripto+kurs.jpg",
        },
        title: "Kripto Eğitimi",
        avatar: null,
        rounded: "lg",
        avatarName: "",
        buttonSize: "default",
        avatarTitle: "",
        buttonLabel: "Derse Git",
        description:
          "Kripto paraların birbirleriyle ve Borsa, Emtia ile ilişkilerini takip et",
        buttonVariant: "default",
      },
    ],
    paddingTop: "lg",
    description:
      "Amaca yönelik, pratik kurslar. Teori yok. Ne öğrenmek istersin?",
    paddingLeft: "lg",
    coursesStyle: "card",
    paddingRight: "lg",
    paddingBottom: "lg",
    numberOfGridColumns: 3,
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    title,
    description,
    courses,
    coursesStyle = "borderless",
    theme = "light",
    numberOfGridColumns = 3,
    paddingTop = "lg",
    paddingBottom = "lg",
    paddingLeft = "lg",
    paddingRight = "lg",
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2
              className={cn(
                "text-3xl font-bold tracking-tight sm:text-4xl break-words whitespace-pre-line",
                textColorVariants({ theme }),
              )}
            >
              {title}
            </h2>
            <div
              className={cn(
                "mt-2 text-lg leading-8 break-words whitespace-pre-line",
                mutedTextColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div
            className={cn("mt-16 grid gap-6 grid-cols-2 place-items-start", {
              "sm:grid-cols-4": numberOfGridColumns == 2,
              "sm:grid-cols-6": numberOfGridColumns == 3,
              "sm:grid-cols-8": numberOfGridColumns == 4,
            })}
          >
            {courses.map((course, idx) => {
              const Comp = course.url ? PiLink : "article";

              return (
                <Comp
                  key={idx}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  href={course.url || undefined}
                  className={cn("col-span-2 flex flex-col items-start", {
                    "hover:shadow-lg hover:transform hover:scale-105 transition-transform duration-300 ease-in-out":
                      coursesStyle === "card" && course.url,
                    "rounded-xl border bg-card text-card-foreground shadow p-4 overflow-clip":
                      coursesStyle === "card",
                    "sm:last:odd:col-start-2": numberOfGridColumns == 2,
                  })}
                >
                  {course.image?.url ? (
                    <Image
                      src={course.image?.url ?? ""}
                      alt={course.image?.alt ?? ""}
                      width={1080}
                      height={1080}
                      unoptimized={
                        !course.image?.url.includes(".pi.education/")
                      }
                      className={cn(
                        "w-full h-auto",
                        roundedVariants({ rounded: course.rounded }),
                      )}
                    />
                  ) : null}
                  {course.type ? (
                    <p
                      className={cn(
                        "mt-4 text-sm font-bold break-words whitespace-pre-line",
                        mutedTextColorVariants({ theme }),
                      )}
                    >
                      {course.type}
                    </p>
                  ) : null}
                  {course.title ? (
                    <div
                      className={cn(
                        "mt-1 text-lg font-semibold leading-6 break-words whitespace-pre-line",
                        textColorVariants({ theme }),
                      )}
                      dangerouslySetInnerHTML={{ __html: course.title }}
                    />
                  ) : null}
                  {course.description ? (
                    <div
                      className={cn(
                        "mt-2 line-clamp-3 text-sm leading-6 break-words whitespace-pre-line",
                        textColorVariants({ theme }),
                      )}
                      dangerouslySetInnerHTML={{ __html: course.description }}
                    />
                  ) : null}
                  {renderDropZone({
                    zone: `course-${idx}`,
                    className: "w-full",
                  })}
                  {course.buttonLabel ? (
                    <ButtonComponent
                      className="mt-4 w-full"
                      variant={course.buttonVariant}
                      size={course.buttonSize}
                    >
                      <Icon
                        icon={course.buttonStartIcon || null}
                        className="mr-2 size-3 sm:size-4"
                      />
                      {course.buttonLabel}
                      <Icon
                        icon={course.buttonEndIcon || null}
                        className="ml-2 size-3 sm:size-4"
                      />
                    </ButtonComponent>
                  ) : null}
                  {Boolean(
                    course.avatar?.url ||
                      course.avatarName ||
                      course.avatarTitle,
                  ) && (
                    <div className="relative mt-8 flex items-center gap-x-4">
                      {course.avatar?.url && (
                        <Image
                          src={course.avatar?.url}
                          alt={course.avatar?.alt ?? course.title ?? ""}
                          width={360}
                          height={360}
                          unoptimized={
                            !course.avatar?.url.includes(".pi.education/")
                          }
                          className="size-10 rounded-full bg-gray-100"
                        />
                      )}
                      <div className="text-sm leading-6">
                        <p
                          className={cn(
                            "font-semibold break-words whitespace-pre-line",
                            textColorVariants({ theme }),
                          )}
                        >
                          <span
                            className={cn(
                              "absolute inset-0",
                              textColorVariants({ theme }),
                            )}
                          />
                          {course.avatarName}
                        </p>
                        <p
                          className={cn(
                            mutedTextColorVariants({ theme }),
                            "break-words whitespace-pre-line",
                          )}
                        >
                          {course.avatarTitle}
                        </p>
                      </div>
                    </div>
                  )}
                </Comp>
              );
            })}
          </div>
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<CoursesSectionProps>;
