import type { Config } from "@/website-editor/core";
import {
  Testimonial,
  TestimonialProps,
} from "@/website-editor/blocks/testimonial";
import {
  InfiniteScrollCarousel,
  InfiniteScrollCarouselProps,
} from "@/website-editor/blocks/infinite-scroll-carousel";
import { Hero, HeroProps } from "@/website-editor/blocks/hero";
import { Image, ImageProps } from "@/website-editor/blocks/image";
import {
  InstructorSection,
  InstructorSectionProps,
} from "@/website-editor/blocks/instructor-section";
import {
  FeaturesSection,
  FeaturesSectionProps,
} from "@/website-editor/blocks/features-section";
import {
  CTASection,
  CTASectionProps,
} from "@/website-editor/blocks/cta-section";
import { Login, LoginProps } from "@/website-editor/blocks/login";
import {
  getPurchaseSection,
  PurchaseSectionProps,
} from "@/website-editor/blocks/purchase-section";
import {
  PricingSection,
  PricingSectionProps,
} from "@/website-editor/blocks/pricing-section";
import {
  InstructorsSection,
  InstructorsSectionProps,
} from "@/website-editor/blocks/instructors-section";
import {
  FeaturesGridSection,
  FeaturesGridSectionProps,
} from "@/website-editor/blocks/features-grid-section";
import {
  FAQSection,
  FAQSectionProps,
} from "@/website-editor/blocks/faq-section";
import {
  FooterSection,
  FooterSectionProps,
} from "@/website-editor/blocks/footer-section";
import {
  NavigationSection,
  NavigationSectionProps,
} from "@/website-editor/blocks/navigation-section";
import {
  ContainerSection,
  ContainerSectionProps,
} from "@/website-editor/blocks/container";
import { Title, TitleProps } from "@/website-editor/blocks/title";
import { Paragraph, ParagraphProps } from "@/website-editor/blocks/paragraph";
import {
  TwoColumnLayout,
  TwoColumnLayoutProps,
} from "@/website-editor/blocks/two-column-layout";
import { Video, VideoProps } from "@/website-editor/blocks/video";
import {
  CourseContentSectionProps,
  getCourseContentSection,
} from "@/website-editor/blocks/course-content-section";
import {
  BulletPointsSection,
  BulletPointsSectionProps,
} from "@/website-editor/blocks/bullet-points-section";
import {
  CoursesSection,
  CoursesSectionProps,
} from "@/website-editor/blocks/courses-section";
import {
  LogoCloudSection,
  LogoCloudSectionProps,
} from "@/website-editor/blocks/logo-cloud-section";
import {
  TestimonialsSection,
  TestimonialsSectionProps,
} from "@/website-editor/blocks/testimonials-section";
import { Separator, SeparatorProps } from "@/website-editor/blocks/separator";
import { GridSection, GridSectionProps } from "@/website-editor/blocks/grid";
import {
  PriceComparisonSection,
  PriceComparisonSectionProps,
} from "@/website-editor/blocks/price-comparison-section";
import {
  CoachingSessionPurchaseSectionProps,
  getCoachingSessionPurchaseSection,
} from "@/website-editor/blocks/coaching-session-purchase-section";
import { Plate, PlateProps } from "@/website-editor/blocks/plate";
import {
  Breadcrumbs,
  BreadcrumbsProps,
} from "@/website-editor/blocks/breadcrumbs";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import {
  Button,
  ButtonProps,
  Buttons,
  ButtonsProps,
} from "@/website-editor/blocks/button";
import { DateCustomField } from "@/website-editor/custom-fields/date";
import { ColorCustomField } from "@/website-editor/custom-fields/color";
import { BlogsProps, getBlogs } from "@/website-editor/blocks/blogs";
import { FontCustomField } from "@/website-editor/custom-fields/font";
import {
  StickyScrollRevealSection,
  StickyScrollRevealSectionProps,
} from "@/website-editor/blocks/sticky-scroll-reveal-section";
import { Embed, EmbedProps } from "@/website-editor/blocks/embed";
import { Carousel, CarouselProps } from "@/website-editor/blocks/carousel";

type Props = {
  Image: ImageProps;
  Testimonial: TestimonialProps;
  InfiniteScrollContainer: InfiniteScrollCarouselProps;
  Hero: HeroProps;
  InstructorSection: InstructorSectionProps;
  FeaturesSection: FeaturesSectionProps;
  CTASection: CTASectionProps;
  Login: LoginProps;
  CoachingSessionPurchaseSection: CoachingSessionPurchaseSectionProps;
  PurchaseSection: PurchaseSectionProps;
  CourseContentSection: CourseContentSectionProps;
  PricingSection: PricingSectionProps;
  InstructorsSection: InstructorsSectionProps;
  FeaturesGridSection: FeaturesGridSectionProps;
  FAQSection: FAQSectionProps;
  FooterSection: FooterSectionProps;
  NavigationSection: NavigationSectionProps;
  ContainerSection: ContainerSectionProps;
  GridSection: GridSectionProps;
  Title: TitleProps;
  Paragraph: ParagraphProps;
  TwoColumnLayout: TwoColumnLayoutProps;
  Video: VideoProps;
  BulletPointsSection: BulletPointsSectionProps;
  CoursesSection: CoursesSectionProps;
  LogoCloudSection: LogoCloudSectionProps;
  TestimonialsSection: TestimonialsSectionProps;
  Separator: SeparatorProps;
  PriceComparisonSection: PriceComparisonSectionProps;
  Plate: PlateProps;
  Breadcrumbs: BreadcrumbsProps;
  Button: ButtonProps;
  Buttons: ButtonsProps;
  Blogs: BlogsProps;
  StickyScrollRevealSection: StickyScrollRevealSectionProps;
  Embed: EmbedProps;
  Carousel: CarouselProps;
};

export function getConfig(organization: { id: string; slug: string }): Config<
  Props,
  {
    kind: "MARKETING" | "COURSE" | "LEGAL" | "BLOG";
    blog?: {
      image: {
        url: string;
        alt?: string;
      };
      category: string;
      title: string;
      description: string;
      publishedAt: string;
    };
    colors?: {
      primary?: string;
      background?: string;
      foreground?: string;
      border?: string;
      primaryDark?: string;
      backgroundDark?: string;
      foregroundDark?: string;
      borderDark?: string;
    };
    fonts?: {
      sans?: string;
      display?: string;
    };
  }
> {
  return {
    root: {
      fields: {
        kind: {
          type: "select",
          label: "Sayfa Türü",
          options: [
            { value: "MARKETING", label: "Marketing" },
            { value: "COURSE", label: "Ders Satış Sayfası" },
            { value: "LEGAL", label: "Yasal Sözleşmeler" },
            { value: "BLOG", label: "Blog" },
          ],
        },
        blog: {
          type: "object",
          label: "Blog Bilgileri",
          showIf: (data) => {
            return data?.kind === "BLOG";
          },
          objectFields: {
            image: {
              ...ImageCustomField,
              label: "Fotoğraf",
            },
            category: {
              type: "text",
              label: "Blog Kategorisi",
            },
            title: {
              type: "text",
              label: "Blog Başlığı",
            },
            description: {
              type: "richtextarea",
              label: "Blog Açıklaması",
            },
            publishedAt: {
              ...DateCustomField,
              label: "Yayınlanma Tarihi",
            },
          },
        },
        fonts: {
          type: "object",
          label: "Fontlar",
          objectFields: {
            display: {
              ...FontCustomField,
              label: "Başlık Fontu",
            },
            sans: {
              ...FontCustomField,
              label: "Yazı Fontu",
            },
          },
        },
        colors: {
          type: "object",
          label: "Renkler",
          objectFields: {
            primary: {
              ...ColorCustomField,
              label: "Ana Renk",
            },
            background: {
              ...ColorCustomField,
              label: "Arka Plan Rengi",
            },
            foreground: {
              ...ColorCustomField,
              label: "Yazı Rengi",
            },
            border: {
              ...ColorCustomField,
              label: "Kenar Rengi",
            },
            primaryDark: {
              ...ColorCustomField,
              label: "Koyu Tema Ana Renk",
            },
            backgroundDark: {
              ...ColorCustomField,
              label: "Koyu Tema Arka Plan Rengi",
            },
            foregroundDark: {
              ...ColorCustomField,
              label: "Koyu Tema Yazı Rengi",
            },
            borderDark: {
              ...ColorCustomField,
              label: "Koyu Tema Kenar Rengi",
            },
          },
        },
      },
      defaultProps: {
        kind: "MARKETING",
        colors: {
          primary: "#e11d48",
          background: "#ffffff",
          foreground: "#020817",
          border: "#e2e8f0",
          primaryDark: "#e11d48",
          backgroundDark: "#020817",
          foregroundDark: "#f8fafc",
          borderDark: "#1e293b",
        },
      },
    },
    components: {
      Image: Image,
      Video: Video,
      Testimonial: Testimonial,
      InfiniteScrollContainer: InfiniteScrollCarousel,
      Hero: Hero,
      InstructorSection: InstructorSection,
      FeaturesSection: FeaturesSection,
      CTASection: CTASection,
      Login: Login,
      CoachingSessionPurchaseSection:
        getCoachingSessionPurchaseSection(organization),
      PurchaseSection: getPurchaseSection(organization),
      CourseContentSection: getCourseContentSection(organization),
      PricingSection: PricingSection,
      InstructorsSection: InstructorsSection,
      FeaturesGridSection: FeaturesGridSection,
      FAQSection: FAQSection,
      FooterSection: FooterSection,
      NavigationSection: NavigationSection,
      ContainerSection: ContainerSection,
      GridSection: GridSection,
      Title: Title,
      Paragraph: Paragraph,
      TwoColumnLayout: TwoColumnLayout,
      BulletPointsSection: BulletPointsSection,
      CoursesSection: CoursesSection,
      LogoCloudSection: LogoCloudSection,
      TestimonialsSection: TestimonialsSection,
      Separator: Separator,
      PriceComparisonSection: PriceComparisonSection,
      Plate: Plate,
      Breadcrumbs: Breadcrumbs,
      Button: Button,
      Buttons: Buttons,
      Blogs: getBlogs(organization),
      StickyScrollRevealSection: StickyScrollRevealSection,
      Embed: Embed,
      Carousel: Carousel,
    },
    categories: {
      sections: {
        title: "Hazır Bölümler",
        defaultExpanded: true,
        components: [
          "NavigationSection",
          "Hero",
          "LogoCloudSection",
          "FeaturesSection",
          "StickyScrollRevealSection",
          "FeaturesGridSection",
          "CTASection",
          "InstructorSection",
          "InstructorsSection",
          "Testimonial",
          "TestimonialsSection",
          "BulletPointsSection",
          "CoursesSection",
          "PricingSection",
          "PriceComparisonSection",
          "FAQSection",
          "FooterSection",
        ],
      },
      coaching: {
        title: "Birebir Görüşme Satış Sayfası",
        defaultExpanded: false,
        components: ["CoachingSessionPurchaseSection"],
      },
      course: {
        title: "Ders Satış Sayfası",
        defaultExpanded: false,
        components: ["PurchaseSection", "CourseContentSection"],
      },
      blogs: {
        title: "Blog",
        defaultExpanded: false,
        components: ["Blogs", "Plate"],
      },
      auth: {
        title: "Giriş/Üye Ol",
        defaultExpanded: false,
        components: ["Login"],
      },
      foundational: {
        title: "Temel Bloklar",
        defaultExpanded: true,
        components: [
          "Image",
          "Video",
          "Title",
          "Paragraph",
          "Plate",
          "Embed",
          "Button",
          "Buttons",
        ],
      },
      layout: {
        title: "Düzen Blokları",
        defaultExpanded: true,
        components: [
          "Breadcrumbs",
          "ContainerSection",
          "GridSection",
          "Carousel",
          "Separator",
          "TwoColumnLayout",
          "InfiniteScrollContainer",
        ],
      },
    },
  };
}
