/**
 * @generated SignedSource<<3f11c99d02f282b2b6e5903d541fc3eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BlogsActiveBlogsQuery$variables = {
  organizationSlug: string;
};
export type BlogsActiveBlogsQuery$data = {
  readonly organization: {
    readonly activeWebPages: ReadonlyArray<{
      readonly id: string;
      readonly normalizedPath: string;
      readonly root: Record<string, any>;
    }>;
  } | null | undefined;
};
export type BlogsActiveBlogsQuery = {
  response: BlogsActiveBlogsQuery$data;
  variables: BlogsActiveBlogsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "type",
      "value": "BLOG"
    }
  ],
  "concreteType": "WebPage",
  "kind": "LinkedField",
  "name": "activeWebPages",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "normalizedPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "root",
      "storageKey": null
    }
  ],
  "storageKey": "activeWebPages(type:\"BLOG\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogsActiveBlogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlogsActiveBlogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec05c820676bdef7aca6bd239af75532",
    "id": null,
    "metadata": {},
    "name": "BlogsActiveBlogsQuery",
    "operationKind": "query",
    "text": "query BlogsActiveBlogsQuery(\n  $organizationSlug: String!\n) {\n  organization(slug: $organizationSlug) {\n    activeWebPages(type: \"BLOG\") {\n      id\n      normalizedPath\n      root\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b94b4dee5820fb7c7479028682caeba1";

export default node;
