"use client";

import { Droppable } from "@hello-pangea/dnd";
import { Draggable } from "../Draggable";
import { createContext, ReactElement, ReactNode, useContext } from "react";
import { useAppContext } from "@/website-editor/core/components/Puck/context";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import DefaultComponentTemplate from "@/website-editor/core/components/DefaultComponentTemplate";
import { InfoCircledIcon } from "@radix-ui/react-icons";

const drawerContext = createContext<{ droppableId: string }>({
  droppableId: "",
});

const DrawerDraggable = ({
  children,
  id,
  index,
}: {
  children: ReactNode;
  id: string;
  index: number;
}) => (
  <Draggable key={id} id={id} index={index} showShadow disableAnimations>
    {() => children}
  </Draggable>
);

const DrawerItem = ({
  name,
  children,
  id,
  index,
}: {
  name: string;
  children?: (props: { children: ReactNode; name: string }) => ReactElement;
  id?: string;
  index: number;
}) => {
  const ctx = useContext(drawerContext);
  const { config } = useAppContext();

  const resolvedId = `${ctx.droppableId}::${id || name}`;
  const component = config.components[name];

  return (
    <DrawerDraggable id={resolvedId} index={index}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="mb-2 flex items-center rounded border bg-background px-4 py-3 font-medium text-gray-900 hover:bg-accent hover:text-accent-foreground">
            <span className="flex-1">{component?.displayName ?? name}</span>
            {component?.tooltip ? (
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoCircledIcon />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent className="max-w-lg text-base" side="right">
                    {component.tooltip}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            ) : null}
          </div>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            side="right"
            className="w-[64rem] max-w-full origin-left scale-50 bg-background @container"
          >
            <DefaultComponentTemplate componentType={name} className="" />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </DrawerDraggable>
  );
};

export const Drawer = ({
  children,
  droppableId: _droppableId = "default",
  direction = "vertical",
}: {
  children: ReactNode;
  droppableId?: string;
  direction?: "vertical" | "horizontal";
}) => {
  const droppableId = `component-list:${_droppableId}`;

  return (
    <drawerContext.Provider value={{ droppableId }}>
      <Droppable droppableId={droppableId} isDropDisabled direction={direction}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}

            {/* Use different element so we don't clash with :last-of-type */}
            <span style={{ display: "none" }}>{provided.placeholder}</span>
          </div>
        )}
      </Droppable>
    </drawerContext.Provider>
  );
};

Drawer.Item = DrawerItem;
