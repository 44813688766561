"use client";

import { getConfig } from "./puckConfig";
import { Render } from "@/website-editor/core/rsc";
import { type Data } from "@/website-editor/core";
import { useEffect, useMemo, useState } from "react";
import { useOrganization } from "@/student/context/OrganizationContext";
import { resolveAllData } from "@/website-editor/core";

const PuckRender: React.FC<{
  data: Data;
  configuration?: Record<string, any>;
}> = ({ data, configuration }) => {
  const organization = useOrganization();

  const config = useMemo(() => {
    return getConfig({
      id: organization.id,
      slug: organization.slug,
    });
  }, [organization.id, organization.slug]);

  const mutableData = useMemo(() => {
    const clone = structuredClone(data);

    if (!configuration) return clone;

    return {
      ...clone,
      root: {
        ...clone.root,
        props: {
          ...clone.root.props,
          ...configuration,
        },
      },
    };
  }, [data, configuration]);

  const [resolvedData, setResolvedData] = useState<Data | null>(null);

  useEffect(() => {
    if (mutableData) {
      // this is a hack to get around the fact that the relay returns a frozen object but website-editor needs a mutable object
      resolveAllData(mutableData, config).then((result) => {
        setResolvedData(result);
      });
    }
  }, [mutableData, config]);

  if (mutableData === null && resolvedData === null) {
    return null;
  }

  return <Render config={config} data={resolvedData ?? mutableData} />;
};

export default PuckRender;
