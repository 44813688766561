import type { AppState } from "../types/Config";
import { PuckAction } from "../reducer";
import { useHotkeys } from "react-hotkeys-hook";
import { HistoryStore } from "./use-history-store";
import { useCallback } from "react";

export type PuckHistory = {
  back: VoidFunction;
  forward: VoidFunction;
  historyStore: HistoryStore;
};

export function usePuckHistory({
  dispatch,
  initialAppState,
  historyStore,
}: {
  dispatch: (action: PuckAction) => void;
  initialAppState: AppState;
  historyStore: HistoryStore;
}) {
  const back = useCallback(() => {
    if (historyStore.hasPast) {
      dispatch({
        type: "set",
        state: historyStore.prevHistory?.data || initialAppState,
      });

      historyStore.back();
    }
  }, [dispatch, historyStore, initialAppState]);

  const forward = useCallback(() => {
    if (historyStore.nextHistory) {
      dispatch({ type: "set", state: historyStore.nextHistory.data });

      historyStore.forward();
    }
  }, [dispatch, historyStore]);

  useHotkeys("meta+z", back, { preventDefault: true });
  useHotkeys("meta+shift+z", forward, { preventDefault: true });
  useHotkeys("meta+y", forward, { preventDefault: true });

  return {
    back,
    forward,
    historyStore,
  };
}
