import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { getCourseAcademicTermField } from "@/website-editor/custom-fields/courseAcademicTerm";

import PurchasePriceAndActions from "@/website-editor/blocks/purchase-section/PurchasePriceAndActions";
import ResolveDataComponentHelper from "@/website-editor/components/ResolveDataComponentHelper";
import { Card, CardContent } from "@/components/ui/card";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import loadSerializableQuery from "@/relay/loadSerializableQuery";
import PurchasePriceAndActionsQueryNode, {
  PurchasePriceAndActionsQuery,
} from "@generated/PurchasePriceAndActionsQuery.graphql";

export type PurchaseSectionProps = {
  sectionId?: string;
  theme: Theme;
  border: boolean;
  unauthorizedRedirectUrl: string;
  sticky: boolean;
  courseAcademicTerm: { id?: string; __preloadedQuery?: any };
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export function getPurchaseSection(organization: { slug: string }) {
  return {
    displayName: "Satın Alma Bölümü",
    tooltip:
      "Ders siparişi oluşturup ödeme sayfasına yönlendiren bir buton içerir.",
    fields: {
      sectionId: SectionIdField,
      courseAcademicTerm: getCourseAcademicTermField(organization),
      unauthorizedRedirectUrl: {
        type: "text",
        label: "Giriş Yapılmamış Kullanıcı Yönlendirme URL'i",
      },
      border: {
        type: "radio",
        options: [
          { label: "Kenarlık", value: true },
          { label: "Kenarlık Yok", value: false },
        ],
        label: "Kenarlık",
      },
      sticky: {
        type: "radio",
        options: [
          { label: "Evet", value: true },
          { label: "Hayır", value: false },
        ],
        label: "Yapışkan ()",
      },
      theme: ThemeField,
      paddingLeft: {
        ...PaddingField,
        label: "Sol Kenar Boşluğu",
      },
      paddingRight: {
        ...PaddingField,
        label: "Sağ Kenar Boşluğu",
      },
      paddingTop: {
        ...PaddingField,
        label: "Üst Kenar Boşluğu",
      },
      paddingBottom: {
        ...PaddingField,
        label: "Alt Kenar Boşluğu",
      },
    },
    defaultProps: {
      theme: "light",
      border: true,
      sticky: true,
      sectionId: "",
      paddingTop: "xl",
      paddingLeft: "lg",
      paddingRight: "lg",
      paddingBottom: "xl",
      unauthorizedRedirectUrl: "",
      courseAcademicTerm: {},
    },
    displayDefaultPropOverrides: {
      courseAcademicTerm: {
        id: "Q291cnNlQWNhZGVtaWNUZXJtOm1pdDQ2ODR3aHYwZmVqMmlzcHY0OXo5Nw==",
      },
    },
    resolveData: async (data, { changed }) => {
      if (!data.props.courseAcademicTerm?.id) {
        return data;
      }

      if (!changed.courseAcademicTerm) {
        return data;
      }

      const preloadedQuery = await loadSerializableQuery<
        typeof PurchasePriceAndActionsQueryNode,
        PurchasePriceAndActionsQuery
      >(PurchasePriceAndActionsQueryNode.params, {
        id: data.props.courseAcademicTerm.id,
      });

      return {
        ...data,
        props: {
          ...data.props,
          courseAcademicTerm: {
            ...data.props.courseAcademicTerm,
            __preloadedQuery: preloadedQuery,
          },
        },
      };
    },
    render: ({
      puck: { renderDropZone },
      sectionId,
      unauthorizedRedirectUrl,
      border = true,
      sticky = true,
      theme = "light",
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      courseAcademicTerm,
    }) => {
      return (
        <Card
          className={cn("relative isolate w-full bg-background", {
            "border-0 shadow-none": !border,
            "sticky top-4": sticky,
          })}
          id={sectionId || undefined}
        >
          <CardContent
            className={cn(
              paddingVariants({ side: "left", size: paddingLeft }),
              paddingVariants({ side: "right", size: paddingRight }),
              paddingVariants({ side: "top", size: paddingTop }),
              paddingVariants({ side: "bottom", size: paddingBottom }),
            )}
          >
            {renderDropZone({
              zone: "above",
            })}
            <ResolveDataComponentHelper
              data={courseAcademicTerm}
              Empty={
                <p
                  className={cn(
                    "text-lg leading-8",
                    textColorVariants({ theme }),
                  )}
                >
                  Bir ders seçin
                </p>
              }
              Loading={
                <p
                  className={cn(
                    "text-lg leading-8",
                    mutedTextColorVariants({ theme }),
                  )}
                >
                  Yükleniyor...
                </p>
              }
            >
              <PurchasePriceAndActions
                preloadedQuery={courseAcademicTerm?.__preloadedQuery}
                unauthorizedRedirectUrl={unauthorizedRedirectUrl}
                theme={theme}
              />
            </ResolveDataComponentHelper>
            {renderDropZone({
              zone: "below",
            })}
          </CardContent>
        </Card>
      );
    },
  } satisfies ComponentConfig<PurchaseSectionProps>;
}
