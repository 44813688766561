import { ComponentConfig } from "@/website-editor/core";
import { StarFilledIcon } from "@radix-ui/react-icons";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { cn } from "@/lib/utils";
import { MarginField } from "@/website-editor/custom-fields/margin";
import Image from "next/image";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type TestimonialProps = {
  sectionId?: string;
  quote: string;
  author: string;
  authorTitle: string;
  authorImage: {
    url: string;
    alt?: string;
  } | null;
  showStars: boolean;
  greaterImage: boolean;
  imagePosition: "left" | "right";
  imageRoundness: Rounded;
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const Testimonial = {
  displayName: "Referans Yorum",
  fields: {
    sectionId: SectionIdField,
    quote: { type: "richtextarea", label: "Alıntı" },
    author: { type: "textarea", label: "Kullancı" },
    authorTitle: { type: "textarea", label: "Kullanıcı Ünvanı" },
    authorImage: {
      ...ImageCustomField,
      label: "Resim",
    },
    imageRoundness: RoundedField,
    showStars: {
      type: "radio",
      label: "Yıldızları Göster",
      options: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    greaterImage: {
      type: "radio",
      label: "Büyük Resim",
      options: [
        {
          label: "Evet",
          value: true,
        },
        {
          label: "Hayır",
          value: false,
        },
      ],
    },
    imagePosition: {
      showIf: (data) => {
        return Boolean(data.greaterImage);
      },
      type: "radio",
      label: "Resim Pozisyonu",
      options: [
        { value: "left", label: "Sol" },
        { value: "right", label: "Sağ" },
      ],
    },
    theme: ThemeField,
    paddingLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id purus ultricies, ultricies nunc quis, ultrices nisl. Nulla facilisi.",
    author: "Author",
    authorTitle: "Author Title",
    authorImage: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      alt: "Author",
    },
    showStars: true,
    greaterImage: false,
    imagePosition: "left",
    imageRoundness: "full",
    theme: "light",
    paddingLeft: "md",
    paddingRight: "md",
    paddingTop: "lg",
    paddingBottom: "lg",
  },
  render: ({
    sectionId,
    quote,
    author,
    authorTitle,
    authorImage,
    greaterImage,
    imagePosition,
    showStars,
    theme,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingTop,
    imageRoundness,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div
          className={cn("mx-auto max-w-7xl flex flex-col", {
            "sm:flex-row-reverse": imagePosition === "right",
            "sm:flex-row": imagePosition === "left",
          })}
        >
          {authorImage && greaterImage && (
            <Image
              src={authorImage?.url ?? ""}
              alt={authorImage?.alt ?? ""}
              width={512}
              height={512}
              unoptimized={!authorImage?.url.includes(".pi.education/")}
              className={cn(
                "ring-1 shrink-0 self-center w-96 max-w-full mb-8 sm:mb-0",
                roundedVariants({ rounded: imageRoundness }),
              )}
            />
          )}
          <figure className="flex flex-col justify-center px-12">
            <p className="sr-only">5 üzerinden 5 yıldız</p>
            {showStars && (
              <div className="mb-8 flex gap-x-1 text-yellow-500">
                <StarFilledIcon
                  className="size-5 flex-none"
                  aria-hidden="true"
                />
                <StarFilledIcon
                  className="size-5 flex-none"
                  aria-hidden="true"
                />
                <StarFilledIcon
                  className="size-5 flex-none"
                  aria-hidden="true"
                />
                <StarFilledIcon
                  className="size-5 flex-none"
                  aria-hidden="true"
                />
                <StarFilledIcon
                  className="size-5 flex-none"
                  aria-hidden="true"
                />
              </div>
            )}
            <blockquote
              className={cn(
                "text-xl font-semibold leading-8 tracking-tight sm:text-2xl sm:leading-9 whitespace-pre-line break-words",
                mutedTextColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: quote }}
            />
            <figcaption className="mt-8 flex items-center gap-x-6">
              {authorImage?.url && !greaterImage && (
                <Image
                  src={authorImage.url ?? ""}
                  alt={authorImage.alt ?? ""}
                  width={540}
                  height={540}
                  unoptimized={!authorImage.url?.includes(".pi.education/")}
                  className={cn(
                    "size-12 rounded-full bg-gray-50",
                    roundedVariants({ rounded: imageRoundness }),
                  )}
                />
              )}
              <div className="text-sm leading-6">
                <div>
                  <p
                    className={cn(
                      "font-semibold whitespace-pre-line break-words",
                      textColorVariants({ theme }),
                    )}
                  >
                    {author}
                  </p>
                </div>
                <div>
                  <p
                    className={cn(
                      "mt-0.5 whitespace-pre-line break-words",
                      textColorVariants({ theme }),
                    )}
                  >
                    {authorTitle}
                  </p>
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<TestimonialProps>;
