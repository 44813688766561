import { cva } from "class-variance-authority";

export type TextCentering = "left" | "right" | "center";

export const TextCenteringField = {
  type: "radio" as const,
  label: "Centering",
  options: [
    {
      value: "left",
      label: "Left",
    },
    {
      value: "center",
      label: "Center",
    },
    {
      value: "right",
      label: "Right",
    },
  ],
};

export const textCenteringVariants = cva(undefined, {
  variants: {
    centering: {
      left: "text-left",
      right: "text-right",
      center: "text-center",
    },
  },
  defaultVariants: {
    centering: "left",
  },
});
