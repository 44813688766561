import { cva } from "class-variance-authority";

export type AlignItems = "start" | "end" | "center" | "baseline" | "stretch";

export const AlignItemsField = {
  type: "select" as const,
  label: "Yan Eksende İçerik Pozisyonu",
  options: [
    { value: "start", label: "Başlangıçta Hizala" },
    { value: "center", label: "Ortada Hizala" },
    { value: "end", label: "Sonda Hizala" },
    {
      value: "baseline",
      label:
        "İçeriklerin alt kenarlarını hizalayarak, içeriklerin yatay eksendeki hizalamasını ayarla",
    },
    {
      value: "stretch",
      label: "İçerikleri tüm boşluğu dolduracak şekilde büyüt",
    },
  ],
};

export const alignItemsVariants = cva(undefined, {
  variants: {
    alignItems: {
      start: "items-start",
      center: "items-center",
      end: "items-end",
      baseline: "items-baseline",
      stretch: "items-stretch",
    },
  },
  defaultVariants: {
    alignItems: "start",
  },
});
