"use client";

import { CustomField } from "@/website-editor/core";
import { FontFamilyIcon } from "@radix-ui/react-icons";
import React from "react";
import "react-fontpicker-ts/dist/index.css";
import dynamic from "next/dynamic";

const FontPicker = dynamic(() => import("react-fontpicker-ts"), {
  ssr: false,
  loading: () => <div>Fontlar Yükleniyor...</div>,
});

export type Font = any;

const FieldRenderer: React.FC<Parameters<CustomField<Font>["render"]>[0]> = ({
  id,
  field,
  name,
  value,
  onChange,
  readOnly,
}) => {
  return (
    <div className="[&_.fontpicker\\_\\_option.selected]:bg-gray-500/25 [&_.fontpicker\\_\\_option]:bg-background [&_.fontpicker\\_\\_popout]:bg-background [&_.fontpicker\\_\\_preview]:bg-background [&_.fontpicker\\_\\_preview]:invert-0 [&_.fontpicker\\_\\_search]:bg-background">
      <div className="flex items-center">
        <FontFamilyIcon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      <FontPicker
        aria-readonly={readOnly}
        defaultValue={value ?? "Inter"}
        value={(font: string) => {
          if (!readOnly) onChange(font as Font);
        }}
      />
    </div>
  );
};

export const FontCustomField = {
  type: "custom",
  render: (props) => <FieldRenderer {...props} />,
} satisfies CustomField<Font>;
