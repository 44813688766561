"use client";

import { useEditorSelector } from "@udecode/plate-common";
import { slugifyPlateHeading } from "@/lib/slugify";
import Link from "next/link";
import { cn } from "@/lib/utils";
import React from "react";
import isEqual from "react-fast-compare";

const PlateToc: React.FC = () => {
  const children = useEditorSelector((editor) => editor.children, [], {
    equalityFn: isEqual,
  });

  const headings = children.filter((node) => {
    return node.type === "h2" || node.type === "h3";
  });

  return (
    <nav className="hidden sm:block">
      <h4 className="text-base font-semibold">İçerik</h4>
      <ul className="space-y-2 pt-2">
        {headings.map((heading) => {
          const { text, slug } = slugifyPlateHeading(heading);
          return (
            <li
              key={slug}
              className={cn(
                "text-muted-foreground",
                heading.type === "h2" ? "text-sm" : "text-xs ml-4",
              )}
            >
              <Link href={`#${slug}`}>{text}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default PlateToc;
