import {
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { ComponentConfig } from "@/website-editor/core";
import Image from "next/image";
import { cn, hsv2hex } from "@/lib/utils";
import { MarginField } from "@/website-editor/custom-fields/margin";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import PiLink from "@/components/PiLink";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";

export type LogoCloudSectionProps = {
  sectionId?: string;
  slogan: string;
  logos: Array<{
    logo: {
      url: string;
      alt?: string;
    } | null;
    url?: string;
  }>;
  text: string;
  linkedText: string | null;
  linkedTextColor?: HarmonyValue;
  link: string;
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const LogoCloudSection = {
  displayName: "Logo Bulutu Bölümü",
  tooltip:
    "Logo bulutu bölümü genellikle sosyal kanıt sağlamak için kullanılır. Referans müşterilerin logoları, müşteri sayısı, müşteri referansları gibi bilgiler içerebilir.",
  fields: {
    sectionId: SectionIdField,
    slogan: {
      type: "textarea",
      label: "Slogan",
    },
    logos: {
      type: "array",
      label: "Logolar",
      getItemSummary: (item, idx) => `Logo ${(idx ?? 0) + 1}`,
      arrayFields: {
        logo: {
          ...ImageCustomField,
          label: "Logo",
        },
        url: {
          type: "text",
          label: "URL",
        },
      },
      defaultItemProps: {
        logo: {
          url: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/512px-Logo_of_Twitter.svg.png",
          alt: "",
        },
      },
    },
    text: {
      type: "textarea",
      label: "Metin",
    },
    linkedText: {
      type: "textarea",
      label: "Bağlantılı Metin",
    },
    linkedTextColor: {
      ...HarmonyCustomField,
      label: "Bağlantılı Metin Özel Renk",
    },
    link: {
      type: "text",
      label: "Bağlantı",
    },
    theme: ThemeField,
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    paddingLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    link: "#",
    text: "1500'den fazla eğitim kurumu başarı için bizi tercih ediyor.",
    logos: [
      {
        logo: {
          alt: "Boston University Logo",
          url: "https://logos-world.net/wp-content/uploads/2022/01/Boston-University-Logo.png",
        },
      },
      {
        logo: {
          alt: "Stanford University Emblem",
          url: "https://logos-world.net/wp-content/uploads/2021/10/Stanford-Emblem.png",
        },
      },
      {
        logo: {
          alt: "MIT Logo",
          url: "https://1000logos.net/wp-content/uploads/2022/08/MIT-Logo.png",
        },
      },
    ],
    theme: "light",
    slogan: "Gelecek Nesillerin Eğitimini Güçlendiriyoruz",
    sectionId: "",
    linkedText: "Eğitim programlarımızı keşfedin",
    linkedTextColor: [],
    paddingTop: "lg",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingBottom: "lg",
  },
  render: ({
    sectionId,
    slogan,
    logos,
    text,
    linkedText,
    linkedTextColor = [],
    link,
    theme,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "flex flex-col md:flex-row bg-white overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div className="mx-auto max-w-7xl space-y-4 px-6 sm:space-y-8 lg:px-8">
          <h2
            className={cn(
              "text-center text-lg font-semibold leading-8 whitespace-pre-line break-words",
              textColorVariants({ theme }),
            )}
          >
            {slogan}
          </h2>
          <div className="mx-auto text-center">
            <div className="grid grid-flow-col-dense gap-x-12 gap-y-4">
              {logos.map((item, index) =>
                item.url ? (
                  <PiLink
                    href={item.url}
                    key={index}
                    className="col-span-2 lg:col-span-1"
                  >
                    <Image
                      src={item.logo?.url ?? ""}
                      alt={""}
                      width={1080}
                      height={1080}
                      unoptimized={!item.logo?.url.includes(".pi.education/")}
                      className="h-12 w-auto object-contain"
                    />
                  </PiLink>
                ) : (
                  <Image
                    key={index}
                    src={item.logo?.url ?? ""}
                    alt={""}
                    width={1080}
                    height={1080}
                    unoptimized={!item.logo?.url.includes(".pi.education/")}
                    className="col-span-2 h-12 w-auto object-contain lg:col-span-1"
                  />
                ),
              )}
            </div>
          </div>
          {(text || linkedText) && (
            <div className="flex justify-center">
              <p
                className={cn(
                  "relative rounded-full px-4 py-1.5 text-sm leading-6 ring-1 bg-background ring-inset ring-gray-900/5",
                  textColorVariants({ theme }),
                )}
              >
                <span className="hidden whitespace-pre-line break-words md:inline">
                  {text}
                </span>
                {linkedText && (
                  <a
                    href={link}
                    className="whitespace-pre-line break-words font-semibold text-indigo-600"
                    style={
                      linkedTextColor[0]
                        ? {
                            color: hsv2hex(
                              linkedTextColor[0].hue,
                              linkedTextColor[0].saturation,
                              linkedTextColor[0].value,
                            ),
                          }
                        : undefined
                    }
                  >
                    <span className="absolute inset-0" aria-hidden="true" />{" "}
                    {linkedText} <span aria-hidden="true">&rarr;</span>
                  </a>
                )}
              </p>
            </div>
          )}
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<LogoCloudSectionProps>;
