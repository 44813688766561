"use client";

import { CSSProperties, ReactNode, SyntheticEvent, useEffect } from "react";
import { Draggable } from "@hello-pangea/dnd";
import styles from "./styles.module.css";
import getClassNameFactory from "../../lib/get-class-name-factory";
import { Save, Copy, Trash } from "lucide-react";
import { useModifierHeld } from "../../lib/use-modifier-held";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { PopoverPortal } from "@radix-ui/react-popover";
import { PopoverAnchor } from "@/components/plate-ui/popover";
import { Loader } from "@/website-editor/core/components/Loader";

const getClassName = getClassNameFactory("DraggableComponent", styles);

export const DraggableComponent = ({
  children,
  id,
  index,
  isLoading = false,
  isSelected = false,
  onClick = () => null,
  onMount = () => null,
  onMouseDown = () => null,
  onMouseUp = () => null,
  onMouseOver = () => null,
  onMouseOut = () => null,
  onSave = () => null,
  onDelete = () => null,
  onDuplicate = () => null,
  debug,
  label,
  isLocked = false,
  isDragDisabled,
  forceHover = false,
  indicativeHover = false,
  style,
}: {
  children: ReactNode;
  id: string;
  index: number;
  isSelected?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onMount?: () => void;
  onMouseDown?: (e: SyntheticEvent) => void;
  onMouseUp?: (e: SyntheticEvent) => void;
  onMouseOver?: (e: SyntheticEvent) => void;
  onMouseOut?: (e: SyntheticEvent) => void;
  onSave?: (e: SyntheticEvent) => void;
  onDelete?: (e: SyntheticEvent) => void;
  onDuplicate?: (e: SyntheticEvent) => void;
  debug?: string;
  label?: string;
  isLocked: boolean;
  isLoading: boolean;
  isDragDisabled?: boolean;
  forceHover?: boolean;
  indicativeHover?: boolean;
  style?: CSSProperties;
}) => {
  const isModifierHeld = useModifierHeld("Alt");

  useEffect(onMount, []);

  return (
    <Popover open={isSelected}>
      <Draggable
        key={id}
        draggableId={id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={getClassName({
              isSelected,
              isModifierHeld,
              isDragging: snapshot.isDragging,
              isLocked,
              forceHover,
              indicativeHover,
            })}
            style={{
              ...style,
              ...provided.draggableProps.style,
              cursor: isModifierHeld ? "initial" : "grab",
            }}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onClick={onClick}
          >
            {debug}
            {isLoading && (
              <div className={getClassName("loadingOverlay")}>
                <Loader size={16} />
              </div>
            )}
            <PopoverPortal>
              <PopoverContent
                side="top"
                align="end"
                className="flex w-auto bg-foreground p-1"
              >
                {label && (
                  <div className={getClassName("actionsLabel")}>{label}</div>
                )}
                <button className={getClassName("action")} onClick={onSave}>
                  <Save size={16} />
                </button>
                <button
                  className={getClassName("action")}
                  onClick={onDuplicate}
                >
                  <Copy size={16} />
                </button>
                <button className={getClassName("action")} onClick={onDelete}>
                  <Trash size={16} />
                </button>
              </PopoverContent>
            </PopoverPortal>
            <PopoverAnchor>
              <div className={getClassName("contents")}>{children}</div>
            </PopoverAnchor>
          </div>
        )}
      </Draggable>
    </Popover>
  );
};
