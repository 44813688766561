import * as React from "react";
import {
  TextCentering,
  TextCenteringField,
  textCenteringVariants,
} from "@/website-editor/custom-fields/textCentering";
import {
  FontSize,
  FontSizeField,
  fontSizeVariants,
} from "@/website-editor/custom-fields/fontSize";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { Label, LabelField } from "@/website-editor/custom-fields/label";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import {
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  FontWeight,
  FontWeightField,
  fontWeightVariants,
} from "@/website-editor/custom-fields/fontWeight";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type TitleProps = {
  sectionId?: string;
  title: string;
  fontSize: FontSize;
  fontWeight: FontWeight;
  centering: TextCentering;
  label: Label;
  theme: Theme;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Title = {
  displayName: "Başlık",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    fontSize: FontSizeField,
    fontWeight: FontWeightField,
    centering: {
      ...TextCenteringField,
      label: "Yazı Ortalama",
    },
    label: {
      ...LabelField,
      label: "Seviye",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    title: "Başlık",
    fontSize: "2xl",
    fontWeight: "bold",
    centering: "center",
    label: 3,
    paddingTop: "lg",
    paddingBottom: "lg",
    marginLeft: "lg",
    marginRight: "lg",
  },
  render: ({
    sectionId,
    title,
    fontSize,
    fontWeight,
    centering,
    label,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    theme,
  }) => {
    const Tag = `h${label}` as const;
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "relative isolate overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: marginLeft }),
          paddingVariants({ side: "right", size: marginRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <Tag
          className={cn(
            "flex-wrap whitespace-pre-line break-words",
            textCenteringVariants({ centering }),
            fontSizeVariants({ fontSize }),
            fontWeightVariants({ fontWeight }),
            textColorVariants({ theme }),
          )}
        >
          {title}
        </Tag>
      </section>
    );
  },
} satisfies ComponentConfig<TitleProps>;
