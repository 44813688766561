/**
 * @generated SignedSource<<48055b90affe6e537316473b2ae60c8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type OtpChannel = "EMAIL" | "SMS" | "WHATSAPP" | "%future added value";
export type LoginWithOtpInput = {
  channel: OtpChannel;
  identifier: string;
  organizationId: string;
  otp: string;
};
export type EmailOtpLoginLoginWithOtpMutation$variables = {
  input: LoginWithOtpInput;
};
export type EmailOtpLoginLoginWithOtpMutation$data = {
  readonly loginWithOtp: {
    readonly credentials: {
      readonly accessToken: any;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type EmailOtpLoginLoginWithOtpMutation = {
  response: EmailOtpLoginLoginWithOtpMutation$data;
  variables: EmailOtpLoginLoginWithOtpMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginWithOtpPayload",
    "kind": "LinkedField",
    "name": "loginWithOtp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthCredentials",
        "kind": "LinkedField",
        "name": "credentials",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "userErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailOtpLoginLoginWithOtpMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailOtpLoginLoginWithOtpMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3b683e01a1c4ecc7f595397e84b006c8",
    "id": null,
    "metadata": {},
    "name": "EmailOtpLoginLoginWithOtpMutation",
    "operationKind": "mutation",
    "text": "mutation EmailOtpLoginLoginWithOtpMutation(\n  $input: LoginWithOtpInput!\n) {\n  loginWithOtp(input: $input) {\n    credentials {\n      accessToken\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca0682e3459428dda50c1d2722f93931";

export default node;
