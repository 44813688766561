import {
  mutedTextColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import loadSerializableQuery from "@/relay/loadSerializableQuery";
import BlogsActiveBlogsQueryNode, {
  BlogsActiveBlogsQuery,
} from "@generated/BlogsActiveBlogsQuery.graphql";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import Blogs from "@/website-editor/blocks/blogs/Blogs";

export type BlogsProps = {
  sectionId?: string;
  theme: Theme;
  blogs: { __preloadedQuery?: any };
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export function getBlogs(organization: { slug: string }) {
  return {
    displayName: "Bloglar Bölümü",
    fields: {
      sectionId: SectionIdField,
      blogs: {
        type: "custom",
        render: () => <></>,
      },
      theme: ThemeField,
      paddingLeft: {
        ...PaddingField,
        label: "Sol Kenar Boşluğu",
      },
      paddingRight: {
        ...PaddingField,
        label: "Sağ Kenar Boşluğu",
      },
      paddingTop: {
        ...PaddingField,
        label: "Üst Kenar Boşluğu",
      },
      paddingBottom: {
        ...PaddingField,
        label: "Alt Kenar Boşluğu",
      },
    },
    defaultProps: {
      sectionId: "",
      theme: "light",
      blogs: {},
      paddingLeft: "none",
      paddingRight: "none",
      paddingTop: "none",
      paddingBottom: "none",
    },
    resolveData: async (data, { changed }) => {
      if (data.props.blogs?.__preloadedQuery) {
        return data;
      }

      const preloadedQuery = await loadSerializableQuery<
        typeof BlogsActiveBlogsQueryNode,
        BlogsActiveBlogsQuery
      >(BlogsActiveBlogsQueryNode.params, {
        organizationSlug: organization.slug,
      });

      return {
        ...data,
        props: {
          ...data.props,
          blogs: {
            __preloadedQuery: preloadedQuery,
          },
        },
      };
    },
    render: ({
      puck: { renderDropZone },
      sectionId,
      theme,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      blogs,
    }) => {
      return (
        <section
          id={sectionId || undefined}
          className={cn(
            "relative isolate overflow-clip bg-background",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
          )}
        >
          {blogs.__preloadedQuery ? (
            <Blogs preloadedQuery={blogs.__preloadedQuery} theme={theme} />
          ) : (
            <p
              className={cn(
                "text-lg leading-8",
                mutedTextColorVariants({ theme }),
              )}
            >
              Yükleniyor...
            </p>
          )}
        </section>
      );
    },
  } satisfies ComponentConfig<BlogsProps>;
}
