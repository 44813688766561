import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { iconOptions } from "@/website-editor/custom-fields/icon";
import { cn, hsv2hex } from "@/lib/utils";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import { ButtonProps, ButtonsArrayField } from "@/website-editor/blocks/button";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import BackgroundGradient from "@/components/background-gradient";
import FeaturesSectionComponent from "./FeaturesSection";

export type FeaturesSectionProps = {
  sectionId?: string;
  title: string;
  slogan: string;
  description: string;
  customPrimaryColor: HarmonyValue;
  theme: Theme;
  harmony: HarmonyValue;
  customColorIsGradient: boolean;
  features: Array<{
    title: string;
    description: string;
    icon?: string;
    buttons: Array<ButtonProps>;
  }>;
  image: {
    url: string;
    alt?: string;
  } | null;
  revealAnimation: "none" | "fade-in-to-center";
  imagePosition: "left" | "right";
  imageOverflowType: "overflow" | "contain";
  imageSticky: boolean;
  buttons: Array<ButtonProps>;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const FeaturesSection = {
  displayName: "Özellikler Bölümü",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    slogan: {
      type: "textarea",
      label: "Slogan",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    theme: ThemeField,
    customPrimaryColor: {
      ...HarmonyCustomField,
      label: "Özel Ana Renk",
    },
    buttons: ButtonsArrayField,
    features: {
      type: "array",
      label: "Özellikler",
      getItemSummary: (item, idx) => item.title || `Özellik ${(idx ?? 0) + 1}`,
      arrayFields: {
        icon: {
          type: "select",
          label: "İkon",
          options: iconOptions,
        },
        title: {
          type: "textarea",
          label: "Başlık",
        },
        description: {
          type: "richtextarea",
          label: "Açıklama",
        },
        buttons: ButtonsArrayField,
      },
      defaultItemProps: {
        title: "Özellik",
        description: "Açıklama",
        icon: "MagicWandIcon",
        buttons: [],
      },
    },
    image: {
      ...ImageCustomField,
      label: "Resim",
    },
    imagePosition: {
      type: "radio",
      label: "Resim Pozisyonu",
      options: [
        {
          value: "left",
          label: "Sol",
        },
        {
          value: "right",
          label: "Sağ",
        },
      ],
    },
    imageOverflowType: {
      type: "radio",
      label: "Resim Tarzı",
      options: [
        {
          value: "overflow",
          label: "Ekrandan Taşan",
        },
        {
          value: "contain",
          label: "Sığdırılmış",
        },
      ],
    },
    imageSticky: {
      type: "radio",
      label: "Yapışkan Resim ",
      options: [
        {
          value: false,
          label: "Hayır",
        },
        {
          value: true,
          label: "Evet",
        },
      ],
    },
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    customColorIsGradient: {
      type: "radio",
      label: "Arkaplan Rengi Tarzı",
      options: [
        {
          value: true,
          label: "Gradient",
        },
        {
          value: false,
          label: "Solid",
        },
      ],
    },
    revealAnimation: {
      type: "radio",
      label: "Gösterim Animasyonu",
      options: [
        {
          value: "none",
          label: "Yok",
        },
        {
          value: "fade-in-to-center",
          label: "Ortaya Doğru",
        },
      ],
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      fields: [
        "sectionId",
        "title",
        "slogan",
        "description",
        "customPrimaryColor",
        "buttons",
        "theme",
        "features",
      ],
      defaultExpanded: true,
    },
    image: {
      title: "Resim",
      defaultExpanded: true,
      fields: ["image", "imagePosition", "imageOverflowType", "imageSticky"],
    },
    background: {
      title: "Arkaplan",
      defaultExpanded: false,
      fields: ["harmony", "customColorIsGradient"],
    },
    animation: {
      title: "Animasyon",
      defaultExpanded: false,
      fields: ["revealAnimation"],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"],
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    title: "Özenle tasarlanmış konu akışı",
    slogan: "Daha iyi öğren",
    buttons: [],
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      alt: "",
    },
    imagePosition: "right",
    imageOverflowType: "contain",
    imageSticky: true,
    features: [
      {
        title: "Konu anlatımları",
        description:
          "Konu anlatımlarımızı izleyerek konuları daha iyi anlayabilirsin.",
        icon: "MagicWandIcon",
        buttons: [],
      },
      {
        title: "İnteraktif sorular",
        description:
          "Yüzlerce çözümlü soruyla kendini test et, eksik olduğun konuları farket ve tekrar et!",
        icon: "MagicWandIcon",
        buttons: [],
      },
      {
        title: "Bireysel takip ve destek",
        description:
          "Eğitmenlerimiz ilerlemeni takip eder ve sana özel destek sağlar.",
        icon: "MagicWandIcon",
        buttons: [],
      },
    ],
    revealAnimation: "none",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingTop: "2xl",
    paddingBottom: "2xl",
    harmony: [],
    customColorIsGradient: true,
    customPrimaryColor: [],
  },
  render: ({
    puck: { renderDropZone },
    id,
    sectionId,
    image,
    imageOverflowType = "contain",
    imagePosition = "right",
    imageSticky = true,
    title,
    slogan,
    description,
    features,
    paddingLeft = "lg",
    paddingRight = "lg",
    paddingTop = "2xl",
    paddingBottom = "2xl",
    theme = "light",
    buttons,
    harmony = [],
    customColorIsGradient = true,
    customPrimaryColor = [],
    revealAnimation = "none",
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "@container overflow-clip relative isolate",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
            "bg-background": customColorIsGradient || harmony.length === 0,
          },
        )}
        style={
          !customColorIsGradient && harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      >
        {customColorIsGradient && harmony.length > 0 && (
          <BackgroundGradient id={id} harmony={harmony} />
        )}
        <div
          className={cn(
            "mx-auto max-w-7xl",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
          )}
        >
          <FeaturesSectionComponent
            title={title}
            slogan={slogan}
            description={description}
            customPrimaryColor={customPrimaryColor}
            theme={theme}
            features={features}
            image={image}
            imagePosition={imagePosition}
            imageOverflowType={imageOverflowType}
            imageSticky={imageSticky}
            buttons={buttons}
            revealAnimation={revealAnimation}
            dropzone={renderDropZone({
              zone: "features-section-dropzone",
              style: { background: "none" },
            })}
          />
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<FeaturesSectionProps>;
