import { ComponentConfig } from "@/website-editor/core";

import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import { cn } from "@/lib/utils";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import dynamic from "next/dynamic";
const VideoPlayer = dynamic(() => import("@/components/video-player"), {});

export type VideoProps = {
  sectionId?: string;
  videoUrl: string;
  width: number | null;
  rounded: Rounded;
  videoTitle: string;
  theme: Theme;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Video = {
  displayName: "Video",
  fields: {
    sectionId: SectionIdField,
    videoUrl: {
      type: "text",
      label: "Video URL",
    },
    videoTitle: {
      type: "textarea",
      label: "Video Başlık",
    },
    width: {
      type: "number",
      label: "Genişlik (px) - Full genişlik için boş bırakın",
    },
    rounded: RoundedField,
    theme: ThemeField,
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    videoUrl: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    videoTitle: "Video Başlık",
    width: null,
    rounded: "none",
    theme: "light",
    paddingTop: "none",
    paddingBottom: "none",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    sectionId,
    videoUrl,
    videoTitle,
    width,
    rounded,
    theme,
    paddingBottom,
    paddingTop,
    marginLeft,
    marginRight,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "overflow-clip bg-background flex flex-1", // bunu silince amk safarisi two column layout icinde sacmaliyor
          roundedVariants({ rounded }),
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: marginLeft }),
          paddingVariants({ side: "right", size: marginRight }),
        )}
        style={{ width: width ? `${width}px` : "100%" }}
      >
        <VideoPlayer src={videoUrl} title={videoTitle} mode="store" muted />
      </section>
    );
  },
} satisfies ComponentConfig<VideoProps>;
