"use client";

import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

export const Highlight: React.FC<
  React.PropsWithChildren<{
    highlightType?: "underline" | "background" | "none";
    className?: string;
    style?: React.CSSProperties;
  }>
> = ({ children, className, style, highlightType = "none" }) => {
  if (highlightType === "none") {
    return <>{children}</>;
  }

  return (
    <motion.span
      initial={{
        backgroundSize: highlightType === "underline" ? "0% 25%" : "0% 100%",
      }}
      animate={{
        backgroundSize:
          highlightType === "underline" ? "100% 25%" : "100% 100%",
      }}
      transition={{
        duration: 2,
        ease: "linear",
        delay: 0.5,
      }}
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition:
          highlightType === "underline" ? "left 85%" : "left center",
        display: "inline",
        ...style,
      }}
      className={cn(
        `relative inline-block pb-1 rounded-lg bg-gradient-to-r`,
        className,
      )}
    >
      {children}
    </motion.span>
  );
};
