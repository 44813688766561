import { graphql } from "react-relay";

export const courseAcademicTermPuckGraphqlQuery = graphql`
  query courseAcademicTermPuckQuery($organizationSlug: String!) {
    organization(slug: $organizationSlug) {
      id
      courseAcademicTerms(first: 100) {
        edges {
          node {
            id
            course {
              title
            }
            academicTerm {
              label
            }
          }
        }
      }
    }
  }
`;
