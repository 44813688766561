"use client";
import { graphql, usePreloadedQuery, useRelayEnvironment } from "react-relay";
import React, { Suspense } from "react";
import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
} from "@/website-editor/custom-fields/theme";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import CourseContentQueryNode, {
  CourseContentQuery,
} from "@generated/CourseContentQuery.graphql";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn, hsv2hex } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import ItemTypeBadge from "@/admin/courses/contents/components/ItemTypeBadge";
import FreeCourseContentDialog from "@/website-editor/blocks/course-content-section/FreeCourseContentDialog";
import NotFreeCourseContentDialog from "@/website-editor/blocks/course-content-section/NotFreeCourseContentDialog";
import LoadingScreen from "@/website-editor/blocks/course-content-section/LoadingScreen";
import { Badge } from "@/components/ui/badge";

const CourseContent: React.FC<{
  preloadedQuery: SerializablePreloadedQuery<
    typeof CourseContentQueryNode,
    CourseContentQuery
  >;
  theme: Theme;
  variant: "default" | "dense";
  customFreeBadgeColor?: any;
  customFreeBadgeTextColor?: any;
}> = ({
  preloadedQuery,
  theme,
  variant,
  customFreeBadgeColor,
  customFreeBadgeTextColor,
}) => {
  const environment = useRelayEnvironment();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);

  const data = usePreloadedQuery(
    graphql`
      query CourseContentQuery($id: ID!) {
        node(id: $id) {
          ... on CourseAcademicTerm {
            id
            sections {
              id
              title
              releaseDate
              description
              items {
                id
                title
                __typename
                slug
                freeAccess
              }
            }
          }
        }
      }
    `,
    queryRef,
  );

  if (!data) {
    return <p>No data</p>;
  }

  if (!data.node) {
    return <p>No node</p>;
  }

  return (
    <div>
      {data.node.sections?.map((section) => {
        const hasAnyFreeItems = section.items?.some((i) => i.freeAccess);

        return (
          <Accordion type="multiple" key={section.id}>
            <AccordionItem value={section.id} key={section.id}>
              <AccordionTrigger
                className={cn("space-y-1 hover:no-underline", {
                  "py-2": variant === "dense",
                })}
              >
                <div className="mr-2 flex w-full items-center">
                  <h2
                    className={cn(
                      "text-lg text-start font-semibold flex-1 break-words whitespace-pre-line",
                      textColorVariants({ theme }),
                    )}
                  >
                    {section.title}
                  </h2>
                  {hasAnyFreeItems ? (
                    <Badge
                      variant="success"
                      className={cn("shrink-0", {
                        "bg-unset": customFreeBadgeColor?.length > 0,
                        "text-unset": customFreeBadgeTextColor?.length > 0,
                      })}
                      style={{
                        ...(customFreeBadgeColor?.length > 0
                          ? {
                              backgroundColor: hsv2hex(
                                customFreeBadgeColor[0].hue,
                                customFreeBadgeColor[0].saturation,
                                customFreeBadgeColor[0].value,
                              ),
                            }
                          : {}),
                        ...(customFreeBadgeTextColor?.length > 0
                          ? {
                              color: hsv2hex(
                                customFreeBadgeTextColor[0].hue,
                                customFreeBadgeTextColor[0].saturation,
                                customFreeBadgeTextColor[0].value,
                              ),
                            }
                          : {}),
                      }}
                    >
                      Ücretsiz İçerik
                    </Badge>
                  ) : null}
                </div>
              </AccordionTrigger>

              <AccordionContent>
                {section.items?.map((item) => (
                  <Dialog key={item.id}>
                    <DialogTrigger className="flex items-center gap-2 py-2">
                      <h3
                        className={cn(
                          "text-md font-semibold text-start line-clamp-2 break-words whitespace-pre-line",
                          mutedTextColorVariants({ theme }),
                        )}
                      >
                        {item.title}
                      </h3>
                      <ItemTypeBadge
                        type={item.__typename}
                        className={cn(
                          "shrink-0 bg-background",
                          mutedTextColorVariants({ theme }),
                        )}
                      />
                      <span className="flex-1" />
                      {item.freeAccess ? (
                        <Badge
                          variant="success"
                          className={cn("shrink-0", {
                            "bg-unset": customFreeBadgeColor?.length > 0,
                            "text-unset": customFreeBadgeTextColor?.length > 0,
                          })}
                          style={{
                            ...(customFreeBadgeColor?.length > 0
                              ? {
                                  backgroundColor: hsv2hex(
                                    customFreeBadgeColor[0].hue,
                                    customFreeBadgeColor[0].saturation,
                                    customFreeBadgeColor[0].value,
                                  ),
                                }
                              : {}),
                            ...(customFreeBadgeTextColor?.length > 0
                              ? {
                                  color: hsv2hex(
                                    customFreeBadgeTextColor[0].hue,
                                    customFreeBadgeTextColor[0].saturation,
                                    customFreeBadgeTextColor[0].value,
                                  ),
                                }
                              : {}),
                          }}
                        >
                          Ücretsiz İçerik
                        </Badge>
                      ) : null}
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-5xl">
                      <Suspense fallback={<LoadingScreen />}>
                        {item.freeAccess ? (
                          <FreeCourseContentDialog itemId={item.id} />
                        ) : (
                          <NotFreeCourseContentDialog />
                        )}
                      </Suspense>
                    </DialogContent>
                  </Dialog>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CourseContent;
