"use client";

import { CustomField } from "@/website-editor/core";
import { ImageIcon, TrashIcon } from "@radix-ui/react-icons";
import { Input } from "@/components/ui/input";
import { useCallback } from "react";
import dynamic from "next/dynamic";
import { Button } from "@/components/ui/button";
const OrganizationImages = dynamic(
  () => import("@/admin/components/OrganizationImages"),
  { ssr: false },
);

interface ImageCustomFieldProps {}

const FieldRenderer: React.FC<
  Parameters<CustomField<ImageCustomFieldProps>["render"]>[0]
> = ({ id, field, name, value, onChange, readOnly }) => {
  const onImageSelected = useCallback(
    (image: { url: string }) => {
      onChange({ url: image.url });
    },
    [onChange],
  );

  return (
    <label className="block max-h-96 overflow-y-auto">
      <div className="flex items-center">
        <ImageIcon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      <OrganizationImages
        id={id}
        allowUpload
        readonly={readOnly}
        onImageSelected={onImageSelected}
      />
      <div className="flex w-full items-center space-x-1 space-y-1.5">
        <Input
          type="url"
          className="mt-2"
          value={value?.url ?? ""}
          placeholder="Direkt URL gir"
          onChange={(e) => {
            onChange(e.target.value ? { ...value, url: e.target.value } : null);
          }}
          readOnly={readOnly}
        />
        <Button
          type="button"
          variant="outline"
          size="icon"
          disabled={readOnly || !value?.url}
          onClick={(e) => {
            onChange(null as any);
          }}
        >
          <TrashIcon className="size-5 rounded-md" />
        </Button>
      </div>
    </label>
  );
};

export const ImageCustomField = {
  type: "custom",
  render: (props) => <FieldRenderer {...props} />,
} satisfies CustomField<ImageCustomFieldProps>;
