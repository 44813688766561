"use client";

import { FeaturesSectionProps } from "@/website-editor/blocks/features-section/index";
import { cn, hsv2hex } from "@/lib/utils";
import {
  mutedTextColorVariants,
  textColorVariants,
} from "@/website-editor/custom-fields/theme";
import { Icon } from "@/website-editor/custom-fields/icon";
import { ButtonsComponent } from "@/website-editor/blocks/button";
import Image from "next/image";
import { motion } from "framer-motion";

const fadeInAnimationVariants = {
  fromLeft: {
    hidden: { opacity: 0, x: -100 },
    "fade-in-to-center": (index: number = 0) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1 * index,
      },
    }),
  },
  fromRight: {
    hidden: { opacity: 0, x: 100 },
    "fade-in-to-center": (index: number = 0) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1 * index,
      },
    }),
  },
};

const FeaturesSection: React.FC<
  Pick<
    FeaturesSectionProps,
    | "title"
    | "slogan"
    | "description"
    | "customPrimaryColor"
    | "theme"
    | "features"
    | "image"
    | "imagePosition"
    | "imageOverflowType"
    | "imageSticky"
    | "buttons"
    | "revealAnimation"
  > & {
    dropzone: React.ReactNode;
  }
> = ({
  image,
  imageOverflowType,
  imagePosition,
  imageSticky,
  title,
  slogan,
  description,
  features,
  theme,
  buttons,
  customPrimaryColor,
  dropzone,
  revealAnimation,
}) => {
  return (
    <div
      className={cn(
        "mx-auto grid place-items-start max-w-2xl grid-cols-1 gap-x-8 gap-y-16 @2xl:gap-y-20 @5xl:mx-0 @5xl:max-w-none",
        {
          "@5xl:grid-cols-2": Boolean(image),
        },
      )}
    >
      <motion.div
        className={cn("@5xl:pt-4", {
          "@5xl:ml-auto @5xl:pl-4": Boolean(image) && imagePosition === "left",
          "@5xl:pr-8": Boolean(image) && imagePosition === "right",
        })}
        variants={
          !image || imagePosition === "right"
            ? fadeInAnimationVariants.fromLeft
            : fadeInAnimationVariants.fromRight
        }
        initial={revealAnimation === "none" ? undefined : "hidden"}
        whileInView={revealAnimation === "none" ? undefined : revealAnimation}
        viewport={{ once: true }}
      >
        <div className={cn(image ? "@5xl:max-w-lg" : "text-center")}>
          <h2
            className={cn(
              "whitespace-pre-line break-words text-base font-semibold leading-7 text-primary/80",
            )}
            style={
              customPrimaryColor[0]
                ? {
                    color: hsv2hex(
                      customPrimaryColor[0].hue,
                      customPrimaryColor[0].saturation,
                      customPrimaryColor[0].value,
                    ),
                  }
                : undefined
            }
          >
            {slogan}
          </h2>
          <p
            className={cn(
              "mt-2 text-3xl font-bold tracking-tight @2xl:text-4xl whitespace-pre-line break-words",
              textColorVariants({ theme }),
            )}
          >
            {title}
          </p>
          <div
            className={cn(
              "mt-6 text-lg leading-8 whitespace-pre-line break-words",
              mutedTextColorVariants({ theme }),
            )}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <dl
            className={cn(
              "mt-10 max-w-xl text-base leading-7 @5xl:max-w-none text-start",
              image
                ? "space-y-8"
                : "grid grid-cols-1 @5xl:grid-cols-3 gap-x-8 gap-y-16",
              mutedTextColorVariants({ theme }),
            )}
          >
            {features.map((feature, idx) => (
              <motion.div
                key={idx}
                className="relative pl-9"
                variants={
                  !image || imagePosition === "right"
                    ? fadeInAnimationVariants.fromLeft
                    : fadeInAnimationVariants.fromRight
                }
                initial={revealAnimation === "none" ? undefined : "hidden"}
                whileInView={
                  revealAnimation === "none" ? undefined : revealAnimation
                }
                viewport={{ once: true }}
                custom={idx + 1}
              >
                <dt
                  className={cn(
                    "inline font-semibold",
                    textColorVariants({ theme }),
                  )}
                >
                  <Icon
                    icon={feature.icon}
                    className="absolute left-1 top-1 size-5 whitespace-pre-line break-words text-primary"
                    style={
                      customPrimaryColor[0]
                        ? {
                            color: hsv2hex(
                              customPrimaryColor[0].hue,
                              customPrimaryColor[0].saturation,
                              customPrimaryColor[0].value,
                            ),
                          }
                        : undefined
                    }
                  />
                  {feature.title}
                </dt>{" "}
                <dd
                  className={
                    (cn({ inline: Boolean(image) }),
                    "break-words whitespace-pre-line")
                  }
                  dangerouslySetInnerHTML={{
                    __html: feature.description,
                  }}
                />
                <ButtonsComponent
                  className="mt-4 justify-start"
                  buttons={feature.buttons}
                  on={theme}
                />
              </motion.div>
            ))}
          </dl>
          <motion.div
            className="relative pl-9"
            variants={
              !image || imagePosition === "right"
                ? fadeInAnimationVariants.fromLeft
                : fadeInAnimationVariants.fromRight
            }
            initial={revealAnimation === "none" ? undefined : "hidden"}
            whileInView={
              revealAnimation === "none" ? undefined : revealAnimation
            }
            viewport={{ once: true }}
            custom={features.length + 2}
          >
            {dropzone}
            <ButtonsComponent className="mt-10" buttons={buttons} on={theme} />
          </motion.div>
        </div>
      </motion.div>
      {image && (
        <motion.div
          className={cn({
            "flex items-start justify-end @5xl:order-first":
              imagePosition === "left",
            "sticky top-4": imageSticky,
          })}
          variants={
            imagePosition === "right"
              ? fadeInAnimationVariants.fromRight
              : fadeInAnimationVariants.fromLeft
          }
          initial={revealAnimation === "none" ? undefined : "hidden"}
          whileInView={revealAnimation === "none" ? undefined : revealAnimation}
          viewport={{ once: true }}
        >
          <Image
            src={image.url}
            alt={image.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!image.url.includes(".pi.education/")}
            className={cn("rounded-xl shadow-xl ring-1", {
              "w-full @2xl:w-[48rem] @2xl:min-w-[48rem] max-w-none @5xl:w-[57rem] @5xl:min-w-[57rem]":
                imageOverflowType === "overflow",
              "@3xl:-ml-4 @5xl:-ml-0 @5xl:order-first":
                imagePosition === "left",
              "ring-white/10": theme === "dark",
              "ring-gray-400/10": theme === "light",
            })}
          />
        </motion.div>
      )}
    </div>
  );
};

export default FeaturesSection;
