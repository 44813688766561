/**
 * @generated SignedSource<<6a4d02618cd353f535aa06d32fdb9d2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type coachingSessionsWebsiteEditorQuery$variables = {
  organizationSlug: string;
};
export type coachingSessionsWebsiteEditorQuery$data = {
  readonly organization: {
    readonly coachingSessions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string;
        };
      }>;
    };
    readonly id: string;
  } | null | undefined;
};
export type coachingSessionsWebsiteEditorQuery = {
  response: coachingSessionsWebsiteEditorQuery$data;
  variables: coachingSessionsWebsiteEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "organizationSlug"
      }
    ],
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          }
        ],
        "concreteType": "CoachingSessionConnection",
        "kind": "LinkedField",
        "name": "coachingSessions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingSessionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingSession",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "coachingSessions(first:100)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "coachingSessionsWebsiteEditorQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "coachingSessionsWebsiteEditorQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "69b6a47906a7a8bdfd2f1c9361bb2216",
    "id": null,
    "metadata": {},
    "name": "coachingSessionsWebsiteEditorQuery",
    "operationKind": "query",
    "text": "query coachingSessionsWebsiteEditorQuery(\n  $organizationSlug: String!\n) {\n  organization(slug: $organizationSlug) {\n    id\n    coachingSessions(first: 100) {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "068e9e67ae22dfc9ab19596e71b50b4c";

export default node;
