import { graphql } from "react-relay";

export const coachingSessionsWebsiteEditorGraphqlQuery = graphql`
  query coachingSessionsWebsiteEditorQuery($organizationSlug: String!) {
    organization(slug: $organizationSlug) {
      id
      coachingSessions(first: 100) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;
