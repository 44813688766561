"use client";

import { CalendarIcon } from "@radix-ui/react-icons";
import { I18nProvider } from "react-aria";

import React, { useRef, useState } from "react";
import {
  DateValue,
  useButton,
  useDatePicker,
  useInteractOutside,
} from "react-aria";
import { DatePickerStateOptions, useDatePickerState } from "react-stately";
import { useForwardedRef } from "@/lib/hooks/useForwardedRef";
import { cn } from "@/lib/utils";
import { Button } from "../button";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Calendar } from "./calendar";
import { DateField } from "./date-field";
import { TimeField } from "./time-field";
import {
  CalendarDateTime,
  parseAbsoluteToLocal,
  parseDateTime,
  toCalendarDateTime,
} from "@internationalized/date";

function safeParseDate(value: string): CalendarDateTime {
  let result: CalendarDateTime | undefined;

  try {
    result = toCalendarDateTime(parseAbsoluteToLocal(value));
  } catch (e) {
    // ignore
  }

  return result ?? parseDateTime(value);
}

interface DateTimePickerProps extends DatePickerStateOptions<DateValue> {
  className?: string;
}

const DateTimePicker = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<
    Omit<DateTimePickerProps, "value"> & {
      value: string | DateTimePickerProps["value"];
    }
  >
>((props, forwardedRef) => {
  const ref = useForwardedRef(forwardedRef);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);

  const safeProps =
    typeof props.value === "string"
      ? {
          ...props,
          value: safeParseDate(props.value),
        }
      : (props as DateTimePickerProps);

  const state = useDatePickerState(safeProps);
  const {
    groupProps,
    fieldProps,
    buttonProps: _buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(safeProps, state, ref);
  const { buttonProps } = useButton(_buttonProps, buttonRef);
  useInteractOutside({
    ref: contentRef,
    onInteractOutside: () => {
      setOpen(false);
    },
  });

  return (
    <I18nProvider locale="tr-TR">
      <div
        {...groupProps}
        ref={ref}
        className={cn(
          groupProps.className,
          "flex items-center rounded-md ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
          props.className,
        )}
      >
        <DateField {...fieldProps} />
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              {...buttonProps}
              variant="outline"
              className="rounded-l-none"
              size="icon"
              disabled={props.isDisabled}
              onClick={() => setOpen(true)}
            >
              <CalendarIcon className="size-5" />
            </Button>
          </PopoverTrigger>
          <PopoverContent ref={contentRef} className="w-full">
            <div {...dialogProps} className="space-y-3">
              <Calendar {...calendarProps} />
              {!!state.hasTime && (
                <TimeField
                  label={props.label}
                  granularity={
                    props.granularity === "day" ? undefined : props.granularity
                  }
                  hourCycle={props.hourCycle}
                  value={state.timeValue}
                  onChange={state.setTimeValue}
                />
              )}
              {props.children}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </I18nProvider>
  );
});

DateTimePicker.displayName = "DateTimePicker";

export { DateTimePicker };
