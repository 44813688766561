/**
 * @generated SignedSource<<35f0259556467d641183a05f39dba59b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteWebPageComponentTemplateInput = {
  webPageComponentTemplateId: string;
};
export type DeleteWebPageComponentTemplateDialogMutation$variables = {
  input: DeleteWebPageComponentTemplateInput;
};
export type DeleteWebPageComponentTemplateDialogMutation$data = {
  readonly deleteWebPageComponentTemplate: {
    readonly deletedWebPageComponentTemplateId: string | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type DeleteWebPageComponentTemplateDialogMutation = {
  response: DeleteWebPageComponentTemplateDialogMutation$data;
  variables: DeleteWebPageComponentTemplateDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteWebPageComponentTemplatePayload",
    "kind": "LinkedField",
    "name": "deleteWebPageComponentTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedWebPageComponentTemplateId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "userErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteWebPageComponentTemplateDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteWebPageComponentTemplateDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3abfbfb43adf7dc0c67ab48bc7710064",
    "id": null,
    "metadata": {},
    "name": "DeleteWebPageComponentTemplateDialogMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteWebPageComponentTemplateDialogMutation(\n  $input: DeleteWebPageComponentTemplateInput!\n) {\n  deleteWebPageComponentTemplate(input: $input) {\n    deletedWebPageComponentTemplateId\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "36524e7aec3ec016673e2178fb9723ad";

export default node;
