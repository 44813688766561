"use client";

import { Environment, fetchQuery } from "react-relay";
import { coachingSessionsWebsiteEditorQuery } from "@generated/coachingSessionsWebsiteEditorQuery.graphql";
import { coachingSessionsWebsiteEditorGraphqlQuery } from "@/website-editor/custom-fields/coachingSessionsWebsiteEditorQuery";

export async function coachingSessionFetchList({
  query,
  environment,
  organizationSlug,
}: {
  query: string;
  environment: Environment;
  organizationSlug: string;
}) {
  const response = await fetchQuery<coachingSessionsWebsiteEditorQuery>(
    environment,
    coachingSessionsWebsiteEditorGraphqlQuery,
    {
      organizationSlug,
    },
  ).toPromise();

  return (
    response?.organization?.coachingSessions.edges.map((edge) => ({
      id: edge.node.id,
      title: edge.node.title,
    })) ?? []
  );
}
