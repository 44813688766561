import { ComponentConfig } from "@/website-editor/core";
import {
  mutedTextColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { cn } from "@/lib/utils";
import Image from "next/image";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  JustifyContent,
  JustifyContentField,
  justifyContentVariants,
} from "@/website-editor/custom-fields/justifyContent";
import {
  AlignItems,
  AlignItemsField,
  alignItemsVariants,
} from "@/website-editor/custom-fields/alignItems";

export type GridSectionProps = {
  sectionId?: string;
  theme: Theme;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  numberOfColumns: 1 | 2 | 3 | 4 | 5 | 6;
  maxWidth: "7xl" | "none";
  justifyContent: JustifyContent;
  alignItems: AlignItems;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const GridSection = {
  displayName: "Izgara",
  tooltip:
    "Bu bloğu kullanarak içerikleri ızgara şeklinde sıralayabilirsiniz ve arkaplan resmi ekleyebilirsiniz.",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    numberOfColumns: {
      type: "radio",
      label: "Sütun Sayısı",
      options: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
      ],
    },
    justifyContent: JustifyContentField,
    alignItems: AlignItemsField,
    maxWidth: {
      type: "radio",
      label: "Maksimum Genişliği Sınırla",
      options: [
        {
          value: "7xl",
          label: "Evet",
        },
        {
          value: "none",
          label: "Hayır",
        },
      ],
    },
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    backgroundImage: null,
    numberOfColumns: 4,
    justifyContent: "center",
    alignItems: "start",
    maxWidth: "7xl",
    paddingTop: "lg",
    paddingBottom: "lg",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    numberOfColumns,
    justifyContent,
    alignItems,
    maxWidth,
    theme,
    backgroundImage,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn("relative isolate bg-background", {
          "overflow-clip": Boolean(backgroundImage),
          dark: theme === "dark",
        })}
      >
        {backgroundImage && (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
              mutedTextColorVariants({ theme }),
            )}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        )}
        <div
          className={cn(
            "relative",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            paddingVariants({ side: "left", size: marginLeft }),
            paddingVariants({ side: "right", size: marginRight }),
            {
              "max-w-7xl mx-auto": maxWidth !== "none",
            },
          )}
        >
          {renderDropZone({
            zone: "content",
            className: cn(
              "grid gap-6",
              justifyContentVariants({ justifyContent }),
              alignItemsVariants({ alignItems }),
              {
                "sm:grid-cols-2": numberOfColumns == 2,
                "sm:grid-cols-3": numberOfColumns == 3,
                "sm:grid-cols-4": numberOfColumns == 4,
                "sm:grid-cols-5": numberOfColumns == 5,
                "sm:grid-cols-6": numberOfColumns == 6,
              },
            ),
          })}
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<GridSectionProps>;
