const ResolveDataComponentHelper: React.FC<
  React.PropsWithChildren<{
    data: { id?: unknown; __preloadedQuery?: any } | null | undefined;
    Empty?: React.ReactNode;
    Loading?: React.ReactNode;
  }>
> = ({ data, Empty, Loading, children }) => {
  if (!data || !data.id) {
    return Empty || null;
  }

  if (!data.__preloadedQuery) {
    return Loading || null;
  }

  return children;
};

export default ResolveDataComponentHelper;
