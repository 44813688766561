import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import GoogleLogin from "@/website-editor/blocks/login/GoogleLogin";
import RedirectAfterLogin from "@/website-editor/blocks/login/RedirectAfterLogin";
import EmailOtpLogin from "@/website-editor/blocks/login/EmailOtpLogin";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type LoginProps = {
  sectionId?: string;
  theme: Theme;
  title: string;
  description: string;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
  googleLogin: boolean;
  emailOtpLogin: boolean;
  redirectAfterLogin?: string;
};

export const Login = {
  displayName: "Giriş Yap",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "textarea",
      label: "Açıklama",
    },
    theme: ThemeField,
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    redirectAfterLogin: {
      type: "text",
      label: "Giriş Yapıldıktan Sonra Yönlendirilecek Sayfa",
    },
    googleLogin: {
      label: "Google ile giriş yap",
      type: "radio",
      options: [
        {
          value: true,
          label: "Evet",
        },
        {
          value: false,
          label: "Hayır",
        },
      ],
    },
    emailOtpLogin: {
      label: "E-posta ile giriş yap",
      type: "radio",
      options: [
        {
          value: true,
          label: "Evet",
        },
        {
          value: false,
          label: "Hayır",
        },
      ],
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    title: "Giriş Yap",
    description: "Giriş yapmak için lütfen giriş bilgilerinizi giriniz.",
    redirectAfterLogin: "/",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingTop: "xl",
    paddingBottom: "xl",
    googleLogin: true,
    emailOtpLogin: true,
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    theme,
    title,
    description,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    redirectAfterLogin,
    googleLogin,
    emailOtpLogin,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "flex grow flex-col justify-center bg-background",
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div className="relative mx-auto flex w-full flex-col justify-center space-y-6 sm:max-w-md">
          <div className="relative flex flex-col items-center space-y-2 text-center">
            {renderDropZone({
              zone: "login-section-above-title",
              style: {
                background: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            })}
            <h1
              className={cn(
                "text-2xl font-semibold tracking-tight whitespace-pre-line",
                textColorVariants({ theme }),
              )}
            >
              {title}
            </h1>
            <p
              className={cn(
                "text-sm whitespace-pre-line",
                mutedTextColorVariants({ theme }),
              )}
            >
              {description}
            </p>
          </div>
          <RedirectAfterLogin redirectAfterLogin={redirectAfterLogin} />
          {googleLogin && <GoogleLogin theme={theme} />}
          {emailOtpLogin && <EmailOtpLogin theme={theme} />}
          {renderDropZone({
            zone: "login-section-footer",
            style: {
              background: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
          })}
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<LoginProps>;
