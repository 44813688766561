"use client";

import { useRouter, useSearchParams } from "next/navigation";
import { useOrganization } from "@/student/context/OrganizationContext";
import { useEffect } from "react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CheckIcon, InfoCircledIcon } from "@radix-ui/react-icons";

const RedirectAfterLogin: React.FC<{
  redirectAfterLogin?: string;
}> = ({ redirectAfterLogin }) => {
  const organization = useOrganization();

  const router = useRouter();
  const searchParams = useSearchParams();

  const nextSearchParam = searchParams?.get("next") ?? null;

  const isLoggedIn = Boolean(organization?.viewerStudent);

  useEffect(() => {
    if (isLoggedIn) {
      router.push((nextSearchParam || redirectAfterLogin) ?? "/");
    }
  }, [isLoggedIn, nextSearchParam, redirectAfterLogin, router]);

  if (nextSearchParam) {
    return (
      <Alert variant="info">
        <InfoCircledIcon className="size-4" />
        <AlertTitle>Bu sayfaya erişmek için giriş yapmalısın</AlertTitle>
        <AlertDescription>
          Erişmek istediğiniz sayfaya giriş yaptıktan sonra
          yönlendirileceksiniz.
        </AlertDescription>
      </Alert>
    );
  }

  if (isLoggedIn) {
    return (
      <Alert variant="success">
        <CheckIcon className="size-4" />
        <AlertTitle>Giriş yapıldı</AlertTitle>
        <AlertDescription>
          Başarıyla giriş yaptınız. Yönlendiriliyorsunuz...
        </AlertDescription>
      </Alert>
    );
  }

  return null;
};

export default RedirectAfterLogin;
