"use client";

import { CustomField } from "@/website-editor/core";
import { ColorWheelIcon } from "@radix-ui/react-icons";
import { Input } from "@/components/ui/input";
import React from "react";

export type Color = any;

const FieldRenderer: React.FC<Parameters<CustomField<Color>["render"]>[0]> = ({
  id,
  field,
  name,
  value,
  onChange,
  readOnly,
}) => {
  return (
    <div>
      <div className="flex items-center">
        <ColorWheelIcon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      <Input
        placeholder="HEX Kodu"
        type="color"
        value={value ?? ""}
        readOnly={readOnly}
        onChange={(event) => {
          onChange(event.target.value as Color);
        }}
      />
    </div>
  );
};

export const ColorCustomField = {
  type: "custom",
  render: (props) => <FieldRenderer {...props} />,
} satisfies CustomField<Color>;
