import React from "react";

const LoadingScreen = () => {
  return (
    <div className="flex h-dvh flex-col items-center justify-center">
      <div className="mb-4 size-16 animate-spin rounded-full border-8 border-solid border-gray-300"></div>
      <p className="text-gray-600">Loading...</p>
    </div>
  );
};

export default LoadingScreen;
