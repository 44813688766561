import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { cn, hsv2hex } from "@/lib/utils";
import Image from "next/image";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  ButtonProps,
  ButtonsComponent,
  ButtonsArrayField,
} from "@/website-editor/blocks/button";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import BackgroundGradient from "@/components/background-gradient";
import dynamic from "next/dynamic";
import { Highlight } from "@/components/ui/highlight";
import { ColorCustomField } from "@/website-editor/custom-fields/color";
const VideoPlayer = dynamic(() => import("@/components/video-player"), {});

export type HeroProps = {
  image: {
    url: string;
    alt?: string;
  } | null;
  imagePosition: "left" | "right";
  imageOverflowType: "overflow" | "contain";
  videoUrl: string;
  sectionId?: string;
  theme: Theme;
  background: "none" | "tiles" | "glowing-gradient" | "tiles+gradient";
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  harmony: HarmonyValue;
  customColorIsGradient: boolean;
  buttons: Array<ButtonProps>;
  title: string;
  titleColor?: HarmonyValue;
  titleHighlightAnimation?: {
    type: "underline" | "background" | "none";
    highlightText?: string;
    startColor: string;
    endColor: string;
  };
  description: string;
  descriptionColor: HarmonyValue;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Hero = {
  displayName: "Hero",
  tooltip:
    "Hero web sayfasının üst kısmında genellikle büyük bir görsel veya video içeren belirgin bir bölümdür. Kullanıcının sayfada gördüğü ilk bölüm olması nedeniyle çok önemlidir.",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    titleColor: {
      ...HarmonyCustomField,
      label: "Başlık Özel Renk",
    },
    titleHighlightAnimation: {
      type: "object",
      label: "Başlık Vurgu Animasyonu",
      objectFields: {
        type: {
          type: "radio",
          label: "Vurgu Türü",
          options: [
            {
              value: "none",
              label: "Yok",
            },
            {
              value: "background",
              label: "Satırı Çiz",
            },
            {
              value: "underline",
              label: "Altını Çiz",
            },
          ],
        },
        highlightText: {
          type: "textarea",
          label: "Vurgulanacak Metin (Hepsi için boş bırakabilirsin)",
        },
        startColor: {
          ...ColorCustomField,
          label: "Vurgu Başlangıç Rengi",
        },
        endColor: {
          ...ColorCustomField,
          label: "Vurgu Bitiş Rengi",
        },
      },
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    descriptionColor: {
      ...HarmonyCustomField,
      label: "Açıklama Özel Renk",
    },
    buttons: ButtonsArrayField,
    image: {
      ...ImageCustomField,
      label: "Hero Image",
    },
    imagePosition: {
      type: "radio",
      label: "Resim Pozisyonu",
      options: [
        {
          value: "left",
          label: "Sol",
        },
        {
          value: "right",
          label: "Sağ",
        },
      ],
    },
    imageOverflowType: {
      type: "radio",
      label: "Resim Tarzı",
      options: [
        {
          value: "overflow",
          label: "Ekrandan Taşan",
        },
        {
          value: "contain",
          label: "Sığdırılmış",
        },
      ],
    },
    videoUrl: {
      type: "text",
      label: "Video URL",
    },
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    customColorIsGradient: {
      type: "radio",
      label: "Arkaplan Rengi Tarzı",
      options: [
        {
          value: true,
          label: "Gradient",
        },
        {
          value: false,
          label: "Solid",
        },
      ],
    },
    theme: ThemeField,
    background: {
      type: "select",
      label: "Arkaplan",
      options: [
        {
          value: "none",
          label: "Yok",
        },
        {
          value: "tiles",
          label: "Desenli",
        },
        {
          value: "glowing-gradient",
          label: "Parlayan",
        },
        {
          value: "tiles+gradient",
          label: "Desenli ve Parlayan",
        },
      ],
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      fields: [
        "sectionId",
        "title",
        "titleColor",
        "titleHighlightAnimation",
        "description",
        "descriptionColor",
        "buttons",
        "theme",
      ],
      defaultExpanded: true,
    },
    media: {
      title: "Medya",
      defaultExpanded: true,
      fields: ["image", "videoUrl", "imagePosition", "imageOverflowType"],
    },
    background: {
      title: "Arkaplan",
      defaultExpanded: false,
      fields: [
        "backgroundImage",
        "harmony",
        "customColorIsGradient",
        "background",
      ],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: ["paddingTop", "paddingBottom", "marginLeft", "marginRight"],
    },
  },
  defaultProps: {
    image: {
      url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/q7f67p5o04ijv8ye1umzp00j/deniz.jpg",
    },
    videoUrl: "",
    theme: "light",
    title: "İşe Yarar Finansal Okuryazarlık: Paranın Dilini Çöz",
    titleColor: [],
    titleHighlightAnimation: {
      type: "none",
      highlightText: "",
      startColor: "#ff80b5",
      endColor: "#9089fc",
    },
    buttons: [
      {
        url: "/iletisim",
        size: "lg",
        label: "Bilgi Al",
        variant: "outline",
      },
      {
        url: "#Kurslar",
        size: "lg",
        label: "Kurslara Göz At",
        variant: "default",
        startIcon: "LayersIcon",
      },
    ],
    harmony: [],
    customColorIsGradient: true,
    sectionId: "",
    background: "tiles",
    marginLeft: "none",
    paddingTop: "2xl",
    description:
      "Ekonomik gelişmeleri anla, verileri takip etmeyi öğren. Kendi finansal tercihlerini yap, bağımsızlığını kazan. Herkes için ekonomi.",
    descriptionColor: [],
    marginRight: "none",
    imagePosition: "right",
    paddingBottom: "2xl",
    backgroundImage: null,
    imageOverflowType: "contain",
  },
  render: ({
    puck: { renderDropZone },
    id,
    image,
    videoUrl = "",
    imagePosition = "right",
    imageOverflowType = "contain",
    sectionId,
    title,
    titleColor = [],
    titleHighlightAnimation = {
      type: "none",
      highlightText: "",
      startColor: "#ff80b5",
      endColor: "#9089fc",
    },
    description,
    descriptionColor = [],
    buttons,
    background,
    backgroundImage,
    theme,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    harmony = [],
    customColorIsGradient = true,
  }) => {
    const highlightStartIndex = titleHighlightAnimation.highlightText
      ? title.indexOf(titleHighlightAnimation.highlightText)
      : -1;

    const highlightEndIndex =
      highlightStartIndex !== -1
        ? highlightStartIndex +
          (titleHighlightAnimation.highlightText?.length ?? 0)
        : -1;

    return (
      <div
        className={cn(
          "relative isolate",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: marginLeft }),
          paddingVariants({ side: "right", size: marginRight }),
          {
            dark: theme === "dark",
            "bg-background": customColorIsGradient || harmony.length === 0,
          },
        )}
        style={
          !customColorIsGradient && harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
        id={sectionId || undefined}
      >
        {backgroundImage ? (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
              mutedTextColorVariants({ theme }),
            )}
          />
        ) : customColorIsGradient && harmony.length > 0 ? (
          <BackgroundGradient id={id} harmony={harmony} />
        ) : (
          <>
            {theme === "light" && background === "glowing-gradient" && (
              <>
                <div
                  className="absolute inset-x-0 -top-40 -z-10 transform-gpu text-clip blur-3xl sm:-top-80"
                  aria-hidden="true"
                >
                  <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                  />
                </div>
                <div
                  className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu text-clip blur-3xl sm:top-[calc(100%-30rem)]"
                  aria-hidden="true"
                >
                  <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                  />
                </div>
              </>
            )}
            {theme === "light" &&
              (background === "tiles" || background === "tiles+gradient") && (
                <svg
                  className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                      width={200}
                      height={200}
                      x="50%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                  </defs>
                  <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                    <path
                      d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                      strokeWidth={0}
                    />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                  />
                </svg>
              )}
            {theme === "dark" &&
              (background === "tiles" || background === "tiles+gradient") && (
                <svg
                  className="absolute inset-0 -z-10 size-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                      width={200}
                      height={200}
                      x="50%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                  </defs>
                  <svg
                    x="50%"
                    y={-1}
                    className="overflow-visible fill-gray-800/20"
                  >
                    <path
                      d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                      strokeWidth={0}
                    />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                  />
                </svg>
              )}
            {theme === "light" && background === "tiles+gradient" && (
              <>
                <div
                  className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu text-clip blur-3xl lg:ml-24 xl:ml-48"
                  aria-hidden="true"
                >
                  <div
                    className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                      clipPath:
                        "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
                    }}
                  />
                </div>
              </>
            )}
            {theme === "dark" &&
              (background === "tiles+gradient" ||
                background === "glowing-gradient") && (
                <div
                  className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                  aria-hidden="true"
                >
                  <div
                    className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                    style={{
                      clipPath:
                        "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                    }}
                  />
                </div>
              )}
          </>
        )}

        <div className="mx-auto max-w-7xl justify-center px-6 lg:flex lg:px-8">
          <div
            className={cn(
              "mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 sm:gap-y-16 lg:mx-0 lg:max-w-none",
              {
                "lg:grid-cols-5": Boolean(image || videoUrl),
              },
            )}
          >
            <div
              className={cn(
                "mx-auto flex flex-col justify-center",
                image || videoUrl
                  ? "flex-shrink-0 lg:mx-0 lg:col-span-3"
                  : "text-center",
              )}
            >
              <h1
                className={cn(
                  "text-3xl font-bold tracking-tight sm:text-5xl sm:leading-tight whitespace-pre-line",
                  {
                    [textColorVariants({ theme })]: !titleColor[0],
                  },
                )}
                style={
                  titleColor[0]
                    ? {
                        color: hsv2hex(
                          titleColor[0].hue,
                          titleColor[0].saturation,
                          titleColor[0].value,
                        ),
                      }
                    : undefined
                }
              >
                {highlightStartIndex !== -1 ? (
                  <>
                    {title.slice(0, highlightStartIndex)}
                    <Highlight
                      highlightType={titleHighlightAnimation.type}
                      style={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        "--tw-gradient-from": `${titleHighlightAnimation.startColor} var(--tw-gradient-to-position)`,
                        "--tw-gradient-to": `${titleHighlightAnimation.endColor} var(--tw-gradient-to-position)`,
                        "--tw-gradient-stops":
                          "var(--tw-gradient-from), var(--tw-gradient-to)",
                      }}
                    >
                      {title.slice(highlightStartIndex, highlightEndIndex)}
                    </Highlight>
                    {title.slice(highlightEndIndex)}
                  </>
                ) : (
                  <Highlight
                    highlightType={titleHighlightAnimation.type}
                    style={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      "--tw-gradient-from": `${titleHighlightAnimation.startColor} var(--tw-gradient-to-position)`,
                      "--tw-gradient-to": `${titleHighlightAnimation.endColor} var(--tw-gradient-to-position)`,
                      "--tw-gradient-stops":
                        "var(--tw-gradient-from), var(--tw-gradient-to)",
                    }}
                  >
                    {title}
                  </Highlight>
                )}
              </h1>
              <div
                className={cn(
                  "mt-6 text-lg leading-8 break-words whitespace-pre-line",
                  {
                    [mutedTextColorVariants({ theme })]: !descriptionColor[0],
                  },
                )}
                style={
                  descriptionColor[0]
                    ? {
                        color: hsv2hex(
                          descriptionColor[0].hue,
                          descriptionColor[0].saturation,
                          descriptionColor[0].value,
                        ),
                      }
                    : undefined
                }
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              {renderDropZone({ zone: "content" })}
              <ButtonsComponent
                className={cn(
                  "mt-10",
                  image ? "justify-start" : "justify-center",
                )}
                buttons={buttons}
                on={theme}
              />
            </div>
            {image || videoUrl ? (
              <div
                className={cn(
                  "lg:col-span-2 relative flex flex-col justify-center",
                  {
                    "items-start lg:order-first lg:ml-0":
                      imagePosition === "left",
                    "lg:mr-0": imagePosition === "right",
                  },
                )}
              >
                {videoUrl ? (
                  <VideoPlayer
                    src={videoUrl}
                    mode="store"
                    className={cn(
                      "w-full rounded-md bg-white/5 shadow-2xl ring-1",
                      {
                        "ring-white/10": theme === "dark",
                        "ring-gray-400/10": theme === "light",
                      },
                    )}
                    muted
                  />
                ) : (
                  <Image
                    src={image!.url}
                    alt={image!.alt ?? ""}
                    width={1080}
                    height={1080}
                    unoptimized={!image!.url.includes(".pi.education/")}
                    className={cn("rounded-md bg-white/5 drop-shadow-2xl", {
                      "w-full sm:w-[48rem] sm:min-w-[48rem] max-w-none lg:w-[57rem] lg:min-w-[57rem]":
                        imageOverflowType === "overflow",
                      "lg:place-self-end":
                        imageOverflowType === "overflow" &&
                        imagePosition === "left",
                    })}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
} satisfies ComponentConfig<HeroProps>;
