import React from "react";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import {
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import { cn } from "@/lib/utils";
import { ComponentConfig } from "@/website-editor/core";
import { Icon, iconOptions } from "@/website-editor/custom-fields/icon";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type BulletPointsSectionProps = {
  sectionId?: string;
  steps: Array<{
    icon?: string | false;
    title: string;
    description: string;
  }>;
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const BulletPointsSection = {
  displayName: "Önemli Noktalar Bölümü",
  fields: {
    sectionId: SectionIdField,
    steps: {
      type: "array",
      label: "Adımlar",
      getItemSummary: (item, idx) => item.title || `Adım ${(idx ?? 0) + 1}`,
      arrayFields: {
        icon: {
          type: "select",
          label: "İkon",
          options: iconOptions,
        },
        title: {
          type: "richtextarea",
          label: "Başlık",
        },
        description: {
          type: "richtextarea",
          label: "Açıklama",
        },
      },
      defaultItemProps: {
        title: "Başlık",
        description: "Açıklama",
      },
    },
    theme: ThemeField,
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    steps: [
      {
        icon: "StarFilledIcon",
        title: "Paranı Yönet, Geleceğini Şekillendir",
        description:
          "Bütçeleme, yatırım yapma ve borç yönetimi gibi temel finansal konseptleri öğrenerek paranızı daha bilinçli bir şekilde yönetmeyi öğreneceksiniz.",
      },
      {
        icon: "TargetIcon",
        title: "Adım Adım Finansal Başarıya",
        description:
          "Sıfırdan ileri seviyeye, her adımda yanınızdayız. Gelirinizi artırmak, tasarruf etmek ve yatırım yapmak için stratejiler öğrenin.",
      },
      {
        icon: "Pencil1Icon",
        title: "Finansal Bilgini Test Et, Uygula, Başar",
        description:
          "Gerçek hayat senaryoları üzerinden pratik yaparak öğrenin. Bu kurs, finansal teorileri anlamanın ötesine geçip, uygulamalı bilgi sunar",
      },
    ],
    theme: "light",
    sectionId: "",
    paddingTop: "lg",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingBottom: "lg",
  },
  render: ({
    sectionId,
    steps,
    theme,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "flex flex-col md:flex-row overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div className="mx-auto flex w-full max-w-7xl flex-col items-center space-y-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="flex w-full flex-1 flex-col items-start md:flex-row md:items-start"
            >
              <div className="relative flex w-auto justify-start">
                <div className="z-10 flex justify-start p-2">
                  <Icon
                    icon={step.icon || null}
                    className="mr-2 size-3 sm:size-4"
                  />
                </div>
                <div className="flex flex-1 flex-col md:ml-4">
                  <div
                    className={cn(
                      "text-xl font-bold break-words whitespace-pre-line",
                      textColorVariants({ theme }),
                    )}
                    dangerouslySetInnerHTML={{ __html: step.title }}
                  />
                  <div
                    className={cn(
                      "text-md mt-1.5 break-words whitespace-pre-line",
                      textColorVariants({ theme }),
                    )}
                    dangerouslySetInnerHTML={{ __html: step.description }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<BulletPointsSectionProps>;
