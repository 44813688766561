export type Label = 1 | 2 | 3 | 4 | 5 | 6;

export const LabelField = {
  type: "select" as const,
  label: "Label",
  options: [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
  ],
};
