import { cva } from "class-variance-authority";

export type FontSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";

export const FontSizeField = {
  type: "select" as const,
  label: "Yazı Boyutu",
  options: [
    {
      value: "xs",
      label: "xs",
    },
    {
      value: "sm",
      label: "Küçük",
    },
    {
      value: "base",
      label: "Orta",
    },
    {
      value: "lg",
      label: "Büyük",
    },
    {
      value: "xl",
      label: "XL",
    },
    {
      value: "2xl",
      label: "XXL",
    },
    {
      value: "3xl",
      label: "3XL",
    },
    {
      value: "4xl",
      label: "4XL",
    },
    {
      value: "5xl",
      label: "5XL",
    },
    {
      value: "6xl",
      label: "6XL",
    },
    {
      value: "7xl",
      label: "7XL",
    },
    {
      value: "8xl",
      label: "8XL",
    },
    {
      value: "9xl",
      label: "9XL",
    },
  ],
};

export const fontSizeVariants = cva(undefined, {
  variants: {
    fontSize: {
      xs: "text-xs",
      sm: "text-sm",
      base: "text-sm sm:text-base",
      lg: "text-base sm:text-lg",
      xl: "text-base sm:text-lg md:text-xl",
      "2xl": "text-lg sm:text-xl md:text-2xl",
      "3xl": "text-xl sm:text-2xl md:text-3xl",
      "4xl": "text-xl sm:text-2xl md:text-3xl lg:text-4xl",
      "5xl": "text-2xl sm:text-3xl md:text-4xl lg:text-5xl",
      "6xl": "text-3xl md:text-4xl lg:text-5xl xl:text-6xl",
      "7xl": "text-4xl md:text-5xl lg:text-6xl xl:text-7xl",
      "8xl": "text-5xl md:text-6xl lg:text-7xl xl:text-8xl",
      "9xl": "text-6xl md:text-7xl lg:text-8xl xl:text-9xl",
    },
  },
  defaultVariants: {
    fontSize: "base",
  },
});
