import { ComponentData, Config, MappedItem } from "../types/Config";
import { getChanged } from "./get-changed";

export const resolveAllComponentData = async (
  content: MappedItem[],
  config: Config,
  onResolveStart?: (item: MappedItem) => void,
  onResolveEnd?: (item: MappedItem) => void,
) => {
  return await Promise.all(
    content.map(async (item) => {
      return await resolveComponentData(
        item,
        null,
        config,
        onResolveStart,
        onResolveEnd,
      );
    }),
  );
};

export const resolveComponentData = async (
  item: ComponentData,
  changed: Partial<Record<string | number | symbol, boolean>> | null,
  config: Config,
  onResolveStart?: (item: MappedItem) => void,
  onResolveEnd?: (item: MappedItem) => void,
) => {
  const configForItem = config.components[item.type];

  if (configForItem?.resolveData) {
    const oldItem = {} as ComponentData; // TODO: no access to oldItem here

    if (onResolveStart) {
      onResolveStart(item);
    }

    const { props: resolvedProps, readOnly = {} } =
      await configForItem.resolveData(item, {
        changed: changed ?? getChanged(item, oldItem),
        lastData: oldItem,
      });

    const { readOnly: existingReadOnly = {} } = item || {};

    const newReadOnly = { ...existingReadOnly, ...readOnly };

    const resolvedItem = {
      ...item,
      props: {
        ...item.props,
        ...resolvedProps,
      },
    };

    if (Object.keys(newReadOnly).length) {
      resolvedItem.readOnly = newReadOnly;
    }

    if (onResolveEnd) {
      onResolveEnd(resolvedItem);
    }

    return resolvedItem;
  }

  return item;
};
