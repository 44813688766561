/**
 * @generated SignedSource<<5543f5122d90f3bf1b022796a34ecb6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Currency = "AED" | "EUR" | "GBP" | "KWD" | "QAR" | "SAR" | "TRY" | "USD" | "%future added value";
export type CreateOrderInput = {
  couponCodes?: ReadonlyArray<string> | null | undefined;
  currency?: Currency | null | undefined;
  orderableId?: string | null | undefined;
  organizationId?: string | null | undefined;
};
export type PurchasePriceAndActionsCreateOrderMutation$variables = {
  input: CreateOrderInput;
};
export type PurchasePriceAndActionsCreateOrderMutation$data = {
  readonly createOrder: {
    readonly order: {
      readonly id: string;
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"OrderPage_order">;
    } | null | undefined;
    readonly userErrors: ReadonlyArray<{
      readonly code: string;
      readonly field: ReadonlyArray<string>;
      readonly message: string;
    }>;
  };
};
export type PurchasePriceAndActionsCreateOrderMutation = {
  response: PurchasePriceAndActionsCreateOrderMutation$data;
  variables: PurchasePriceAndActionsCreateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "userErrors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Money",
    "kind": "LinkedField",
    "name": "presentmentMoney",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "MoneyBag",
  "kind": "LinkedField",
  "name": "discountedPrice",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchasePriceAndActionsCreateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrderPayload",
        "kind": "LinkedField",
        "name": "createOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrderPage_order"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchasePriceAndActionsCreateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrderPayload",
        "kind": "LinkedField",
        "name": "createOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationStudent",
                "kind": "LinkedField",
                "name": "student",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderLineItem",
                "kind": "LinkedField",
                "name": "lineItems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MoneyBag",
                    "kind": "LinkedField",
                    "name": "originalPrice",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "orderable",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Course",
                            "kind": "LinkedField",
                            "name": "course",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "type": "CourseAcademicTerm",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sessionCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoachingSession",
                            "kind": "LinkedField",
                            "name": "coachingSession",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "type": "CoachingSessionPlan",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderTransaction",
                "kind": "LinkedField",
                "name": "activeTransaction",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "embedUrl",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Money",
                    "kind": "LinkedField",
                    "name": "price",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderBillingAddress",
                "kind": "LinkedField",
                "name": "billingAddress",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "countryCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "province",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "identityNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxOffice",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d0544dc87ac07d20a3ae6ac3a98b602",
    "id": null,
    "metadata": {},
    "name": "PurchasePriceAndActionsCreateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation PurchasePriceAndActionsCreateOrderMutation(\n  $input: CreateOrderInput!\n) {\n  createOrder(input: $input) {\n    order {\n      id\n      slug\n      ...OrderPage_order\n    }\n    userErrors {\n      code\n      field\n      message\n    }\n  }\n}\n\nfragment BillingInformationStep_order on Order {\n  id\n  billingAddress {\n    id\n    firstName\n    lastName\n    addressLine1\n    addressLine2\n    countryCode\n    zipCode\n    province\n    city\n    identityNumber\n    phone\n    companyName\n    taxNumber\n    taxOffice\n  }\n}\n\nfragment OrderPage_lineItem on OrderLineItem {\n  id\n  originalPrice {\n    presentmentMoney {\n      amount\n      currency\n    }\n  }\n  discountedPrice {\n    presentmentMoney {\n      amount\n      currency\n    }\n  }\n  orderable {\n    id\n    __typename\n    ... on CourseAcademicTerm {\n      slug\n      course {\n        id\n        slug\n        image\n        title\n      }\n    }\n    ... on CoachingSessionPlan {\n      sessionCount\n      coachingSession {\n        id\n        slug\n        image\n        title\n      }\n    }\n  }\n}\n\nfragment OrderPage_order on Order {\n  id\n  slug\n  status\n  email\n  student {\n    id\n    email\n  }\n  discountedPrice {\n    presentmentMoney {\n      amount\n      currency\n    }\n  }\n  lineItems {\n    id\n    ...OrderPage_lineItem\n  }\n  activeTransaction {\n    id\n    createdAt\n    embedUrl\n    status\n    price {\n      amount\n      currency\n    }\n  }\n  ...BillingInformationStep_order\n}\n"
  }
};
})();

(node as any).hash = "177c290cbd7c1d1a4d531da965f4b575";

export default node;
