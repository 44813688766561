import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import Image from "next/image";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { cn, hsv2hex } from "@/lib/utils";
import {
  mutedTextColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import PiLink from "@/components/PiLink";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import BackgroundGradient from "@/components/background-gradient";
import React from "react";

export type FooterSectionProps = {
  sectionId?: string;
  mission: string;
  youtubeLink: string | null;
  instagramLink: string | null;
  linkedinLink: string | null;
  footerElements: Array<{
    elementTitle: string;
    footerElement: Array<{ title: string; link: string }> | null;
  }>;
  logo: {
    url: string;
    alt?: string;
  } | null;
  logoRoundness: Rounded;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  theme: Theme;
  harmony: HarmonyValue;
  customColorIsGradient: boolean;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const FooterSection = {
  displayName: "Footer",
  fields: {
    sectionId: SectionIdField,
    mission: {
      type: "richtextarea",
      label: "Misyon",
    },
    youtubeLink: {
      type: "text",
      label: "Youtube Link",
    },
    instagramLink: {
      type: "text",
      label: "Instagram Link",
    },
    linkedinLink: {
      type: "text",
      label: "Linkedin Link",
    },
    logo: {
      ...ImageCustomField,
      label: "Şirket Logosu",
    },
    logoRoundness: {
      ...RoundedField,
      label: "Logo Yuvarlaklığı",
    },
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    theme: ThemeField,
    footerElements: {
      type: "array",
      label: "Footer Elementleri",
      getItemSummary: (item, idx) =>
        item.elementTitle || `Element ${(idx ?? 0) + 1}`,
      arrayFields: {
        elementTitle: {
          type: "richtextarea",
          label: "Element Başlığı",
        },
        footerElement: {
          type: "array",
          label: "Elementler",
          getItemSummary: (item, idx) =>
            (item as { title: string }).title || `Element ${(idx ?? 0) + 1}`,
          arrayFields: {
            title: {
              type: "richtextarea",
              label: "Başlık",
            },
            link: {
              type: "text",
              label: "Link",
            },
          },
          defaultItemProps: {
            title: "Alt başlık",
            link: "#",
          },
        },
      },
      defaultItemProps: {
        elementTitle: "Başlık",
        footerElement: [
          {
            title: "Alt başlık",
            link: "#",
          },
          {
            title: "Alt başlık",
            link: "#",
          },
        ],
      },
    },
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    customColorIsGradient: {
      type: "radio",
      label: "Arkaplan Rengi Tarzı",
      options: [
        {
          value: true,
          label: "Gradient",
        },
        {
          value: false,
          label: "Solid",
        },
      ],
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      defaultExpanded: true,
      fields: [
        "sectionId",
        "mission",
        "theme",
        "youtubeLink",
        "instagramLink",
        "linkedinLink",
        "logo",
        "logoRoundness",
        "footerElements",
      ],
    },
    background: {
      title: "Arkaplan",
      defaultExpanded: false,
      fields: ["backgroundImage", "harmony", "customColorIsGradient"],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"],
    },
  },
  defaultProps: {
    logo: {
      url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/iswewozvf6mwcp99374fzgs5/pi.png",
    },
    theme: "light",
    mission: "Amaca Uygun Finansal Okuryazarlık",
    sectionId: "",
    harmony: [],
    customColorIsGradient: true,
    paddingTop: "xl",
    paddingLeft: "lg",
    youtubeLink: "https://www.youtube.com/@trypieducation",
    linkedinLink: "https://www.linkedin.com/company/pieducation/",
    paddingRight: "lg",
    instagramLink: "https://instagram.com/trypieducation",
    logoRoundness: "sm",
    paddingBottom: "md",
    footerElements: [
      {
        elementTitle: "Deniz Hayal",
        footerElement: [
          { link: "/hakkimda", title: "Hakkımda" },
          { link: "#yorumlar", title: "Öğrencilerim" },
          { link: "#kurslar", title: "Derslerim" },
        ],
      },
      {
        elementTitle: "Destek",
        footerElement: [{ link: "/iletisim", title: "Bana Ulaşın" }],
      },
    ],
    backgroundImage: null,
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    mission,
    logo,
    youtubeLink,
    linkedinLink,
    instagramLink,
    footerElements,
    theme,
    harmony = [],
    customColorIsGradient = false,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    backgroundImage,
    logoRoundness,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "relative isolate overflow-clip",
          {
            "overflow-clip": Boolean(backgroundImage),
            dark: theme === "dark",
            "bg-background": customColorIsGradient || harmony.length === 0,
          },
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
        style={
          !customColorIsGradient && harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      >
        {backgroundImage ? (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
            )}
          />
        ) : customColorIsGradient && harmony.length > 0 ? (
          <BackgroundGradient
            id={sectionId ? sectionId : ""}
            harmony={harmony}
          />
        ) : null}
        <div className={cn("mx-auto max-w-7xl")}>
          <div className="mx-auto w-full">
            <div className="xl:grid xl:grid-cols-2 xl:gap-8">
              <div className="space-y-8">
                {logo && (
                  <Image
                    src={logo.url}
                    alt={logo.alt ?? ""}
                    width={1080}
                    height={1080}
                    unoptimized={!logo.url.includes(".pi.education/")}
                    className={cn(
                      roundedVariants({
                        rounded: logoRoundness,
                      }),
                    )}
                    style={{ width: "90px", height: "90px" }}
                  />
                )}

                {renderDropZone({
                  zone: "footer-below-logo",
                })}

                <div
                  className={cn(
                    " text-md leading-12 max-w-xl break-words whitespace-pre-line",
                    mutedTextColorVariants({ theme }),
                  )}
                  dangerouslySetInnerHTML={{ __html: mission }}
                />
                <div className="flex space-x-6">
                  {youtubeLink && (
                    <a
                      href={youtubeLink}
                      target="_blank"
                      style={{ textAlign: "center" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        className={cn(
                          "h-12 w-12",
                          mutedTextColorVariants({ theme }),
                        )}
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M21.8,8.02c-0.2-1.29-1.29-2.2-2.57-2.2C16.2,5.6,12,5.6,12,5.6s-4.2,0-7.23,0.22c-1.29,0-2.37,0.91-2.57,2.2C2,10.25,2,12,2,12s0,1.74,0.2,3.98c0.2,1.29,1.29,2.2,2.57,2.2C7.8,18.4,12,18.4,12,18.4s4.2,0,7.23-0.22c1.29,0,2.37-0.91,2.57-2.2C22,13.74,22,12,22,12S22,10.25,21.8,8.02z M9.75,15.48V8.52l6.5,3.48L9.75,15.48z"
                        />
                      </svg>
                    </a>
                  )}
                  {instagramLink && (
                    <a
                      href={instagramLink}
                      target="_blank"
                      style={{ textAlign: "center" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        className={cn(
                          "h-10 w-10 m-1",
                          mutedTextColorVariants({ theme }),
                        )}
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        ></path>
                      </svg>
                    </a>
                  )}
                  {linkedinLink && (
                    <a
                      href={linkedinLink}
                      target="_blank"
                      style={{ textAlign: "center" }}
                    >
                      <svg
                        viewBox="0 0 48 48"
                        className={cn(
                          "h-12 w-12",
                          mutedTextColorVariants({ theme }),
                        )}
                      >
                        <g
                          id="Icon/Social/linkedin-color"
                          stroke="none"
                          fill="currentColor"
                        >
                          <path
                            d="M20.9716667,33.5527338 L25.001,33.5527338 L25.001,27.1328007 C25.001,25.439485 25.3213333,23.7988354 27.4206667,23.7988354 C29.491,23.7988354 29.517,25.7351486 29.517,27.2404662 L29.517,33.5527338 L33.5506667,33.5527338 L33.5506667,26.4341413 C33.5506667,22.9381777 32.796,20.2505391 28.711,20.2505391 C26.7483333,20.2505391 25.432,21.3265278 24.8943333,22.3471839 L24.839,22.3471839 L24.839,20.5725357 L20.9716667,20.5725357 L20.9716667,33.5527338 Z M16.423,14.1202696 C15.1273333,14.1202696 14.0823333,15.1682587 14.0823333,16.4595785 C14.0823333,17.7508984 15.1273333,18.7992208 16.423,18.7992208 C17.7133333,18.7992208 18.761,17.7508984 18.761,16.4595785 C18.761,15.1682587 17.7133333,14.1202696 16.423,14.1202696 L16.423,14.1202696 Z M14.4026667,33.5527338 L18.4406667,33.5527338 L18.4406667,20.5725357 L14.4026667,20.5725357 L14.4026667,33.5527338 Z M9.76633333,40 C8.79033333,40 8,39.2090082 8,38.2336851 L8,9.76631493 C8,8.79065843 8.79033333,8 9.76633333,8 L38.234,8 C39.2093333,8 40,8.79065843 40,9.76631493 L40,38.2336851 C40,39.2090082 39.2093333,40 38.234,40 L9.76633333,40 Z"
                            id="Shape"
                            fill="currentColor"
                          ></path>
                        </g>
                      </svg>
                    </a>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 md:gap-8">
                {footerElements.map((element, index) => (
                  <div key={index} className="text-lg leading-6">
                    <div
                      className={cn(
                        "text-lg font-semibold mt-4 mb-4 break-words whitespace-pre-line",
                        mutedTextColorVariants({ theme }),
                      )}
                      dangerouslySetInnerHTML={{ __html: element.elementTitle }}
                    />
                    <ul role="list" className="space-y-2">
                      {element.footerElement &&
                        element.footerElement.map((item, itemIndex) => (
                          <li key={itemIndex} className="whitespace-nowrap">
                            <PiLink
                              href={item.link}
                              className={cn(
                                "text-sm leading-6 transition-colors duration-300 hover:text-blue-500 mt-2 break-words whitespace-pre-line",
                                mutedTextColorVariants({ theme }),
                              )}
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<FooterSectionProps>;
