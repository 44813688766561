import React from "react";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import { cn } from "@/lib/utils";
import { ComponentConfig } from "@/website-editor/core";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import PiLink from "@/components/PiLink";

export type BreadcrumbsProps = {
  sectionId?: string;
  breadcrumbs: Array<{
    label: string;
    url: string;
  }>;
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const Breadcrumbs = {
  displayName: "Breadcrumbs",
  fields: {
    sectionId: SectionIdField,
    breadcrumbs: {
      type: "array",
      label: "Sayfalar",
      getItemSummary: (item, idx) => item.label || `Sayfa ${(idx ?? 0) + 1}`,
      arrayFields: {
        label: { type: "text", label: "Başlık" },
        url: { type: "text", label: "URL" },
      },
      defaultItemProps: {
        label: "Başlık",
        url: "#",
      },
    },
    theme: ThemeField,
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    breadcrumbs: [
      {
        label: "Anasayfa",
        url: "/",
      },
      {
        label: "Kategori",
        url: "/kategori",
      },
      {
        label: "Bu Sayfa",
        url: "/kategori/bu-sayfa",
      },
    ],
    theme: "light",
    sectionId: "",
    paddingTop: "md",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingBottom: "md",
  },
  render: ({
    sectionId,
    breadcrumbs,
    theme,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "flex flex-col md:flex-row overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <Breadcrumb className="mx-auto w-full max-w-7xl">
          <BreadcrumbList>
            <BreadcrumbItem></BreadcrumbItem>
            {breadcrumbs.slice(0, -1).map((breadcrumb, index) => (
              <React.Fragment key={index}>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <PiLink href={breadcrumb.url}>{breadcrumb.label}</PiLink>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </React.Fragment>
            ))}

            {breadcrumbs.length > 0 && (
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {breadcrumbs[breadcrumbs.length - 1]!.label}
                </BreadcrumbPage>
              </BreadcrumbItem>
            )}
          </BreadcrumbList>
        </Breadcrumb>
      </section>
    );
  },
} satisfies ComponentConfig<BreadcrumbsProps>;
