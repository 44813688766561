import { ComponentConfig } from "@/website-editor/core";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { cn } from "@/lib/utils";
import {
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type FAQSectionProps = {
  sectionId?: string;
  title: string;
  questions: Array<{
    question: string;
    answer: string;
  }>;
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const FAQSection = {
  displayName: "Sıkça Sorulan Sorular",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "richtextarea",
      label: "Başlık",
    },
    theme: ThemeField,
    questions: {
      type: "array",
      label: "Sorular",
      getItemSummary: (item, idx) => item.question || `Soru ${(idx ?? 0) + 1}`,
      arrayFields: {
        question: {
          type: "richtextarea",
          label: "Soru",
        },
        answer: {
          type: "richtextarea",
          label: "Cevap",
        },
      },
      defaultItemProps: {
        question: "Soru",
        answer: "Cevap",
      },
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    theme: "light",
    title: "Sıkça Sorulan Sorular",
    questions: [
      {
        answer:
          "Herhangi bir ön bilgi gerektirmeyen kursumuza, finansal yönetim becerilerini geliştirmek isteyen her yaştan ve her seviyeden katılımcı katılabilir. Öğrenciler, yeni mezunlar, profesyoneller veya kişisel finans yönetimini iyileştirmek isteyen herkes için idealdir",
        question: "Bu kursa kimler katılabilir?",
      },
      {
        answer:
          "Evet, kurs içeriğimiz mobil uyumlu olduğu için akıllı telefon veya tablet gibi mobil cihazlarınız üzerinden kolayca erişim sağlayabilirsiniz. Bu sayede, dilediğiniz yerde ve zamanda öğrenmeye devam edebilirsiniz.",
        question: "Kurs içeriğine mobil cihazlarımdan erişebilir miyim?",
      },
      {
        answer:
          "Kursu tamamladığınızda, bütçeleme, tasarruf etme, yatırım yapma, borç yönetimi, finansal planlama ve risk yönetimi gibi çeşitli finansal konularda temel ve ileri düzey yetkinlikler kazanmış olacaksınız. Ayrıca, kişisel finansal hedeflerinize ulaşmanıza yardımcı olacak pratik araçlar ve stratejiler konusunda bilgi sahibi olacaksınız.",
        question: "Kurs sonunda hangi konularda yetkinlik kazanmış olacağım?",
      },
    ],
    sectionId: "",
    paddingTop: "xl",
    paddingLeft: "lg",
    paddingRight: "sm",
    paddingBottom: "md",
  },
  render: ({
    sectionId,
    title,
    questions,
    theme,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div
          className={cn(
            "mx-auto max-w-7xl",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
          )}
        >
          <div
            className={cn(
              "mb-4 text-2xl font-bold md:text-3xl break-words whitespace-pre-line",
              textColorVariants({ theme }),
            )}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Accordion type="multiple">
            {questions.map((item, idx) => (
              <AccordionItem
                key={idx}
                value={`item-${idx}`}
                className="mb-4 last:mb-0"
              >
                <AccordionTrigger
                  className={cn(
                    "flex justify-between items-center p-4",
                    textColorVariants({ theme }),
                  )}
                >
                  <span className="flex items-center">
                    <span
                      className={cn(
                        "text-lg font-semibold break-words whitespace-pre-line",
                        textColorVariants({ theme }),
                      )}
                      dangerouslySetInnerHTML={{ __html: item.question }}
                    />
                  </span>
                </AccordionTrigger>
                <AccordionContent
                  className={cn(
                    "p-4 rounded-b-lg break-words whitespace-pre-line",
                    textColorVariants({ theme }),
                  )}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.answer }} />
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<FAQSectionProps>;
