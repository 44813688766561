import { AppState, ComponentData, Config, RootData } from "../types/Config";
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { PuckAction } from "../reducer";
import { resolveComponentData } from "./resolve-component-data";
import { applyDynamicProps } from "./apply-dynamic-props";
import { resolveRootData } from "./resolve-root-data";
import { isEqualWithoutVolatileInternalKeys } from "@/website-editor/core/lib/volatile-internal-keys-helpers";

export const useResolvedData = (
  appState: AppState,
  config: Config,
  dispatch: Dispatch<PuckAction>,
) => {
  const [componentState, setComponentState] = useState<
    Record<string, { loading: boolean }>
  >({});

  const deferredSetStates = useRef<Record<string, NodeJS.Timeout>>({});

  const setComponentLoading = useCallback(
    (id: string, loading: boolean, defer: number = 0) => {
      if (deferredSetStates.current[id]) {
        clearTimeout(deferredSetStates.current[id]);

        delete deferredSetStates.current[id];
      }

      deferredSetStates.current[id] = setTimeout(() => {
        setComponentState((prev) => ({
          ...prev,
          [id]: { ...prev[id], loading },
        }));

        delete deferredSetStates.current[id];
      }, defer);
    },
    [],
  );

  useEffect(() => {
    // initial load of data
    const newData = appState.data;

    const flatContent = newData.zones
      ? Object.keys(newData.zones)
          .reduce(
            (acc, zone) => [...acc, ...newData.zones![zone]!],
            newData.content,
          )
          .filter((item) => !!config.components[item.type]?.resolveData)
      : [];

    const applyIfChange = (
      dynamicDataMap: Record<string, ComponentData>,
      dynamicRoot?: RootData,
    ) => {
      // Apply the dynamic content to `data`, not `newData`, in case `data` has been changed by the user
      const processed = applyDynamicProps(
        appState.data,
        dynamicDataMap,
        dynamicRoot,
      );

      const processedAppState = { ...appState, data: processed };

      const containsChanges = !isEqualWithoutVolatileInternalKeys(
        processedAppState,
        appState,
      );

      if (containsChanges) {
        dispatch({
          type: "set",
          state: (prev) => ({
            ...prev,
            data: applyDynamicProps(prev.data, dynamicDataMap, dynamicRoot),
            ui: { ...prev.ui, ...appState.ui },
          }),
          recordHistory: true,
        });
      }
    };

    const promises: Promise<void>[] = [];

    promises.push(
      (async () => {
        setComponentLoading("puck-root", true, 50);

        const dynamicRoot = await resolveRootData(newData, config);

        applyIfChange({}, dynamicRoot);

        setComponentLoading("puck-root", false);
      })(),
    );

    flatContent.forEach((item) => {
      promises.push(
        (async () => {
          const dynamicData: ComponentData = await resolveComponentData(
            item,
            null,
            config,
            (item) => {
              setComponentLoading(item.props.id, true, 50);
            },
            (item) => {
              deferredSetStates.current[item.props.id];

              setComponentLoading(item.props.id, false);
            },
          );

          const dynamicDataMap = { [item.props.id]: dynamicData };

          applyIfChange(dynamicDataMap);
        })(),
      );
    });

    Promise.all(promises).then();
  }, []);

  const resolveData = useCallback(
    (
      newAppState: AppState,
      changed?: Partial<Record<string | number | symbol, boolean>>,
    ) => {
      // Flatten zones
      const newData = newAppState.data;

      const flatContent = newData.zones
        ? Object.keys(newData.zones)
            .reduce(
              (acc, zone) => [...acc, ...newData.zones![zone]!],
              newData.content,
            )
            .filter((item) => !!config.components[item.type]?.resolveData)
        : [];

      const applyIfChange = (
        dynamicDataMap: Record<string, ComponentData>,
        dynamicRoot?: RootData,
      ) => {
        // Apply the dynamic content to `data`, not `newData`, in case `data` has been changed by the user
        const processed = applyDynamicProps(
          appState.data,
          dynamicDataMap,
          dynamicRoot,
        );

        const processedAppState = { ...appState, data: processed };

        const containsChanges = !isEqualWithoutVolatileInternalKeys(
          processedAppState,
          appState,
        );

        if (containsChanges) {
          dispatch({
            type: "set",
            state: (prev) => ({
              ...prev,
              data: applyDynamicProps(prev.data, dynamicDataMap, dynamicRoot),
              ui: { ...prev.ui, ...newAppState.ui },
            }),
            recordHistory: true,
          });
        }
      };

      const promises: Promise<void>[] = [];

      promises.push(
        (async () => {
          setComponentLoading("puck-root", true, 50);

          const dynamicRoot = await resolveRootData(newData, config);

          applyIfChange({}, dynamicRoot);

          setComponentLoading("puck-root", false);
        })(),
      );

      flatContent.forEach((item) => {
        promises.push(
          (async () => {
            const dynamicData: ComponentData = await resolveComponentData(
              item,
              changed ?? null,
              config,
              (item) => {
                setComponentLoading(item.props.id, true, 50);
              },
              (item) => {
                deferredSetStates.current[item.props.id];

                setComponentLoading(item.props.id, false);
              },
            );

            const dynamicDataMap = { [item.props.id]: dynamicData };

            applyIfChange(dynamicDataMap);
          })(),
        );
      });

      Promise.all(promises).then();
    },
    [appState, config, dispatch, setComponentLoading],
  );

  return {
    resolveData,
    componentState,
  };
};
