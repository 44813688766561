/**
 * @generated SignedSource<<64e7cb8409d92478fc8be90286e3ff06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "AED" | "EUR" | "GBP" | "KWD" | "QAR" | "SAR" | "TRY" | "USD" | "%future added value";
export type CoachingSessionPlansAndActionsQuery$variables = {
  id: string;
};
export type CoachingSessionPlansAndActionsQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly organization?: {
      readonly slug: string;
    };
    readonly plans?: ReadonlyArray<{
      readonly discountedPrice: {
        readonly amount: string;
        readonly currency: Currency;
      } | null | undefined;
      readonly id: string;
      readonly originalPrice: {
        readonly amount: string;
        readonly currency: Currency;
      } | null | undefined;
      readonly sessionCount: number;
      readonly viewerCanOrder: boolean | null | undefined;
    }>;
    readonly slug?: string;
    readonly viewerStudent?: {
      readonly remainingSessionCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type CoachingSessionPlansAndActionsQuery = {
  response: CoachingSessionPlansAndActionsQuery$data;
  variables: CoachingSessionPlansAndActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CoachingSessionPlan",
  "kind": "LinkedField",
  "name": "plans",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sessionCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "originalPrice",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "discountedPrice",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingSessionCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachingSessionPlansAndActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingSessionStudent",
                "kind": "LinkedField",
                "name": "viewerStudent",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CoachingSession",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachingSessionPlansAndActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingSessionStudent",
                "kind": "LinkedField",
                "name": "viewerStudent",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CoachingSession",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0faf2643aea1fdc3763017ff83b43837",
    "id": null,
    "metadata": {},
    "name": "CoachingSessionPlansAndActionsQuery",
    "operationKind": "query",
    "text": "query CoachingSessionPlansAndActionsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CoachingSession {\n      id\n      slug\n      plans {\n        id\n        sessionCount\n        viewerCanOrder\n        originalPrice {\n          amount\n          currency\n        }\n        discountedPrice {\n          amount\n          currency\n        }\n      }\n      viewerStudent {\n        remainingSessionCount\n        id\n      }\n      organization {\n        slug\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "588a4e8bcadf4cd3980afa6595d75d89";

export default node;
