import { cva } from "class-variance-authority";

export type Blur = "none" | "sm" | "base" | "md" | "lg" | "xl" | "2xl" | "3xl";

export const BlurField = {
  type: "select" as const,
  label: "Arkaplan Bulanıklığı",
  options: [
    {
      value: "none",
      label: "Yok",
    },
    {
      value: "sm",
      label: "Hafif",
    },
    {
      value: "base",
      label: "Orta",
    },
    {
      value: "md",
      label: "Çok Bulanık",
    },
    {
      value: "lg",
      label: "En Bulanık",
    },
  ],
};

export const blurVariants = cva(undefined, {
  variants: {
    blur: {
      none: "blur-none",
      sm: "blur-sm",
      base: "blur",
      md: "blur-md",
      lg: "blur-lg",
      xl: "blur-xl",
      "2xl": "blur-2xl",
      "3xl": "blur-3xl",
    },
  },
  defaultVariants: {
    blur: "none",
  },
});
