import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import Image from "next/image";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { iconOptions } from "@/website-editor/custom-fields/icon";
import { cn, hsv2hex } from "@/lib/utils";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { HarmonyCustomField } from "@/website-editor/custom-fields/harmony";
import BackgroundGradient from "@/components/background-gradient";
import { PlateCustomField } from "@/website-editor/custom-fields/plate";
import React from "react";
import { nanoid } from "nanoid/non-secure";
import FeaturesGridFeature from "@/website-editor/blocks/features-grid-section/FeaturesGridFeature";
import { Shadow, ShadowField } from "@/website-editor/custom-fields/shadow";

export type Feature = {
  id: string;
  image: {
    url: string;
    alt?: string;
  };
  title: string;
  slogan: string;
  description: any;
  icon?: string;
};

export type FeaturesGridSectionProps = {
  sectionId?: string;
  title: string;
  description: string;
  elements: Array<Feature>;
  gridGap?: "sm" | "base" | "lg";
  numberOfGridColumns: 2 | 3 | 4;
  theme: Theme;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  harmony: any;
  featureRevealAnimation: "none" | "fade-in-from-left-staggered";
  featureCardShadow: Shadow;
  customColorIsGradient: boolean;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const FeaturesGridSection = {
  displayName: "Özellikler Izgarası",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    customColorIsGradient: {
      type: "radio",
      label: "Arkaplan Rengi Tarzı",
      options: [
        {
          value: true,
          label: "Gradient",
        },
        {
          value: false,
          label: "Solid",
        },
      ],
    },
    theme: ThemeField,
    elements: {
      type: "array",
      label: "İçerikler",
      getItemSummary: (item, idx) => item.title || `İçerik ${(idx ?? 0) + 1}`,
      arrayFields: {
        id: {
          type: "custom",
          label: "ID",
          render: () => <></>,
        },
        title: {
          type: "textarea",
          label: "Başlık",
        },
        slogan: {
          type: "richtextarea",
          label: "Slogan",
        },
        description: {
          ...PlateCustomField,
          label: "Açıklama",
        },
        image: {
          ...ImageCustomField,
          label: "Resim",
        },
        icon: {
          type: "select",
          label: "İkon",
          options: iconOptions,
        },
      },
      getDefaultItemProps: () => ({
        id: `Item-${nanoid()}`,
        title: "Başlık",
        description: null,
        slogan: "Slogan",
        image: {
          url: "https://pics.craiyon.com/2023-10-24/19a8cb2dd1df463aa043966165904412.webp",
          alt: "",
        },
        icon: "MagicWandIcon",
      }),
    },
    numberOfGridColumns: {
      type: "radio",
      label: "Sütun Sayısı",
      options: [
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
      ],
    },
    gridGap: {
      type: "radio",
      label: "Sütun Arası Boşluk",
      options: [
        {
          value: "sm",
          label: "Küçük",
        },
        {
          value: "base",
          label: "Orta",
        },
        {
          value: "lg",
          label: "Büyük",
        },
      ],
    },
    featureRevealAnimation: {
      type: "radio",
      label: "Özellik Gösterme Animasyonu",
      options: [
        {
          value: "none",
          label: "Yok",
        },
        {
          value: "fade-in-from-left-staggered",
          label: "Sola Sağa Sırayla",
        },
      ],
    },
    featureCardShadow: {
      ...ShadowField,
      label: "Özellik Kartı Gölgesi",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      defaultExpanded: true,
      fields: [
        "sectionId",
        "title",
        "theme",
        "description",
        "elements",
        "numberOfGridColumns",
      ],
    },
    background: {
      title: "Arkaplan",
      defaultExpanded: false,
      fields: ["backgroundImage", "harmony", "customColorIsGradient"],
    },
    animation: {
      title: "Animasyon",
      defaultExpanded: false,
      fields: ["featureRevealAnimation"],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: [
        "gridGap",
        "paddingTop",
        "paddingBottom",
        "paddingLeft",
        "paddingRight",
      ],
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    paddingLeft: "xl",
    paddingRight: "xl",
    paddingTop: "xl",
    paddingBottom: "md",
    backgroundImage: null,
    harmony: [],
    customColorIsGradient: true,
    title: "Coğrafyanın Büyüleyici Dünyasına Seyahat",
    featureRevealAnimation: "none",
    featureCardShadow: "sm",
    description: "",
    elements: [
      {
        id: `Item-${nanoid()}`,
        title: "İnteraktif Haritalar",
        description: "",
        slogan: "Öğrendiklerini Tecrübe Ederek Pekiştir",
        image: {
          url: "https://i.pinimg.com/originals/e2/3c/fd/e23cfd081f30495af53c876837a183ec.jpg",
          alt: "",
        },
        icon: "MagicWandIcon",
      },
      {
        id: `Item-${nanoid()}`,
        title: "Başlık",
        description: "",
        slogan: "Slogan",
        image: {
          url: "https://pics.craiyon.com/2023-10-24/19a8cb2dd1df463aa043966165904412.webp",
          alt: "",
        },
        icon: "MagicWandIcon",
      },
      {
        id: `Item-${nanoid()}`,
        title: "Başlık",
        description: "",
        slogan: "Slogan",
        image: {
          url: "https://pics.craiyon.com/2023-10-24/19a8cb2dd1df463aa043966165904412.webp",
          alt: "",
        },
        icon: "MagicWandIcon",
      },
      {
        id: `Item-${nanoid()}`,
        title: "Başlık",
        description: "",
        slogan: "Slogan",
        image: {
          url: "https://pics.craiyon.com/2023-10-24/19a8cb2dd1df463aa043966165904412.webp",
          alt: "",
        },
        icon: "MagicWandIcon",
      },
    ],
    numberOfGridColumns: 2,
  },
  render: ({
    puck: { renderDropZone },
    id,
    sectionId,
    title,
    description,
    elements,
    theme = "light",
    paddingLeft = "xl",
    paddingRight = "xl",
    paddingTop = "xl",
    paddingBottom = "md",
    numberOfGridColumns = 2,
    gridGap,
    featureRevealAnimation = "none",
    featureCardShadow = "sm",
    backgroundImage,
    harmony = [],
    customColorIsGradient = true,
  }) => {
    return (
      <div
        id={sectionId || undefined}
        className={cn(
          "relative isolate overflow-clip",
          {
            "overflow-clip": Boolean(backgroundImage),
            dark: theme === "dark",
            "bg-background": customColorIsGradient || harmony.length === 0,
          },
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
        )}
        style={
          !customColorIsGradient && harmony.length > 0
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      >
        {backgroundImage ? (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
              mutedTextColorVariants({ theme }),
            )}
          />
        ) : customColorIsGradient && harmony.length > 0 ? (
          <BackgroundGradient id={id} harmony={harmony} />
        ) : null}
        <div className={cn("mx-auto max-w-7xl")}>
          {title || description ? (
            <div className="mb-6 text-center sm:mb-12">
              {title && (
                <h2
                  className={cn(
                    "text-2xl font-bold tracking-tight sm:text-3xl break-words whitespace-pre-line",
                    textColorVariants({ theme }),
                  )}
                >
                  {title}
                </h2>
              )}
              {description && (
                <div
                  className={cn(
                    "mt-2 sm:mt-4 text-base sm:text-lg leading-8 break-words mx-auto max-w-prose whitespace-pre-line",
                    mutedTextColorVariants({ theme }),
                  )}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
          ) : null}

          <ul
            role="list"
            className={cn(
              `grid mt-4 grid-cols-2`,
              gridGap === "sm"
                ? "gap-4 lg:gap-6 xl:gap-8"
                : gridGap === "lg"
                  ? "gap-16 lg:gap-24 xl:gap-32"
                  : "gap-8 lg:gap-12 xl:gap-16",
              {
                "sm:grid-cols-4": numberOfGridColumns == 2,
                "sm:grid-cols-6": numberOfGridColumns == 3,
                "sm:grid-cols-8": numberOfGridColumns == 4,
              },
              textColorVariants({ theme }),
            )}
          >
            {elements.map((element, idx) => (
              <FeaturesGridFeature
                index={idx}
                shadow={featureCardShadow}
                key={idx}
                dropzone={renderDropZone({
                  zone: element.id || `element-${idx}`,
                })}
                animation={featureRevealAnimation}
                theme={theme}
                numberOfGridColumns={numberOfGridColumns}
                element={element}
              />
            ))}
          </ul>
          {renderDropZone({
            zone: "bottom",
          })}
        </div>
      </div>
    );
  },
} satisfies ComponentConfig<FeaturesGridSectionProps>;
