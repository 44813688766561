import { Field } from "@/website-editor/core";
import { coachingSessionFetchList } from "@/website-editor/custom-fields/coachingSessionFetchList";

export function getCoachingSessionField(organization: { slug: string }) {
  return {
    type: "external",
    fetchList: async ({ query, environment }) => {
      if (!environment) {
        return [];
      }

      return coachingSessionFetchList({
        query,
        environment,
        organizationSlug: organization.slug,
      });
    },
    mapRow: ({ id, ...other }) => {
      return other;
    },
    mapProp: (item) => {
      return { id: item.id };
    },
    getItemSummary: (item) => {
      if (!item) {
        return "";
      }
      return item.id;
    },
    label: "Birebir Görüşme",
    placeholder: "Seç",
    // showSearch: true,
  } satisfies Field<{ id: string }>;
}
