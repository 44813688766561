import { ComponentConfig } from "@/website-editor/core";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { cn } from "@/lib/utils";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import EmbedComponent from "@/components/embed";
import { isUrl } from "@/components/utils/isUrl";

export type EmbedProps = {
  sectionId?: string;
  theme: Theme;
  embed: string;
  maxWidth: "7xl" | "none";
  height: number;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Embed = {
  displayName: "Embed (Gömülü İçerik)",
  tooltip:
    "Bu bloğu kullanarak sosyal medya paylaşımları, harita, form gibi içerikleri ekleyebilirsiniz.",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    embed: {
      type: "textarea",
      label: "URL veya Gömülü Kod",
    },
    maxWidth: {
      type: "radio",
      label: "Maksimum Genişliği Sınırla",
      options: [
        {
          value: "7xl",
          label: "Evet",
        },
        {
          value: "none",
          label: "Hayır",
        },
      ],
    },
    height: {
      type: "number",
      label: "Yükseklik (px)",
      min: 0,
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    embed:
      '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ?si=8UrEvkVLJ-cTEdSm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    maxWidth: "7xl",
    height: 315,
    paddingTop: "lg",
    paddingBottom: "lg",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    sectionId,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    embed,
    maxWidth,
    height,
    theme,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn("relative isolate overflow-clip bg-background", {
          dark: theme === "dark",
        })}
      >
        <div
          className={cn(
            "relative",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            paddingVariants({ side: "left", size: marginLeft }),
            paddingVariants({ side: "right", size: marginRight }),
            {
              "max-w-7xl mx-auto": maxWidth !== "none",
            },
          )}
        >
          <EmbedComponent
            customHtml={isUrl(embed) ? null : embed}
            url={embed}
            className="mx-auto w-fit"
            style={{
              height: `${height}px`,
            }}
          />
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<EmbedProps>;
