"use client";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { z } from "zod";
import { useOrganization } from "@/student/context/OrganizationContext";
import { graphql, useMutation } from "react-relay";
import { useToast } from "@/components/ui/use-toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSubmitButton,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import React from "react";
import { CreateWebPageComponentTemplateDialogMutation } from "@generated/CreateWebPageComponentTemplateDialogMutation.graphql";
import { Textarea } from "@/components/ui/textarea";

const inputSchema = z.object({
  title: z
    .string({
      required_error: "Lütfen bir başlık gir.",
    })
    .min(1, { message: "Lütfen bir başlık gir." }),
  description: z.string().optional(),
});

const CreateWebPageComponentTemplateDialog: React.FC<{
  templateToSave: {
    component: {
      type: string;
      props: object;
    };
    zones: {
      [key: string]: Array<{ type: string; props: object }>;
    };
  } | null;
  resetTemplateToSave: () => void;
}> = ({ templateToSave, resetTemplateToSave }) => {
  const { toast } = useToast();
  const organization = useOrganization();

  const [
    commitCreateWebPageComponentTemplate,
    isCreateWebPageComponentTemplateInFlight,
  ] = useMutation<CreateWebPageComponentTemplateDialogMutation>(graphql`
    mutation CreateWebPageComponentTemplateDialogMutation(
      $input: CreateWebPageComponentTemplateInput!
    ) {
      createWebPageComponentTemplate(input: $input) {
        webPageComponentTemplate {
          id
          ...WebPageComponentTemplate
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `);

  const form = useForm({
    resolver: zodResolver(inputSchema),
    mode: "onTouched",
    defaultValues: {
      title: "",
      description: "",
    },
  });

  return (
    <Dialog
      open={templateToSave !== null}
      onOpenChange={(open) => {
        if (!open) {
          resetTemplateToSave();
        }
      }}
    >
      <Form {...form}>
        <DialogContent className="sm:max-w-5xl">
          <form
            onSubmit={form.handleSubmit(async (formData) => {
              if (isCreateWebPageComponentTemplateInFlight) {
                return;
              }

              commitCreateWebPageComponentTemplate({
                variables: {
                  input: {
                    organizationId: organization.id,
                    title: formData.title,
                    description: formData.description || null,
                    component: templateToSave!.component.type,
                    content: templateToSave!,
                  },
                },
                updater: (store, mutationData) => {
                  if (
                    mutationData === null ||
                    mutationData === undefined ||
                    !mutationData.createWebPageComponentTemplate
                      .webPageComponentTemplate
                  ) {
                    return;
                  }

                  const organizationRecord = store.get(organization.id);

                  if (!organizationRecord) {
                    return;
                  }

                  const webPageComponentTemplates =
                    organizationRecord.getLinkedRecords(
                      "webPageComponentTemplates",
                      { component: templateToSave?.component.type },
                    );

                  if (!webPageComponentTemplates) {
                    return;
                  }

                  const createdWebPageTemplateComponent = store.get(
                    mutationData.createWebPageComponentTemplate
                      .webPageComponentTemplate.id,
                  )!;

                  const newWebPageComponentTemplates = [
                    createdWebPageTemplateComponent,
                    ...webPageComponentTemplates,
                  ];

                  organizationRecord.setLinkedRecords(
                    newWebPageComponentTemplates,
                    "webPageComponentTemplates",
                    { component: templateToSave?.component.type },
                  );
                },
                onCompleted: (mutationData, errors) => {
                  if (errors) {
                    console.error(errors);
                    return;
                  }

                  if (
                    mutationData.createWebPageComponentTemplate
                      .webPageComponentTemplate === null ||
                    mutationData.createWebPageComponentTemplate
                      .webPageComponentTemplate === undefined
                  ) {
                    console.error(
                      "createWebPageComponentTemplate.webPageComponentTemplate is null",
                    );
                    return;
                  }

                  toast({
                    title: "Şablon oluşturuldu",
                    description:
                      "Artık bu komponenti oluştururken bu şablonu kullanabileceksin.",
                  });
                  resetTemplateToSave();
                },
              });
            })}
          >
            <DialogHeader>
              <DialogTitle>Şablon Oluştur</DialogTitle>
              <DialogDescription></DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Şablon Adı</FormLabel>
                    <FormControl>
                      <Input placeholder="Şablon Adı" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Açıklama (Opsiyonel)</FormLabel>
                    <FormControl>
                      <Textarea placeholder="Açıklama" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <FormSubmitButton
                loading={isCreateWebPageComponentTemplateInFlight}
                className="w-full"
              >
                Şablon Oluştur
              </FormSubmitButton>
            </DialogFooter>
          </form>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export default CreateWebPageComponentTemplateDialog;
