"use client";

import { cn } from "@/lib/utils";
import { textColorVariants, Theme } from "@/website-editor/custom-fields/theme";
import Image from "next/image";
import PlateRenderer from "@/components/plate-renderer";
import { motion } from "framer-motion";
import React from "react";
import { Feature } from "@/website-editor/blocks/features-grid-section/index";
import { Shadow, shadowVariants } from "@/website-editor/custom-fields/shadow";

const fadeInAnimationVariants = {
  hidden: { opacity: 0, x: -100 },
  "fade-in-from-left-staggered": (index: number) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.1 * index,
    },
  }),
};

const FeaturesGridFeature: React.FC<{
  index: number;
  animation: "none" | "fade-in-from-left-staggered";
  shadow: Shadow;
  numberOfGridColumns: number;
  theme: Theme;
  element: Feature;
  dropzone: React.ReactNode;
}> = ({
  index,
  animation,
  numberOfGridColumns,
  shadow,
  theme,
  element,
  dropzone,
}) => {
  return (
    <motion.li
      className={cn(
        "col-span-2 flex flex-col rounded-lg",
        textColorVariants({ theme }),
        shadowVariants({ shadow }),
        {
          "sm:last:odd:col-start-2": numberOfGridColumns == 2,
        },
      )}
      variants={fadeInAnimationVariants}
      initial={animation === "none" ? undefined : "hidden"}
      whileInView={animation === "none" ? undefined : animation}
      viewport={{ once: true }}
      custom={index}
    >
      <h3
        className={cn(
          "text-lg sm:text-2xl font-bold text-center break-words whitespace-pre-line",
          textColorVariants({ theme }),
        )}
      >
        {element.title}
      </h3>

      {element.image?.url && (
        <Image
          src={element.image.url}
          alt={element.image.alt ?? ""}
          width={1080}
          height={1080}
          unoptimized={!element.image.url.includes(".pi.education/")}
          className={cn(
            "mx-auto mt-4 rounded-xl w-full h-auto",
            textColorVariants({ theme }),
          )}
        />
      )}

      <dl className="mt-4 flex grow flex-col">
        <div
          className={cn(
            "text-center text-xl font-bold break-words whitespace-pre-line",
            textColorVariants({ theme }),
          )}
          dangerouslySetInnerHTML={{ __html: element.slogan }}
        />
        <PlateRenderer className="mt-2" value={element.description || null} />
      </dl>
      {dropzone}
    </motion.li>
  );
};

export default FeaturesGridFeature;
