"use client";
import { graphql, usePreloadedQuery, useRelayEnvironment } from "react-relay";
import React from "react";
import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import { textColorVariants, Theme } from "@/website-editor/custom-fields/theme";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import BlogsActiveBlogsQueryNode, {
  BlogsActiveBlogsQuery,
} from "@generated/BlogsActiveBlogsQuery.graphql";
import PiLink from "@/components/PiLink";
import { cn } from "@/lib/utils";
import Image from "next/image";
import { formatDateToRelative } from "@/lib/date-utils";

const Blogs: React.FC<{
  preloadedQuery: SerializablePreloadedQuery<
    typeof BlogsActiveBlogsQueryNode,
    BlogsActiveBlogsQuery
  >;
  theme: Theme;
}> = ({ preloadedQuery, theme }) => {
  const environment = useRelayEnvironment();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);

  const data = usePreloadedQuery(
    graphql`
      query BlogsActiveBlogsQuery($organizationSlug: String!) {
        organization(slug: $organizationSlug) {
          activeWebPages(type: "BLOG") {
            id
            normalizedPath
            root
          }
        }
      }
    `,
    queryRef,
  );

  if (!data) {
    return <p>No data</p>;
  }

  if (!data.organization) {
    return <p>No node</p>;
  }

  return (
    <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 sm:grid-cols-2 lg:grid-cols-3">
      {data.organization.activeWebPages.map((page) => (
        <BlogCard key={page.id} page={page} theme={theme} />
      ))}
    </div>
  );
};

const BlogCard: React.FC<{
  page: {
    normalizedPath: string;
    root: {
      blog?: {
        image?: {
          url: string;
          alt?: string;
        };
        category?: string;
        title?: string;
        description?: string;
        publishedAt?: string;
      };
    };
  };
  theme: Theme;
}> = ({
  page: {
    normalizedPath,
    root: { blog },
  },
  theme,
}) => {
  if (!blog) return null;

  return (
    <PiLink
      href={`/${normalizedPath}`}
      className="overflow-hidden rounded-2xl transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
    >
      <article className="flex flex-col items-start justify-between">
        <div className="relative w-full">
          <Image
            src={blog.image?.url ?? ""}
            alt={blog.image?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!blog.image?.url.includes(".pi.education/")}
            className="aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
          />
        </div>
        <div className="p-2">
          <div className="mt-4 flex items-center gap-x-4 text-xs">
            {blog.publishedAt ? (
              <time dateTime={blog.publishedAt} className="text-gray-500">
                {formatDateToRelative(blog.publishedAt, false)}
              </time>
            ) : null}

            <div className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600">
              {blog.category}
            </div>
          </div>
          <div className="group relative">
            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              {blog.title}
            </h3>
            {blog.description ? (
              <div
                className={cn(
                  "mt-5 line-clamp-3 text-sm leading-6 text-gray-600",
                  textColorVariants({ theme }),
                )}
                dangerouslySetInnerHTML={{ __html: blog.description }}
              />
            ) : null}
          </div>
        </div>
      </article>
    </PiLink>
  );
};

export default Blogs;
