import { Data } from "../types/Config";
import { rootDroppableId } from "./root-droppable-id";

export const setupZone = (data: Data, zoneKey: string): Required<Data> => {
  if (zoneKey === rootDroppableId) {
    return data as Required<Data>;
  }

  // data from relay is read-only, so we need to clone it to be able to modify it
  const newData = structuredClone(data);
  newData.zones = newData.zones || {};
  newData.zones[zoneKey] = newData.zones[zoneKey] || [];

  return newData as Required<Data>;
};
