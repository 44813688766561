import { rootDroppableId } from "../../../../lib/root-droppable-id";
import {
  ReplaceAction,
  replaceAction,
  SetAction,
  setAction,
} from "../../../../reducer";
import { ComponentData, RootData, UiState } from "@/website-editor/core";
import type {
  Field,
  Fields as FieldsType,
} from "@/website-editor/core/types/Fields";
import { AutoField } from "../../../AutoField";
import { useAppContext } from "../../context";

import styles from "./styles.module.css";
import { getClassNameFactory } from "../../../../lib";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { CaretUpIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { mutedTextColorVariants } from "@/website-editor/custom-fields/theme";
import { getChanged } from "@/website-editor/core/lib/get-changed";
import { Loader } from "@/website-editor/core/components/Loader";

const getClassName = getClassNameFactory("PuckFields", styles);

const defaultPageFields: Record<string, Field> = {
  title: { type: "text" },
};

const DefaultFields = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

type ComponentOrRootData = Omit<Partial<ComponentData<any>>, "type">;

const useResolvedFields = (): [FieldsType, boolean] => {
  const { selectedItem, state, config } = useAppContext();

  const { data } = state;

  const rootFields = config.root?.fields || defaultPageFields;

  const componentConfig = selectedItem
    ? config.components[selectedItem.type]
    : null;

  const defaultFields = selectedItem
    ? (componentConfig?.fields as Record<string, Field<any>>)
    : rootFields;

  // DEPRECATED
  const rootProps = data.root.props || data.root;

  const [lastSelectedData, setLastSelectedData] = useState<ComponentOrRootData>(
    {},
  );
  const [resolvedFields, setResolvedFields] = useState(defaultFields || {});
  const [fieldsLoading, setFieldsLoading] = useState(false);

  const componentData: ComponentOrRootData = selectedItem
    ? selectedItem
    : { props: rootProps, readOnly: data.root.readOnly };

  const resolveFields = useCallback(
    async (fields: FieldsType = {}) => {
      const lastData =
        lastSelectedData.props?.id === componentData.props.id
          ? lastSelectedData
          : {};

      setLastSelectedData(componentData);

      if (!componentConfig?.resolveFields) {
        return defaultFields;
      }

      const changed = getChanged(componentData, lastData);

      if (selectedItem && componentConfig?.resolveFields) {
        return componentConfig?.resolveFields(componentData as ComponentData, {
          changed,
          fields,
          lastFields: resolvedFields,
          lastData: lastData as ComponentData,
          appState: state,
        });
      }

      if (!selectedItem && config.root?.resolveFields) {
        return config.root?.resolveFields(componentData, {
          changed,
          fields,
          lastFields: resolvedFields,
          lastData: lastData as RootData,
          appState: state,
        });
      }
    },
    [data, config, componentData, selectedItem, resolvedFields, state],
  );

  useEffect(() => {
    if (componentConfig?.resolveFields) {
      setFieldsLoading(true);
    }

    resolveFields(defaultFields).then((fields) => {
      setResolvedFields(fields || {});

      setFieldsLoading(false);
    });
  }, [data, defaultFields]);

  return [resolvedFields, fieldsLoading];
};

export const Fields = () => {
  const { selectedItem, config, componentState, overrides } = useAppContext();

  const [fields, fieldsResolving] = useResolvedFields();

  const categories = selectedItem
    ? config.components[selectedItem.type]?.categories
    : {};

  const componentResolving = selectedItem
    ? componentState[selectedItem?.props.id]?.loading
    : componentState["puck-root"]?.loading;

  const isLoading = fieldsResolving || componentResolving;

  const Wrapper = useMemo(
    () => overrides.fields || DefaultFields,
    [overrides.fields],
  );

  return (
    <form
      className={getClassName()}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Wrapper isLoading={isLoading ?? false}>
        {categories && Object.keys(categories).length > 0
          ? Object.keys(categories).map((categoryKey) => {
              const category = categories[categoryKey];

              if (!category) return null;

              const categoryFields = category.fields || [];

              return (
                <Collapsible
                  key={categoryKey}
                  title={category.title}
                  defaultOpen={category.defaultExpanded}
                >
                  <CollapsibleTrigger asChild>
                    <Button
                      variant="ghost"
                      size="lg"
                      className={cn(
                        "group w-full justify-between whitespace-pre uppercase",
                        mutedTextColorVariants({
                          theme: "light",
                        }),
                      )}
                    >
                      {category.title ?? "Other"}
                      <CaretUpIcon className="size-4 group-data-[state=open]:rotate-180" />
                      <span className="sr-only">
                        Toggle {category.title ?? "other"} components
                      </span>
                    </Button>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    {categoryFields.map((field) => (
                      <FieldComponent
                        key={field.toString()}
                        fieldName={field.toString()}
                      />
                    ))}
                  </CollapsibleContent>
                </Collapsible>
              );
            })
          : Object.keys(fields).map((field) => (
              <FieldComponent key={field} fieldName={field} />
            ))}
        {isLoading && (
          <div className={getClassName("loadingOverlay")}>
            <div className={getClassName("loadingOverlayInner")}>
              <Loader size={16} />
            </div>
          </div>
        )}
      </Wrapper>
    </form>
  );
};

const FieldComponent: React.FC<{
  fieldName: string;
}> = ({ fieldName }) => {
  const { selectedItem, state, dispatch, config, resolveData } =
    useAppContext();

  const { data, ui } = state;
  const { itemSelector } = ui;

  const rootFields = config.root?.fields || defaultPageFields;

  const fields = selectedItem
    ? (config.components[selectedItem.type]?.fields as Record<
        string,
        Field<any>
      >) || {}
    : rootFields;

  const field = fields[fieldName]!;

  if (!field?.type) return null;

  const rootProps = data.root.props;

  const onChange = (value: any, updatedUi?: Partial<UiState>) => {
    let currentProps;

    if (selectedItem) {
      currentProps = selectedItem.props;
    } else {
      currentProps = rootProps;
    }

    const newProps = {
      ...currentProps,
      [fieldName]: value,
    };

    if (itemSelector) {
      const replaceActionData: ReplaceAction = {
        type: "replace",
        destinationIndex: itemSelector.index,
        destinationZone: itemSelector.zone || rootDroppableId,
        data: { ...selectedItem, props: newProps },
      };

      // We use `replace` action, then feed into `set` action, so we can also process any UI changes
      const replacedData = replaceAction(data, replaceActionData);

      // If the component has a resolveData method, we let resolveData run and handle the dispatch once it's done
      if (config.components[selectedItem!.type]?.resolveData) {
        resolveData(
          setAction(state, {
            type: "set",
            state: {
              data: { ...data, ...replacedData },
              ui: { ...ui, ...updatedUi },
            },
          }),
          {
            [fieldName]: true,
          },
        );
      } else {
        dispatch({
          type: "set",
          state: {
            data: { ...data, ...replacedData },
            ui: { ...ui, ...updatedUi },
          },
          recordHistory: true,
        });
      }
    } else {
      if (data.root.props) {
        // If the component has a resolveData method, we let resolveData run and handle the dispatch once it's done
        if (config.root?.resolveData) {
          resolveData({
            ui: { ...ui, ...updatedUi },
            data: {
              ...data,
              root: { props: newProps },
            },
          });
        } else {
          dispatch({
            type: "set",
            state: {
              ui: { ...ui, ...updatedUi },
              data: {
                ...data,
                root: { props: newProps },
              },
            },
            recordHistory: true,
          });
        }
      } else {
        // DEPRECATED ??
        dispatch({
          type: "setData",
          data: {
            root: {
              ...data.root,
              ...newProps,
            },
          },
        });
      }
    }
  };

  if (selectedItem && itemSelector) {
    const { readOnly = {} } = selectedItem;

    return (
      <AutoField
        key={`${selectedItem.props.id}_${fieldName}`}
        field={field}
        name={fieldName}
        id={`${selectedItem.props.id}_${fieldName}`}
        label={field.label}
        readOnly={readOnly[fieldName]}
        readOnlyFields={readOnly}
        value={selectedItem.props[fieldName]}
        data={selectedItem.props}
        onChange={onChange}
      />
    );
  } else {
    const { readOnly = {} } = data.root;

    return (
      <AutoField
        key={`page_${fieldName}`}
        field={field}
        name={fieldName}
        id={`root_${fieldName}`}
        label={field.label}
        readOnly={readOnly[fieldName]}
        readOnlyFields={readOnly}
        // eslint-disable-next-line
        // @ts-ignore
        value={rootProps?.[fieldName]}
        data={rootProps}
        onChange={onChange}
      />
    );
  }
};
