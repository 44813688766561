/**
 * @generated SignedSource<<0bdd815bb30c9100fb1701063bb9768f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type CourseContentQuery$variables = {
  id: string;
};
export type CourseContentQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly sections?: ReadonlyArray<{
      readonly description: any | null | undefined;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly __typename: string;
        readonly freeAccess: boolean;
        readonly id: string;
        readonly slug: string;
        readonly title: string;
      }>;
      readonly releaseDate: string | null | undefined;
      readonly title: string;
    }>;
  } | null | undefined;
};
export type CourseContentQuery = {
  response: CourseContentQuery$data;
  variables: CourseContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CourseSection",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeAccess",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "type": "CourseAcademicTerm",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "CourseAcademicTerm",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fe9ca01898f9b53d53614388a19083a",
    "id": null,
    "metadata": {},
    "name": "CourseContentQuery",
    "operationKind": "query",
    "text": "query CourseContentQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CourseAcademicTerm {\n      id\n      sections {\n        id\n        title\n        releaseDate\n        description\n        items {\n          id\n          title\n          __typename\n          slug\n          freeAccess\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "838ac2b60e935d82623f9f119dbacdeb";

export default node;
