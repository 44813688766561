"use client";

import { useOrganization } from "@/student/context/OrganizationContext";
import { Theme } from "@/website-editor/custom-fields/theme";
import React, { useState } from "react";
import { useTimer } from "react-timer-hook";
import { graphql, useMutation } from "react-relay";
import { EmailOtpLoginLoginWithOtpMutation } from "@generated/EmailOtpLoginLoginWithOtpMutation.graphql";
import { EmailOtpLoginSendLoginOtpMutation } from "@generated/EmailOtpLoginSendLoginOtpMutation.graphql";
import { z } from "zod";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSubmitButton,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { parseISO } from "date-fns";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";

const sendLoginOtpFormSchema = z.object({
  identifier: z.string().email({
    message: "Geçerli bir email adresi girin",
  }),
});

const loginWithOtpFormSchema = z.object({
  otp: z.string(),
});

const EmailOtpLogin: React.FC<{
  theme: Theme;
}> = ({ theme }) => {
  const [phase, setPhase] = useState<"sendOTP" | "login">("sendOTP");
  const [timerExpired, setTimerExpired] = useState<boolean>(false);

  const sendLoginOtpForm = useForm<z.infer<typeof sendLoginOtpFormSchema>>({
    resolver: zodResolver(sendLoginOtpFormSchema),
    mode: "onTouched",
    defaultValues: {
      identifier: "",
    },
  });

  const loginWithOtpForm = useForm<z.infer<typeof loginWithOtpFormSchema>>({
    resolver: zodResolver(loginWithOtpFormSchema),
    mode: "onTouched",
    defaultValues: {
      otp: "",
    },
  });

  const identifier = useWatch({
    control: sendLoginOtpForm.control,
    name: "identifier",
  });

  const { seconds, minutes, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => setTimerExpired(true),
  });

  const [commitSendLoginOtp, isSendLoginOtpInFlight] =
    useMutation<EmailOtpLoginSendLoginOtpMutation>(graphql`
      mutation EmailOtpLoginSendLoginOtpMutation($input: SendLoginOtpInput!) {
        sendLoginOtp(input: $input) {
          loginOtp {
            email
            channel
            validUntil
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const [commitLoginWithOtp, isLoginWithOtpInFlight] =
    useMutation<EmailOtpLoginLoginWithOtpMutation>(graphql`
      mutation EmailOtpLoginLoginWithOtpMutation($input: LoginWithOtpInput!) {
        loginWithOtp(input: $input) {
          credentials {
            accessToken
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  const organization = useOrganization();

  if (phase === "sendOTP") {
    return (
      <Form {...sendLoginOtpForm}>
        <form
          className="grid gap-6"
          onSubmit={sendLoginOtpForm.handleSubmit((values) => {
            if (isSendLoginOtpInFlight) {
              return;
            }

            commitSendLoginOtp({
              variables: {
                input: {
                  identifier: values.identifier,
                  channel: "EMAIL",
                  organizationId: organization.id,
                },
              },
              onCompleted: (mutationData) => {
                if (mutationData.sendLoginOtp.userErrors.length > 0) {
                  mutationData.sendLoginOtp.userErrors.forEach((error) => {
                    const field = error.field[1];

                    if (field === "identifier") {
                      sendLoginOtpForm.setError(field, {
                        type: "manual",
                        message: error.message,
                      });
                    } else {
                      console.error("Unexpected error:", error);
                    }
                  });

                  return;
                }

                if (
                  mutationData.sendLoginOtp.loginOtp === null ||
                  mutationData.sendLoginOtp.loginOtp === undefined
                ) {
                  console.error("sendLoginOtp.loginOtp is null");
                  return;
                }

                setPhase("login");
                setTimerExpired(false);
                restart(
                  parseISO(mutationData.sendLoginOtp.loginOtp.validUntil),
                );
              },
            });
          })}
        >
          <FormField
            control={sendLoginOtpForm.control}
            name="identifier"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Email" type="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormMessage />
          <FormSubmitButton loading={isSendLoginOtpInFlight} className="w-full">
            Giriş Kodu Gönder
          </FormSubmitButton>
        </form>
      </Form>
    );
  }

  const onSubmitLogin = loginWithOtpForm.handleSubmit((values) => {
    if (isLoginWithOtpInFlight) {
      return;
    }

    commitLoginWithOtp({
      variables: {
        input: {
          identifier: identifier,
          channel: "EMAIL",
          organizationId: organization.id,
          otp: values.otp,
        },
      },
      onCompleted: (mutationData) => {
        if (mutationData.loginWithOtp.userErrors.length > 0) {
          mutationData.loginWithOtp.userErrors.forEach((error) => {
            const field = error.field[1];

            if (field === "otp") {
              loginWithOtpForm.setError(field, {
                type: "manual",
                message: error.message,
              });
            } else {
              console.error("Unexpected error:", error);
            }
          });

          return;
        }

        if (
          mutationData.loginWithOtp.credentials === null ||
          mutationData.loginWithOtp.credentials === undefined
        ) {
          console.error("loginWithOtp.credentials is null");
          return;
        }

        // refresh the page to get the new token
        window.location.reload();
      },
    });
  });

  return (
    <Form {...loginWithOtpForm}>
      <form className="grid" onSubmit={onSubmitLogin}>
        <FormField
          control={loginWithOtpForm.control}
          name="otp"
          key="otp"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="mb-4 block text-center">
                Giriş Kodu
              </FormLabel>
              <FormControl>
                <InputOTP
                  className="mx-auto"
                  maxLength={6}
                  onComplete={onSubmitLogin}
                  {...field}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormMessage />
        <FormSubmitButton
          className="mt-6 w-full"
          loading={isLoginWithOtpInFlight}
          disabled={timerExpired}
        >
          Giriş Yap
        </FormSubmitButton>
        <Button
          className="mt-2 w-full tabular-nums"
          disabled={!timerExpired}
          onClick={() => setPhase("sendOTP")}
          variant="outline"
        >
          {timerExpired
            ? "Kodu tekrar gönder"
            : `Kodu tekrar gönder: ${minutes}:${seconds.toString().padStart(2, "0")}`}
        </Button>
      </form>
    </Form>
  );
};

export default EmailOtpLogin;
