import { cva } from "class-variance-authority";

export type Brightness = 50 | 75 | 90 | 95 | 100 | 105 | 110 | 125 | 150 | 200;

export const BrightnessField = {
  type: "select" as const,
  label: "Arkaplan Aydınlığı",
  options: [
    {
      value: 50,
      label: "En Koyu (%50)",
    },
    {
      value: 75,
      label: "Koyu (%75)",
    },
    {
      value: 90,
      label: "Orta Koyu (%90)",
    },
    {
      value: 95,
      label: "Hafif Koyu (%95)",
    },
    {
      value: 100,
      label: "Normal (%100)",
    },
    {
      value: 105,
      label: "Hafif Açık (%105)",
    },
    {
      value: 110,
      label: "Orta Açık (%110)",
    },
    {
      value: 125,
      label: "Açık (%125)",
    },
    {
      value: 150,
      label: "Çok Açık (%150)",
    },
    {
      value: 200,
      label: "En Açık (%200)",
    },
  ],
};

export const brightnessVariants = cva(undefined, {
  variants: {
    brightness: {
      50: "brightness-50",
      75: "brightness-75",
      90: "brightness-90",
      95: "brightness-95",
      100: "brightness-100",
      105: "brightness-105",
      110: "brightness-110",
      125: "brightness-125",
      150: "brightness-150",
      200: "brightness-200",
    },
  },
  defaultVariants: {
    brightness: 100,
  },
});
