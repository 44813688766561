"use client";

import { graphql, useLazyLoadQuery } from "react-relay";
import { FreeCourseContentDialogQuery } from "@generated/FreeCourseContentDialogQuery.graphql";
import VideoCourseItem from "@/courses/components/VideoCourseItem";
import QuestionCourseItem from "@/courses/components/QuestionCourseItem";
import QuizCourseItem from "@/courses/quiz/components/QuizCourseItem";
import ArticleCourseItem from "@/courses/components/ArticleCourseItem";
import ExamCourseItem from "@/courses/exam/components/ExamCourseItem";
import EmbedCourseItem from "@/courses/components/EmbedCourseItem";
import DocumentCourseItem from "@/courses/components/DocumentCourseItem";
import AssignmentCourseItem from "@/courses/assignment/components/AssignmentCourseItem";
import { WrenchIcon } from "lucide-react";

const FreeCourseContentDialog: React.FC<{
  itemId: string;
}> = ({ itemId }) => {
  const data = useLazyLoadQuery<FreeCourseContentDialogQuery>(
    graphql`
      query FreeCourseContentDialogQuery($id: ID!) {
        node(id: $id) {
          ... on CourseItem {
            id
            __typename
            ... on VideoCourseItem {
              ...VideoCourseItem
            }
            ... on QuestionCourseItem {
              ...QuestionCourseItem
            }
            ... on QuizCourseItem {
              ...QuizCourseItem
            }
            ... on ArticleCourseItem {
              ...ArticleCourseItem
            }
            ... on ExamCourseItem {
              ...ExamCourseItem
            }
            ... on EmbedCourseItem {
              ...EmbedCourseItem
            }
            ... on DocumentCourseItem {
              ...DocumentCourseItem
            }
            ... on AssignmentCourseItem {
              ...AssignmentCourseItem
            }
          }
        }
      }
    `,
    { id: itemId },
  );

  if (!data) {
    return <p>No data</p>;
  }

  if (!data.node) {
    return <p>No node</p>;
  }

  if (data.node.__typename === "VideoCourseItem") {
    return (
      <VideoCourseItem
        item={data.node}
        mode="store"
        courseContentsSide="NONE"
      />
    );
  }

  if (data.node.__typename === "QuestionCourseItem") {
    return (
      <QuestionCourseItem
        item={data.node}
        mode="store"
        courseContentsSide="NONE"
      />
    );
  }

  if (data.node.__typename === "QuizCourseItem") {
    return (
      <QuizCourseItem item={data.node} mode="store" courseContentsSide="NONE" />
    );
  }

  if (data.node.__typename === "ArticleCourseItem") {
    return (
      <ArticleCourseItem
        item={data.node}
        mode="store"
        courseContentsSide="NONE"
      />
    );
  }

  if (data.node.__typename === "ExamCourseItem") {
    return (
      <ExamCourseItem item={data.node} mode="store" courseContentsSide="NONE" />
    );
  }

  if (data.node.__typename === "EmbedCourseItem") {
    return (
      <EmbedCourseItem
        item={data.node}
        mode="store"
        courseContentsSide="NONE"
      />
    );
  }

  if (data.node.__typename === "DocumentCourseItem") {
    return (
      <DocumentCourseItem
        item={data.node}
        mode="store"
        courseContentsSide="NONE"
      />
    );
  }

  if (data.node.__typename === "AssignmentCourseItem") {
    return (
      <AssignmentCourseItem
        item={data.node}
        mode="store"
        courseContentsSide="NONE"
      />
    );
  }

  // TODO: error fırlat, event fırlat
  return (
    <div className="px-4 pt-4 text-center sm:pl-2 sm:pr-6 sm:pt-8 lg:pr-8">
      <WrenchIcon className="mx-auto size-12 text-primary" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        Böyle bir şey görmemeliydiniz.
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Lütfen eğitmeninizle iletişime geçin.
      </p>
    </div>
  );
};

export default FreeCourseContentDialog;
