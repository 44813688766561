import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import {
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import PlateRenderer from "@/components/plate-renderer";
import { PlateCustomField } from "@/website-editor/custom-fields/plate";

export type PlateProps = {
  sectionId?: string;
  theme: Theme;
  value: any;
  toc?: boolean;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const Plate = {
  displayName: "Zengin Metin",
  tooltip:
    "Zengin Metin ile görsel ve yazı içeriklerini bir arada kullanabilirsiniz. Blog yazıları, ürün tanıtımları ve daha fazlası için idealdir.",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    value: PlateCustomField,
    toc: {
      type: "radio",
      label: "İçindekiler Tablosu",
      options: [
        { label: "Göster", value: true },
        { label: "Gizle", value: false },
      ],
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      fields: ["sectionId", "value", "toc", "theme"],
      defaultExpanded: true,
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"],
    },
  },
  defaultProps: {
    theme: "light",
    toc: false,
    value: null,
    sectionId: "",
    paddingLeft: "lg",
    paddingTop: "2xl",
    paddingRight: "lg",
    paddingBottom: "2xl",
  },
  render: ({
    id,
    puck: { renderDropZone },
    value,
    toc = false,
    sectionId,
    theme,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }) => {
    return (
      <div
        className={cn(
          "relative isolate overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
        id={sectionId || undefined}
      >
        <div className={cn("mx-auto max-w-7xl", textColorVariants({ theme }))}>
          <PlateRenderer
            value={value}
            withToc={toc}
            childrenBelowToc={
              toc ? renderDropZone({ zone: "below-toc" }) : null
            }
          />
        </div>
      </div>
    );
  },
} satisfies ComponentConfig<PlateProps>;
