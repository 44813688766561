const getPiHref = (path: string) => {
  if (path.startsWith("//") || !path.startsWith("/")) {
    return path;
  }

  const host = globalThis.location?.host;

  if (
    !host ||
    host.includes("localhost") ||
    host === "pi.education" ||
    host === "www.pi.education"
  ) {
    return path;
  }

  // we are either on a subdomain or a custom domain, remove the organization slug (first path segment) from the href
  const [_, __, ...rest] = path.split("/");

  return `/${rest.join("/")}`;
};

export default getPiHref;
