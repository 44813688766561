import { ComponentConfig } from "@/website-editor/core";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { cn, hsv2hex } from "@/lib/utils";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import React from "react";
import { nanoid } from "nanoid/non-secure";
import { StickyScroll } from "@/components/ui/sticky-scroll-reveal";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { ColorCustomField } from "@/website-editor/custom-fields/color";

export type StickyScrollRevealSectionProps = {
  sectionId?: string;
  theme: Theme;
  harmony: HarmonyValue;
  sections: Array<{
    id: string;
    startColor: string;
    endColor: string;
    image: {
      url: string;
      alt?: string;
    } | null;
    videoUrl: string;
  }>;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const StickyScrollRevealSection = {
  displayName: "Sticky Scroll Reveal Section",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    sections: {
      type: "array",
      label: "Bölümler",
      getItemSummary: (item, idx) => `Bölüm ${(idx ?? 0) + 1}`,
      arrayFields: {
        id: {
          type: "custom",
          label: "ID",
          render: () => <></>,
        },
        image: {
          ...ImageCustomField,
          label: "Fotoğraf",
        },
        videoUrl: {
          type: "text",
          label: "Video URL (Döngüde ve sessiz olarak tekrarlanır)",
        },
        startColor: {
          ...ColorCustomField,
          label: "Arkaplan Sol Üst Başlangıç Rengi",
        },
        endColor: {
          ...ColorCustomField,
          label: "Arkaplan Sağ Alt Bitiş Rengi",
        },
      },
      getDefaultItemProps: () => ({
        id: `Section-${nanoid()}`,
        image: null,
        videoUrl: "",
        startColor: "#ff80b5",
        endColor: "#9089fc",
      }),
    },
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  categories: {
    core: {
      title: "Genel",
      fields: ["sectionId", "sections", "theme"],
      defaultExpanded: true,
    },
    background: {
      title: "Arkaplan",
      defaultExpanded: false,
      fields: ["harmony"],
    },
    spacing: {
      title: "Boşluklar",
      defaultExpanded: false,
      fields: ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"],
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    sections: [],
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingTop: "2xl",
    paddingBottom: "2xl",
    harmony: [],
  },
  render: ({
    puck: { renderDropZone },
    id,
    sectionId,
    sections,
    paddingLeft = "lg",
    paddingRight = "lg",
    paddingTop = "2xl",
    paddingBottom = "2xl",
    theme = "light",
    harmony = [],
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
            "bg-background": harmony.length === 0,
          },
        )}
        style={
          harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      >
        <div
          className={cn(
            "mx-auto max-w-7xl",
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
          )}
        >
          <StickyScroll
            id={id}
            sections={sections.map((section) => ({
              id: section.id,
              content: renderDropZone({
                zone: `${section.id}-content`,
                className: "w-full",
              }),
              image: section.image,
              videoUrl: section.videoUrl,
              startColor: section.startColor,
              endColor: section.endColor,
            }))}
          />
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<StickyScrollRevealSectionProps>;
