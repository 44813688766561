import { Data } from "@/website-editor/core";
import isEqual from "react-fast-compare";

const volatileInternalKeys = new Set(["__preloadedQuery"]);

function structuredCloneIgnoreFunctionsAndRemoveVolatileInternalKeys<T>(
  value: T,
): T {
  if (typeof value === "function") {
    return undefined as any;
  }

  if (typeof value !== "object" || value === null) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map(
      structuredCloneIgnoreFunctionsAndRemoveVolatileInternalKeys,
    ) as any;
  }

  const cloned: Record<string, any> = {};

  for (const key in value) {
    if (volatileInternalKeys.has(key)) {
      continue;
    }

    cloned[key] = structuredCloneIgnoreFunctionsAndRemoveVolatileInternalKeys(
      value[key],
    );
  }

  return cloned as T;
}

/**
 * Returns a deeply cloned object with volatile internal keys removed.
 * @param data - The data to remove volatile internal keys from.
 */
export function removeVolatileInternalKeysFromData(data: Data): Data {
  const newData =
    structuredCloneIgnoreFunctionsAndRemoveVolatileInternalKeys(data);

  return newData;
}

export function isZonesEqualWithoutVolatileInternalKeys(
  existingData: Data | Record<string, any>,
  newData: Data,
): boolean {
  const newDataKeys = Object.keys(newData.zones ?? {});

  if (newDataKeys.length === 0) {
    return true;
  }

  // ignore existing zones that are not in the new data by purpose
  return newDataKeys.every((zoneKey) => {
    const existingZone = existingData.zones?.[zoneKey];
    const newZone = newData.zones?.[zoneKey];

    return isEqualWithoutVolatileInternalKeys(existingZone, newZone);
  });
}

export function isEqualWithoutVolatileInternalKeys<B>(
  a: unknown,
  b: B,
): a is B {
  if (
    typeof a !== "object" ||
    typeof b !== "object" ||
    a === null ||
    b === null
  ) {
    return isEqual(a, b);
  }

  if (Array.isArray(a) || Array.isArray(b)) {
    if (!Array.isArray(a) || !Array.isArray(b)) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    return a.every((value, index) =>
      isEqualWithoutVolatileInternalKeys(value, b[index]),
    );
  }

  const keys = Array.from(new Set([...Object.keys(a), ...Object.keys(b)]));

  for (const key of keys) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (volatileInternalKeys.has(key) && b[key] && a[key]) {
      // && b[key] && a[key] checkini koymayınca data resolve olduktan sonra ui güncellenmiyor
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isEqualWithoutVolatileInternalKeys(a[key], b[key])) {
      return false;
    }
  }

  return true;
}
