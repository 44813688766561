"use client";

import styles from "./styles.module.css";
import getClassNameFactory from "../../lib/get-class-name-factory";
import { ReactNode, useEffect } from "react";
import { useAppContext } from "../Puck/context";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Drawer } from "../Drawer";
import { loadQuery, useRelayEnvironment } from "react-relay";
import { InsertDialogComponentTemplatesQuery } from "@/website-editor/core/components/InsertDialog";
import { useOrganization } from "@/student/context/OrganizationContext";

const getClassName = getClassNameFactory("ComponentList", styles);

const ComponentListItem = ({
  name,
  index,
}: {
  name: string;
  index: number;
}) => {
  const environment = useRelayEnvironment();
  const { slug } = useOrganization();

  useEffect(() => {
    loadQuery(
      environment,
      InsertDialogComponentTemplatesQuery,
      { slug, component: name },
      { fetchPolicy: "store-or-network" },
    );
  }, [environment, name, slug]);

  return <Drawer.Item name={name} index={index} />;
};

const ComponentList = ({
  children,
  title,
  id,
}: {
  id: string;
  children?: ReactNode;
  title?: string;
}) => {
  const { config, state, setUi } = useAppContext();

  const { expanded = true } = state.ui.componentList[id] || {};

  return (
    <div className={getClassName({ isExpanded: expanded })}>
      {title && (
        <button
          className={getClassName("title")}
          onClick={() =>
            setUi({
              componentList: {
                ...state.ui.componentList,
                [id]: {
                  ...state.ui.componentList[id],
                  expanded: !expanded,
                },
              },
            })
          }
          title={
            expanded
              ? `Collapse${title ? ` ${title}` : ""}`
              : `Expand${title ? ` ${title}` : ""}`
          }
        >
          <div>{title}</div>
          <div className={getClassName("titleIcon")}>
            {expanded ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
          </div>
        </button>
      )}
      <div className={getClassName("content")}>
        <Drawer droppableId={title}>
          {children ||
            Object.keys(config.components).map((componentKey, i) => {
              return (
                <ComponentListItem
                  key={componentKey}
                  name={componentKey}
                  index={i}
                />
              );
            })}
        </Drawer>
      </div>
    </div>
  );
};

ComponentList.Item = ComponentListItem;

export { ComponentList };
