import { ComponentConfig } from "@/website-editor/core";
import {
  mutedTextColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { cn } from "@/lib/utils";
import Image from "next/image";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import {
  JustifyContent,
  JustifyContentField,
  justifyContentVariants,
} from "@/website-editor/custom-fields/justifyContent";
import {
  AlignItems,
  AlignItemsField,
  alignItemsVariants,
} from "@/website-editor/custom-fields/alignItems";

export type ContainerSectionProps = {
  sectionId?: string;
  theme: Theme;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  containerPosition: "vertical" | "horizontal";
  justifyContent: JustifyContent;
  alignItems: AlignItems;
  maxWidth: "7xl" | "none";
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const ContainerSection = {
  displayName: "Konteyner",
  tooltip:
    "Bu bloğu kullanarak içerikleri yan yana veya alt alta sıralayabilirsiniz ve arkaplan resmi ekleyebilirsiniz.",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    containerPosition: {
      type: "radio",
      label: "Konteyner Yönü",
      options: [
        { value: "vertical", label: "Dikey" },
        { value: "horizontal", label: "Yatay" },
      ],
    },
    justifyContent: JustifyContentField,
    alignItems: AlignItemsField,
    maxWidth: {
      type: "radio",
      label: "Maksimum Genişliği Sınırla",
      options: [
        {
          value: "7xl",
          label: "Evet",
        },
        {
          value: "none",
          label: "Hayır",
        },
      ],
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    backgroundImage: null,
    containerPosition: "vertical",
    justifyContent: "center",
    alignItems: "start",
    maxWidth: "7xl",
    paddingTop: "lg",
    paddingBottom: "lg",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    containerPosition,
    justifyContent,
    alignItems,
    maxWidth,
    theme,
    backgroundImage,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn("relative isolate overflow-clip bg-background", {
          "overflow-clip": Boolean(backgroundImage),
          dark: theme === "dark",
        })}
      >
        {backgroundImage && (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
              mutedTextColorVariants({ theme }),
            )}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        )}
        <div
          className={cn(
            "relative",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            paddingVariants({ side: "left", size: marginLeft }),
            paddingVariants({ side: "right", size: marginRight }),
            {
              "max-w-7xl mx-auto": maxWidth !== "none",
            },
          )}
        >
          {renderDropZone({
            zone: "content",
            className: cn(
              "flex",
              justifyContentVariants({ justifyContent }),
              alignItemsVariants({ alignItems }),
              {
                "min-h-0 w-full min-w-0 flex-col":
                  containerPosition === "vertical",
                "flex-row": containerPosition === "horizontal",
              },
            ),
            direction:
              containerPosition === "vertical" ? "vertical" : "horizontal",
          })}
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<ContainerSectionProps>;
