import { Config, Data, RootDataWithProps } from "../types/Config";
import { getChanged } from "./get-changed";
export const resolveRootData = async (data: Data, config: Config) => {
  if (config.root?.resolveData && data.root.props) {
    const changed = getChanged(data.root, {});

    const rootWithProps = data.root as RootDataWithProps;

    const resolvedRoot = await config.root?.resolveData(rootWithProps, {
      changed,
      lastData: {},
    });

    return {
      ...data.root,
      ...resolvedRoot,
      props: {
        ...data.root.props,
        ...resolvedRoot.props,
      },
    };
  }

  return data.root;
};
