/**
 * @generated SignedSource<<b16a5e52865fda851a5059a297bb9470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type InsertDialogComponentTemplatesQuery$variables = {
  component: string;
  slug: string;
};
export type InsertDialogComponentTemplatesQuery$data = {
  readonly organization: {
    readonly webPageComponentTemplates: ReadonlyArray<{
      readonly content: Record<string, any>;
      readonly id: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"WebPageComponentTemplate">;
    }>;
  } | null | undefined;
  readonly publicWebPageComponentTemplates: ReadonlyArray<{
    readonly content: Record<string, any>;
    readonly id: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"WebPageComponentTemplate">;
  }>;
};
export type InsertDialogComponentTemplatesQuery = {
  response: InsertDialogComponentTemplatesQuery$data;
  variables: InsertDialogComponentTemplatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "component"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "component",
    "variableName": "component"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "WebPageComponentTemplate"
  }
],
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "component",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isPublicTemplate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertDialogComponentTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "WebPageComponentTemplate",
            "kind": "LinkedField",
            "name": "webPageComponentTemplates",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "WebPageComponentTemplate",
        "kind": "LinkedField",
        "name": "publicWebPageComponentTemplates",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InsertDialogComponentTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "WebPageComponentTemplate",
            "kind": "LinkedField",
            "name": "webPageComponentTemplates",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "WebPageComponentTemplate",
        "kind": "LinkedField",
        "name": "publicWebPageComponentTemplates",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db2a225cd3a6bad8583436749d3b22ff",
    "id": null,
    "metadata": {},
    "name": "InsertDialogComponentTemplatesQuery",
    "operationKind": "query",
    "text": "query InsertDialogComponentTemplatesQuery(\n  $slug: String!\n  $component: String!\n) {\n  organization(slug: $slug) {\n    webPageComponentTemplates(component: $component) {\n      id\n      title\n      content\n      ...WebPageComponentTemplate\n    }\n    id\n  }\n  publicWebPageComponentTemplates(component: $component) {\n    id\n    title\n    content\n    ...WebPageComponentTemplate\n  }\n}\n\nfragment WebPageComponentTemplate on WebPageComponentTemplate {\n  id\n  title\n  content\n  component\n  description\n  isPublicTemplate\n}\n"
  }
};
})();

(node as any).hash = "d095c21d7cacb04d775b6f79709b161c";

export default node;
