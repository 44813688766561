"use client";

import ErrorBoundary from "@/components/ErrorBoundary";
import PuckRender from "@/website-editor/render";
import React, { useMemo } from "react";
import { usePuck } from "@/website-editor/core";
import { generateId } from "@/website-editor/core/lib/generate-id";
import { cn } from "@/lib/utils";

const DefaultComponentTemplate: React.FC<{
  componentType: string;
  className?: string;
}> = ({ componentType, className }) => {
  const { config } = usePuck();

  const defaultComponentData = useMemo(() => {
    return {
      root: {},
      content: [
        {
          type: componentType,
          props: {
            ...(config.components[componentType]?.defaultProps || {}),
            ...(config.components[componentType]?.displayDefaultPropOverrides ||
              {}),
            id: generateId(componentType),
          },
        },
      ],
      zones: {},
    };
  }, [componentType]);

  return (
    <ErrorBoundary
      fallback={
        <div>Bu web sayfası hata alıyor. Destek ile iletişime geçin.</div>
      }
    >
      <div
        className={cn("pointer-events-none", className)}
        style={{
          // https://stackoverflow.com/a/15885486
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "-webkit-transform": "translateZ(0)",
        }}
      >
        <PuckRender data={defaultComponentData} />
      </div>
    </ErrorBoundary>
  );
};

export default DefaultComponentTemplate;
