import * as React from "react";
import {
  TextCentering,
  TextCenteringField,
  textCenteringVariants,
} from "@/website-editor/custom-fields/textCentering";
import {
  FontSize,
  FontSizeField,
  fontSizeVariants,
} from "@/website-editor/custom-fields/fontSize";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import {
  mutedTextColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  FontWeight,
  FontWeightField,
  fontWeightVariants,
} from "@/website-editor/custom-fields/fontWeight";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type ParagraphProps = {
  sectionId?: string;
  paragraph: string;
  fontSize: FontSize;
  fontWeight: FontWeight;
  centering: TextCentering;
  theme: Theme;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Paragraph = {
  displayName: "Paragraf",
  fields: {
    sectionId: SectionIdField,
    paragraph: {
      type: "richtextarea",
      label: "Paragraf",
    },
    fontSize: FontSizeField,
    fontWeight: FontWeightField,
    centering: {
      ...TextCenteringField,
      label: "Yazı Ortalama",
    },
    theme: ThemeField,
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    paragraph:
      "We choose to go to the Moon in this decade and do the other things, not because they are easy, but because they are hard; because that goal will serve to organize and measure the best of our energies and skills, because that challenge is one that we are willing to accept, one we are unwilling to postpone, and one we intend to win, and the others, too.",
    fontSize: "lg",
    fontWeight: "normal",
    centering: "left",
    theme: "light",
    paddingTop: "sm",
    paddingBottom: "sm",
    marginLeft: "md",
    marginRight: "sm",
  },
  render: ({
    sectionId,
    paragraph,
    fontSize,
    fontWeight,
    centering,
    theme,
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn("relative isolate overflow-clip bg-background", {
          dark: theme === "dark",
        })}
      >
        <div
          className={cn(
            "relative isolate overflow-clip",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            paddingVariants({ side: "left", size: marginLeft }),
            paddingVariants({ side: "right", size: marginRight }),
          )}
        >
          <div
            className={cn(
              textCenteringVariants({ centering }),
              fontSizeVariants({ fontSize }),
              fontWeightVariants({ fontWeight }),
              mutedTextColorVariants({ theme }),
              "flex-wrap whitespace-pre-line break-words",
            )}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<ParagraphProps>;
