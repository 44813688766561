import { ComponentConfig } from "@/website-editor/core";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { cn } from "@/lib/utils";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import { Icon, iconOptions } from "@/website-editor/custom-fields/icon";
import { Button as ButtonComponent } from "@/components/ui/button";
import PiLink from "@/components/PiLink";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type PricingSectionProps = {
  sectionId?: string;
  title: string;
  description: string;
  checkList: Array<{ description: string; icon?: string }>;
  price: string;
  url: string;
  slogan: string;
  currency: string;
  theme: Theme;
  courseDescription: string;
  pricingDescription: string;
  buttonDescription: string;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const PricingSection = {
  displayName: "Fiyatlandırma Bölümü",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    slogan: {
      type: "richtextarea",
      label: "Slogan",
    },
    url: { type: "text", label: "URL" },
    theme: ThemeField,
    price: {
      type: "text",
      label: "Fiyat",
    },
    currency: {
      type: "text",
      label: "Para Birimi",
    },
    pricingDescription: {
      type: "richtextarea",
      label: "Fiyat Açıklaması",
    },
    courseDescription: {
      type: "richtextarea",
      label: "Kurs Açıklaması",
    },
    buttonDescription: {
      type: "textarea",
      label: "Satın Al Butonu Açıklaması",
    },
    checkList: {
      type: "array",
      label: "Özellikler",
      getItemSummary: (item, idx) =>
        item.description || `Özellik ${(idx ?? 0) + 1}`,
      arrayFields: {
        description: {
          type: "richtextarea",
          label: "Özellik Açıklaması",
        },
        icon: {
          type: "select",
          label: "İkon",
          options: iconOptions,
        },
      },
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    url: "#",
    price: "999",
    theme: "light",
    title: "Fiyatlandırma",
    slogan: "Hayat Boyu Erişim",
    currency: "₺",
    checkList: [
      { icon: "MagicWandIcon", description: "İnteraktif Sorular" },
      { icon: "MagicWandIcon", description: "Eğitici Simülasyonlar" },
      { icon: "MagicWandIcon", description: "Pratik Analizler" },
    ],
    paddingTop: "xl",
    description: "Finansal özgürlük yolculuğu bir sprint değil maratondur",
    paddingBottom: "lg",
    buttonDescription: "Hemen Kaydol",
    courseDescription:
      "Borsa bölümümüzde, finansal okuryazarlığı eğlenceli ve kazançlı bir yolculuğa dönüştürüyoruz! Bu bölümde, hisse senetleri, tahviller ve endeksler gibi finansal konuları keşfederken, borsa dünyasının karmaşıklığından sıyrılacaksınız. Ayrıca fiyatlandırma bölümümüzde yatırım stratejilerinizi geliştirmenin yöntemlerini de öğreneceksiniz. Finansal okuryazarlıkta borsa dünyasının kapılarını aralayın, mali hedeflerinizi belirleyin ve kazançlı bir geleceğe adım atmak için kazanç dolu bir yolculuğa başlayın!",
    pricingDescription: "KDV Dahildir",
  },
  render: ({
    sectionId,
    title,
    description,
    checkList,
    theme,
    slogan,
    courseDescription,
    paddingTop,
    paddingBottom,
    buttonDescription,
    url,
    price,
    currency,
    pricingDescription,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2
              className={cn(
                "text-3xl font-bold tracking-tight sm:text-4xl break-words whitespace-pre-line",
                textColorVariants({ theme }),
              )}
            >
              {title}
            </h2>
            <div
              className={cn(
                "mt-6 text-lg leading-8 whitespace-pre-line break-words",
                textColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <div
                className={cn(
                  "text-2xl font-bold tracking-tight whitespace-pre-line break-words",
                  textColorVariants({ theme }),
                )}
                dangerouslySetInnerHTML={{ __html: slogan }}
              />
              <div
                className={cn(
                  "mt-6 text-base leading-7 whitespace-pre-line break-words",
                  textColorVariants({ theme }),
                )}
                dangerouslySetInnerHTML={{ __html: courseDescription }}
              />
              <div className="mt-10 flex items-center gap-x-4">
                <h4
                  className={cn(
                    "flex-none text-sm font-semibold leading-6",
                    textColorVariants({ theme }),
                  )}
                >
                  Eğitim İçeriğinde Seni Neler Bekliyor
                </h4>
                <div className="h-px flex-auto" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6  sm:grid-cols-2 sm:gap-6"
              >
                {checkList.map((check, idx) => (
                  <li key={idx} className="flex gap-x-3">
                    <Icon
                      icon={check.icon}
                      className={cn(
                        "h-6 w-5 flex-none",
                        mutedTextColorVariants({ theme }),
                      )}
                    />
                    <span
                      className={cn(
                        "whitespace-pre-line break-words",
                        textColorVariants({ theme }),
                      )}
                      dangerouslySetInnerHTML={{ __html: check.description }}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:shrink-0">
              <div
                className={cn(
                  "rounded-2xl py-10 text-center h-full ring-1 ring-inset lg:flex lg:flex-col lg:justify-center lg:py-16",
                  {
                    "bg-gray-50 ring-gray-900/5": theme === "light",
                    "bg-gray-800 ring-gray-50/5": theme === "dark",
                  },
                )}
              >
                <div className="mx-auto max-w-xs px-8">
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span
                      className={cn(
                        "text-5xl font-bold tracking-tight",
                        textColorVariants({ theme }),
                      )}
                    >
                      {price}
                    </span>
                    <span
                      className={cn(
                        "text-4xl font-bold leading-6 tracking-wide",
                        textColorVariants({ theme }),
                      )}
                    >
                      {currency}
                    </span>
                  </p>
                  <ButtonComponent asChild variant={"default"} size={"lg"}>
                    <PiLink
                      href={url}
                      className="mt-10 block w-full whitespace-pre-line break-words rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    >
                      {buttonDescription}
                    </PiLink>
                  </ButtonComponent>
                  <div
                    className={cn(
                      "mt-6 text-xs leading-5 whitespace-pre-line break-words",
                      textColorVariants({ theme }),
                    )}
                    dangerouslySetInnerHTML={{ __html: pricingDescription }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<PricingSectionProps>;
