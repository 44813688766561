import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import Image from "next/image";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import {
  HarmonyCustomField,
  HarmonyValue,
} from "@/website-editor/custom-fields/harmony";
import { cn, hsv2hex } from "@/lib/utils";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import BackgroundGradient from "@/components/background-gradient";
import React from "react";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";

export type InstructorSectionProps = {
  sectionId?: string;
  title: string;
  instructor: string;
  description: string;
  theme: Theme;
  harmony: HarmonyValue;
  customColorIsGradient: boolean;
  logoRoundness: Rounded;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  image: {
    url: string;
    alt?: string;
  };
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const InstructorSection = {
  displayName: "Eğitmen Bölümü",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    instructor: {
      type: "textarea",
      label: "Eğitmen",
    },
    image: {
      ...ImageCustomField,
      label: "Eğitmen Resmi",
    },
    logoRoundness: {
      ...RoundedField,
      label: "Logo Yuvarlaklığı",
    },
    theme: ThemeField,
    harmony: {
      ...HarmonyCustomField,
      label: "Arkaplan Rengi",
    },
    customColorIsGradient: {
      type: "radio",
      label: "Arkaplan Rengi Tarzı",
      options: [
        {
          value: true,
          label: "Gradient",
        },
        {
          value: false,
          label: "Solid",
        },
      ],
    },
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "egitmen",
    title: "Amaca Yönelik Finansal Okuryazarlık",
    instructor: "Deniz Hayal",
    theme: "light",
    harmony: [],
    customColorIsGradient: true,
    description:
      "Ekonomi bölümü mezunu ve Finans Uzmanlaşması yapmış biri olarak, hem çevremdeki insanlar hem de ilk mezun olduğumda kendi adıma ne kadar pratik bilgi eksikliği olduğunu hissettiğimi hatırlıyorum. Merak ettiğim ve ihtiyacım olan konularda işime yaracak bilgiler bulmak için çok fazla enerji ve zaman harcardım. Teknolojinin gücünü kullanarak kendi platformumda, istediğim konuları çeşitli ölçülebilir metotlarla, tamamen özgürce aktarabildiğim için çok keyifli ve faydalı işler ortaya çıkarıyoruz.",
    image: {
      url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/snaol6ueeyug4r0ps3zr4980/deniz+hayal.jpg",
    },
    logoRoundness: "sm",
    backgroundImage: null,
    paddingTop: "xl",
    paddingLeft: "lg",
    paddingRight: "lg",
    paddingBottom: "md",
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    image,
    title,
    description,
    instructor,
    theme,
    harmony = [],
    customColorIsGradient = false,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    backgroundImage,
    logoRoundness,
    id,
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "relative isolate overflow-clip scroll-mt-14 pb-3 pt-8 sm:scroll-mt-32 sm:pb-16 sm:pt-10 lg:pt-1",
          {
            "overflow-clip": Boolean(backgroundImage),
            dark: theme === "dark",
            "bg-background": customColorIsGradient || harmony.length === 0,
          },
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
        style={
          !customColorIsGradient && harmony[0]
            ? {
                backgroundColor: hsv2hex(
                  harmony[0].hue,
                  harmony[0].saturation,
                  harmony[0].value,
                ),
              }
            : undefined
        }
      >
        {backgroundImage ? (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
            )}
          />
        ) : customColorIsGradient && harmony.length > 0 ? (
          <BackgroundGradient
            id={sectionId ? sectionId : ""}
            harmony={harmony}
          />
        ) : null}
        <div className="absolute inset-x-0 bottom-0 top-1/2 text-slate-900/10 [mask-image:linear-gradient(transparent,white)]">
          <svg aria-hidden="true" className="absolute inset-0 size-full">
            <defs>
              <pattern
                id={`pattern-${id}`}
                width="128"
                height="128"
                patternUnits="userSpaceOnUse"
                x="50%"
                y="100%"
              >
                <path d="M0 128V.5H128" fill="none" stroke="currentColor" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill={`url(#pattern-${id})`} />
          </svg>
        </div>
        <div className="relative mx-auto max-w-5xl pt-16 sm:px-6">
          <div className="pt-px sm:rounded-3xl">
            <div className="relative mx-auto -mt-16 size-44 text-clip rounded-full bg-slate-200 md:float-right md:size-64 md:[shape-outside:circle(40%)] lg:mr-20 lg:size-72">
              <Image
                className={cn(
                  "absolute inset-0 size-full object-cover",
                  roundedVariants({
                    rounded: logoRoundness,
                  }),
                )}
                src={image.url}
                alt="instructor"
                fill
                unoptimized={!image.url.includes(".pi.education/")}
                sizes="(min-width: 1024px) 18rem, (min-width: 768px) 16rem, 11rem"
              />
            </div>
            <div className="px-4 py-10 sm:px-10 sm:py-16 md:py-20 lg:px-20 lg:py-32">
              <p className="mt-8 whitespace-pre-line break-words text-5xl font-extrabold tracking-tight text-slate-900 sm:text-6xl">
                <span className="block whitespace-pre-line break-words text-primary">
                  {instructor} –
                </span>{" "}
                {title}
              </p>
              <div
                className="mt-4 whitespace-pre-line break-words text-lg tracking-tight text-slate-700"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<InstructorSectionProps>;
