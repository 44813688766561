"use client";

import {
  Carousel as CarouselComponent,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import AutoHeight from "embla-carousel-auto-height";
import Autoplay from "embla-carousel-autoplay";
import { cn } from "@/lib/utils";
import { marginVariants } from "@/website-editor/custom-fields/margin";
import * as React from "react";
import { CarouselProps } from "@/website-editor/blocks/carousel/index";

const Carousel: React.FC<
  Required<
    Pick<
      CarouselProps,
      | "spacing"
      | "autoPlay"
      | "autoPlayDelay"
      | "autoPlayJump"
      | "arrowsPosition"
    >
  > & {
    carouselItems: React.ReactNode;
  }
> = ({
  spacing,
  carouselItems,
  autoPlay,
  autoPlayJump,
  autoPlayDelay,
  arrowsPosition,
}) => {
  return (
    <CarouselComponent
      opts={{
        align: "start",
        loop: true,
      }}
      plugins={[
        AutoHeight(),
        ...(autoPlay
          ? [
              Autoplay({
                delay: autoPlayDelay,
                jump: autoPlayJump,
              }),
            ]
          : []),
      ]}
    >
      {arrowsPosition === "above-left" ||
      arrowsPosition === "above" ||
      arrowsPosition === "above-right" ? (
        <div
          className={cn("flex pb-2 gap-1", {
            "justify-start": arrowsPosition === "above-left",
            "justify-center": arrowsPosition === "above",
            "justify-end": arrowsPosition === "above-right",
          })}
        >
          <CarouselPrevious className="static translate-y-0" />
          <CarouselNext className="static translate-y-0" />
        </div>
      ) : null}
      <CarouselContent
        className={cn(
          "items-start transition-[height] ease-in-out duration-500",
          marginVariants({
            side: "leftNegative",
            size: spacing,
          }),
        )}
      >
        {carouselItems}
      </CarouselContent>
      <CarouselPrevious
        className={cn({
          hidden: arrowsPosition !== "inside" && arrowsPosition !== "outside",
          "left-0 bg-black/20 text-white rounded-none":
            arrowsPosition === "inside",
        })}
        variant={arrowsPosition === "inside" ? "ghost" : "outline"}
      />
      <CarouselNext
        className={cn({
          hidden: arrowsPosition !== "inside" && arrowsPosition !== "outside",
          "right-0 bg-black/20 text-white rounded-none":
            arrowsPosition === "inside",
        })}
        variant={arrowsPosition === "inside" ? "ghost" : "outline"}
      />
      {arrowsPosition === "below-left" ||
      arrowsPosition === "below" ||
      arrowsPosition === "below-right" ? (
        <div
          className={cn("flex pt-2 gap-1", {
            "justify-start": arrowsPosition === "below-left",
            "justify-center": arrowsPosition === "below",
            "justify-end": arrowsPosition === "below-right",
          })}
        >
          <CarouselPrevious className="static translate-y-0" />
          <CarouselNext className="static translate-y-0" />
        </div>
      ) : null}
    </CarouselComponent>
  );
};

export default Carousel;
