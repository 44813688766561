import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import Image from "next/image";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { cn } from "@/lib/utils";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import { GlobeIcon } from "lucide-react";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import PiLink from "@/components/PiLink";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";

export type InstructorsSectionProps = {
  sectionId?: string;
  title: string;
  description: string;
  instructors: Array<{
    name: string;
    title: string;
    description: string;
    image: {
      url: string;
      alt?: string;
    } | null;
    url: string;
    imageRoundness: Rounded;
  }>;
  theme: Theme;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const InstructorsSection = {
  displayName: "Eğitmenler Bölümü",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    theme: ThemeField,
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    instructors: {
      type: "array",
      label: "Eğitmenler",
      getItemSummary: (item, idx) => item.name || `Eğitmen ${(idx ?? 0) + 1}`,
      arrayFields: {
        name: {
          type: "textarea",
          label: "İsim",
        },
        title: {
          type: "textarea",
          label: "Ünvan",
        },
        description: {
          type: "richtextarea",
          label: "Açıklama",
        },
        image: {
          ...ImageCustomField,
          label: "Resim",
        },
        url: {
          type: "text",
          label: "URL",
        },
        imageRoundness: {
          ...RoundedField,
          label: "Resim Yuvarlaklığı",
        },
      },
      defaultItemProps: {
        name: "Eğitmen Adı",
        title: "Ünvan",
        description: "Açıklama",
        image: {
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/rd97358srshmwz2axi5e4gmu/pelin.jpg",
          alt: "",
        },
        url: "#",
        imageRoundness: "full",
      },
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    title: "Finansal Okuryazarlık yolculuğunuzun ardındaki uzmanları tanıyın",
    marginLeft: "none",
    paddingTop: "xl",
    description:
      "Eğitmenler olarak finansal okuryazarlık konusundaki bilgilerimizi paylaşmanın yanında, her öğrencinin bireysel hikayesine değer veren bir misyona sahibiz. Finansal başarı, sadece rakamlarla değil, öğrencilerimizin kendi finansal hikayelerini yazma tutkusunu da içerir. \nÖğrencilerimize finansal okuryazarlık konusunda iki taraf için de heyecan verici bir yolculuğa çıkma fırsatı sunuyoruz. Makroekonomi, Finans ve Borsalar, Kripto, Strateji ve Bankacılık gibi birçok alanda hem akademide eğitmenlik hem de kurumlar eğitimi tecrübemizle eğitime hazırız.",
    instructors: [
      {
        url: "",
        name: "Pelin Patsu",
        image: {
          alt: "",
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/rd97358srshmwz2axi5e4gmu/pelin.jpg",
        },
        title: "Ana Eğitmen",
        description:
          "17 yıldır Girişimcilik ve Kişisel Finans eğitimleri veriyor. 2016 yılında Koç Üniversitesi Ekonomi ve İşletme bölümlerinden mezu noldu.",
        imageRoundness: "full",
      },
      {
        url: "",
        name: "Josephine James",
        image: {
          alt: "",
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/jvrj6z7grq2ma1u91bz482np/josephine.jpg",
        },
        title: "Eğitmen",
        description:
          "10 yıldır Kişisel Finans, Muhasebe ve Yatırım eğitimleri veriyor. 2014 yılında Northwestern Üniversitesi Ekonomi bölümünden mezun oldu.",
        imageRoundness: "full",
      },
    ],
    marginRight: "none",
    paddingBottom: "xl",
    backgroundImage: null,
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    title,
    description,
    instructors,
    theme,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    backgroundImage,
  }) => {
    return (
      <div
        id={sectionId || undefined}
        className={cn(
          "@container relative isolate overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: marginLeft }),
          paddingVariants({ side: "right", size: marginRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        {backgroundImage && (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
              mutedTextColorVariants({ theme }),
            )}
          />
        )}
        <div className="mx-auto max-w-7xl px-6 @5xl:px-8">
          <div className="mx-auto max-w-2xl @2xl:text-center">
            <h2
              className={cn(
                "text-3xl font-bold tracking-tight @2xl:text-4xl",
                textColorVariants({ theme }),
              )}
            >
              {title}
            </h2>
            <div
              className={cn(
                "mt-6 text-lg w-full",
                textColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 @5xl:max-w-4xl @5xl:gap-x-8 @7xl:max-w-none @2xl:grid-cols-4"
          >
            {instructors.map((instructor, idx) => (
              <li
                key={idx}
                className="flex flex-col gap-10 @5xl:pt-12 @2xl:col-span-2 @2xl:flex-row @2xl:pt-8 @2xl:last:odd:col-start-2"
              >
                {instructor.image && (
                  <Image
                    src={instructor.image?.url ?? ""}
                    alt={instructor.image?.alt ?? ""}
                    width={540}
                    height={540}
                    unoptimized={
                      !instructor.image?.url.includes(".pi.education/")
                    }
                    className={cn(
                      "m-auto @2xl:m-0 @2xl:flex-shrink @3xl:flex-shrink-0 size-64 @2xl:size-24 @5xl:size-64 object-cover",
                      roundedVariants({
                        rounded: instructor.imageRoundness,
                      }),
                    )}
                  />
                )}
                <div className="mt-6 flex-1 @2xl:mt-0">
                  <h3
                    className={cn(
                      "text-lg font-semibold leading-8 tracking-tight",
                      textColorVariants({ theme }),
                    )}
                  >
                    {instructor.name}
                  </h3>
                  <p
                    className={cn(
                      "text-base leading-7",
                      textColorVariants({ theme }),
                    )}
                  >
                    {instructor.title}
                  </p>
                  <div
                    className={cn(
                      "mt-6 text-base max-w-prose leading-7",
                      textColorVariants({ theme }),
                    )}
                    dangerouslySetInnerHTML={{ __html: instructor.description }}
                  />
                  {instructor.url && (
                    <ul role="list" className="mt-6 flex gap-x-6">
                      <li>
                        <PiLink
                          href={instructor.url}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <GlobeIcon size={24} />
                        </PiLink>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  },
} satisfies ComponentConfig<InstructorsSectionProps>;
