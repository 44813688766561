"use client";

import { Environment, fetchQuery } from "react-relay";
import { courseAcademicTermPuckQuery } from "@generated/courseAcademicTermPuckQuery.graphql";
import { courseAcademicTermPuckGraphqlQuery } from "@/website-editor/custom-fields/courseAcademicTermPuckQuery";

export async function courseAcademicTermFetchList({
  query,
  environment,
  organizationSlug,
}: {
  query: string;
  environment: Environment;
  organizationSlug: string;
}) {
  const response = await fetchQuery<courseAcademicTermPuckQuery>(
    environment,
    courseAcademicTermPuckGraphqlQuery,
    {
      organizationSlug,
    },
  ).toPromise();

  return (
    response?.organization?.courseAcademicTerms.edges.map((edge) => ({
      id: edge.node.id,
      title: edge.node.course.title,
    })) ?? []
  );
}
