"use client";

import { WebPageComponentTemplate$key } from "@generated/WebPageComponentTemplate.graphql";
import { graphql, useFragment } from "react-relay";
import ErrorBoundary from "@/components/ErrorBoundary";
import PuckRender from "@/website-editor/render";
import React from "react";
import { cn } from "@/lib/utils";

const WebPageComponentTemplate: React.FC<{
  webPageComponentTemplate: WebPageComponentTemplate$key;
  className?: string;
}> = ({ webPageComponentTemplate, className }) => {
  const data = useFragment(
    graphql`
      fragment WebPageComponentTemplate on WebPageComponentTemplate {
        id
        title
        content
        component
        description
        isPublicTemplate
      }
    `,
    webPageComponentTemplate,
  );

  return (
    <ErrorBoundary
      fallback={
        <div>Bu web sayfası hata alıyor. Destek ile iletişime geçin.</div>
      }
    >
      <div
        className={cn("pointer-events-none", className)}
        style={{
          // https://stackoverflow.com/a/15885486
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "-webkit-transform": "translateZ(0)",
        }}
      >
        <PuckRender
          data={{
            root: {},
            content: [data.content.component],
            zones: data.content.zones,
          }}
        />
      </div>
    </ErrorBoundary>
  );
};

export default WebPageComponentTemplate;
