import { cva } from "class-variance-authority";

export type Shadow = "sm" | "md" | "lg" | "xl" | "2xl" | "inner" | "none";

export const ShadowField = {
  type: "select" as const,
  label: "Gölge",
  options: [
    { value: "none", label: "Yok" },
    { value: "sm", label: "Hafif" },
    { value: "md", label: "Orta" },
    { value: "lg", label: "Fazla" },
    { value: "xl", label: "Çok Fazla" },
    { value: "2xl", label: "Çok çok fazla" },
    { value: "inner", label: "İçe Doğru" },
  ],
};

export const shadowVariants = cva(undefined, {
  variants: {
    shadow: {
      sm: "shadow-sm",
      md: "shadow",
      lg: "shadow-lg",
      xl: "shadow-xl",
      "2xl": "shadow-2xl",
      inner: "shadow-inner",
      none: "shadow-none",
    },
  },
  defaultVariants: {
    shadow: "none",
  },
});
