import * as React from "react";
import {
  Margin,
  MarginField,
  marginVariants,
} from "@/website-editor/custom-fields/margin";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import { Separator as SeparatorComponent } from "@/components/ui/separator";
import { paddingVariants } from "@/website-editor/custom-fields/padding";

export type SeparatorProps = {
  theme: Theme;
  marginTop: Margin;
  marginBottom: Margin;
  marginX: Margin;
};

export const Separator = {
  displayName: "Ayraç",
  fields: {
    theme: ThemeField,
    marginTop: {
      ...MarginField,
      label: "Üst Kenar Boşluğu",
    },
    marginBottom: {
      ...MarginField,
      label: "Alt Kenar Boşluğu",
    },
    marginX: {
      ...MarginField,
      label: "Yan Kenar Boşlukları",
    },
  },
  defaultProps: {
    theme: "light",
    marginTop: "none",
    marginBottom: "none",
    marginX: "none",
  },
  render: ({ marginTop, marginBottom, marginX, theme }) => {
    return (
      <div
        className={cn(
          "relative",
          paddingVariants({ side: "left", size: marginX }),
          paddingVariants({ side: "right", size: marginX }),
        )}
      >
        <SeparatorComponent
          className={cn(
            "box-border",
            marginVariants({ side: "top", size: marginTop }),
            marginVariants({ side: "bottom", size: marginBottom }),

            {
              "bg-gray-600": theme === "dark",
            },
          )}
        />
      </div>
    );
  },
} satisfies ComponentConfig<SeparatorProps>;
