import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import { cn, hsv2hex } from "@/lib/utils";
import Image from "next/image";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { HarmonyCustomField } from "@/website-editor/custom-fields/harmony";

export type TestimonialsSectionProps = {
  sectionId?: string;
  title: string;
  description: string;
  testimonials: Array<{
    comment: string;
    authorName: string;
    authorTitle: string;
    authorImage: {
      url: string;
      alt?: string;
    } | null;
    imageRoundness: Rounded;
  }>;
  backgroundImage: {
    url: string;
    alt?: string;
  } | null;
  testimonialBorder: boolean;
  customTestimonialBackgroundColor?: any;
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const TestimonialsSection = {
  displayName: "Referans Yorumlar Bölümü",
  fields: {
    sectionId: SectionIdField,
    title: {
      type: "textarea",
      label: "Başlık",
    },
    description: {
      type: "richtextarea",
      label: "Açıklama",
    },
    testimonials: {
      type: "array",
      label: "Yorumlar",
      getItemSummary: (item, idx) =>
        item.authorName || `Yorumcu ${(idx ?? 0) + 1}`,
      arrayFields: {
        comment: {
          type: "richtextarea",
          label: "Yorum",
        },
        authorName: {
          type: "textarea",
          label: "Yorumcu İsmi",
        },
        authorTitle: {
          type: "textarea",
          label: "Yorumcu Ünvanı",
        },
        authorImage: {
          ...ImageCustomField,
          label: "Yorumcu Resmi",
        },
        imageRoundness: {
          ...RoundedField,
          label: "Resim Yuvarlaklığı",
        },
      },
      defaultItemProps: {
        comment: "Yorum",
        authorName: "İsim",
        authorTitle: "Ünvan",
        authorImage: {
          url: "https://i.pinimg.com/originals/72/c9/38/72c938ba19bd22ccbb5d60c151414c2d.jpg",
        },
        imageRoundness: "full",
      },
    },
    testimonialBorder: {
      type: "radio",
      label: "Yorum Kenarlıkları",
      options: [
        { label: "Evet", value: true },
        { label: "Hayır", value: false },
      ],
    },
    customTestimonialBackgroundColor: {
      ...HarmonyCustomField,
      label: "Özel Yorum Arkaplan Rengi",
    },
    theme: ThemeField,
    backgroundImage: {
      ...ImageCustomField,
      label: "Arkaplan Resmi",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    paddingLeft: {
      ...PaddingField,
      label: "Sol Kenar Boşluğu",
    },
    paddingRight: {
      ...PaddingField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    theme: "light",
    testimonialBorder: true,
    title: "Görüşler",
    sectionId: "",
    paddingTop: "lg",
    description: "Binlerce harika öğrenciyle çalıştık",
    paddingLeft: "lg",
    paddingRight: "lg",
    testimonials: [
      {
        comment:
          "Uzun zamandır farklı kurlar arasında geçişler yaparak kendi kendime döviz piyasasını anlamaya çalışıyordum. Ne kadar zaman kaybettiğimi gördüm. Şiddetle tavsiye ederim..",
        authorName: "Ece A.",
        authorImage: {
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/dagoabvawb8dhamw46cgircu/bireysel.jpg",
        },
        authorTitle: "Matematik Öğretmeni",
        imageRoundness: "full",
      },
      {
        comment:
          "Borsa kuruyla karmaşık finansal ilişkileri anlaşılır şekilde öğrendim. Dersler çok pratikti. Yarım anladığım birçok parça yerine oturdu.",
        authorName: "Ayşe Kandemir",
        authorImage: {
          url: "https://cdn.pi.education/clsvtgvrz0030t45w0qks2vat/mky6bgzwwfswwd3ywf4gegzb/_38c08658-a80f-4b67-8c8d-462f99fef536.jpg",
        },
        authorTitle: "Moleküler Biyoloji ve Genetik Profesörü",
        imageRoundness: "full",
      },
      {
        comment:
          "Borsa ve Kripto kursunu aldım, hala dönüp tekrar edip videoları, makaleleri, soruları gözden geçirdiğim olur.",
        authorName: "Hakan Okutan",
        authorImage: {
          url: "https://i.pinimg.com/originals/72/c9/38/72c938ba19bd22ccbb5d60c151414c2d.jpg",
        },
        authorTitle: "IT Uzmanı",
        imageRoundness: "full",
      },
    ],
    paddingBottom: "lg",
    backgroundImage: null,
  },
  render: ({
    sectionId,
    testimonials,
    title,
    description,
    theme,
    testimonialBorder = true,
    customTestimonialBackgroundColor,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    backgroundImage,
  }) => {
    return (
      <div
        id={sectionId || undefined}
        className={cn(
          "@container relative isolate overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: paddingLeft }),
          paddingVariants({ side: "right", size: paddingRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        {backgroundImage && (
          <Image
            src={backgroundImage?.url ?? ""}
            alt={backgroundImage?.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!backgroundImage?.url.includes(".pi.education/")}
            className={cn(
              "absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm",
              mutedTextColorVariants({ theme }),
            )}
          />
        )}
        <div className="mx-auto max-w-7xl px-6 @5xl:px-8">
          <div className="mx-auto max-w-xl text-center">
            <h2
              className={cn(
                "text-lg font-semibold leading-8 tracking-tight text-indigo-600 whitespace-pre-line break-words",
                textColorVariants({ theme }),
              )}
            >
              {title}
            </h2>

            <div
              className={cn(
                " mt-2 text-3xl font-bold tracking-tight @2xl:text-4xl whitespace-pre-line break-words",
                textColorVariants({ theme }),
              )}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="mx-auto mt-16 flow-root max-w-2xl @5xl:mx-0 @5xl:max-w-none @2xl:mt-20">
            <div className="-mt-8 @5xl:columns-3 @2xl:-mx-4 @2xl:columns-2">
              {testimonials.map((testimonial, index) => (
                <figure
                  key={index}
                  className={cn(
                    "p-4 @2xl:p-6 rounded-lg mt-4 @2xl:inline-block @2xl:w-full text-sm leading-6",
                    {
                      border: testimonialBorder,
                    },
                  )}
                  style={{
                    ...(customTestimonialBackgroundColor?.length > 0
                      ? {
                          backgroundColor: hsv2hex(
                            customTestimonialBackgroundColor[0].hue,
                            customTestimonialBackgroundColor[0].saturation,
                            customTestimonialBackgroundColor[0].value,
                          ),
                        }
                      : {}),
                  }}
                >
                  <blockquote
                    className={cn(
                      "whitespace-pre-line break-words",
                      textColorVariants({ theme }),
                    )}
                    dangerouslySetInnerHTML={{ __html: testimonial.comment }}
                  />
                  <figcaption className="mt-4 flex items-center gap-x-4">
                    {testimonial.authorImage && (
                      <Image
                        src={testimonial.authorImage.url}
                        alt={testimonial.authorImage.alt ?? ""}
                        width={1080}
                        height={1080}
                        unoptimized={
                          !testimonial.authorImage.url.includes(
                            ".pi.education/",
                          )
                        }
                        className={cn(
                          "size-10",
                          roundedVariants({
                            rounded: testimonial.imageRoundness,
                          }),
                        )}
                      />
                    )}
                    <div className="min-w-0 flex-1">
                      <p
                        className={cn(
                          "font-semibold leading-4 truncate",
                          textColorVariants({ theme }),
                        )}
                      >
                        {testimonial.authorName}
                      </p>
                      <p
                        className={cn(
                          "truncate",
                          mutedTextColorVariants({ theme }),
                        )}
                      >
                        {testimonial.authorTitle}
                      </p>
                    </div>
                  </figcaption>
                </figure>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
} satisfies ComponentConfig<TestimonialsSectionProps>;
