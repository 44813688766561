"use client";

import {
  graphql,
  useMutation,
  usePreloadedQuery,
  useRelayEnvironment,
} from "react-relay";
import useSerializablePreloadedQuery from "@/relay/useSerializablePreloadedQuery";
import { SerializablePreloadedQuery } from "@/relay/loadSerializableQuery";
import PurchasePriceAndActionsQueryNode, {
  PurchasePriceAndActionsQuery,
} from "@generated/PurchasePriceAndActionsQuery.graphql";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
} from "@/website-editor/custom-fields/theme";
import { Badge } from "@/components/ui/badge";
import { cn, formatMoney } from "@/lib/utils";
import { PurchasePriceAndActionsCreateOrderMutation } from "@generated/PurchasePriceAndActionsCreateOrderMutation.graphql";
import { Button } from "@/components/ui/button";
import { useOrganization } from "@/student/context/OrganizationContext";
import { useToast } from "@/components/ui/use-toast";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import getPiHref from "@/components/utils/getPiHref";
import PiLink from "@/components/PiLink";
import { isFuture, parseISO } from "date-fns";

const PurchasePriceAndActions: React.FC<{
  preloadedQuery: SerializablePreloadedQuery<
    typeof PurchasePriceAndActionsQueryNode,
    PurchasePriceAndActionsQuery
  >;
  unauthorizedRedirectUrl?: string;
  theme: Theme;
}> = ({ preloadedQuery, unauthorizedRedirectUrl, theme }) => {
  const organization = useOrganization();
  const environment = useRelayEnvironment();
  const { toast } = useToast();

  const queryRef = useSerializablePreloadedQuery(environment, preloadedQuery);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const data = usePreloadedQuery(
    graphql`
      query PurchasePriceAndActionsQuery($id: ID!) {
        node(id: $id) {
          ... on CourseAcademicTerm {
            id
            slug
            viewerCanOrder
            originalPrice {
              amount
              currency
            }
            discountedPrice {
              amount
              currency
            }
            viewerStudent {
              accessExpiresAt
            }
            viewerNextItem {
              slug
            }
            course {
              organization {
                slug
              }
              slug
            }
            remainingCapacity
          }
        }
      }
    `,
    queryRef,
  );

  const [commitCreateOrder, isCreateOrderInFlight] =
    useMutation<PurchasePriceAndActionsCreateOrderMutation>(graphql`
      mutation PurchasePriceAndActionsCreateOrderMutation(
        $input: CreateOrderInput!
      ) {
        createOrder(input: $input) {
          order {
            id
            slug
            ...OrderPage_order
          }
          userErrors {
            code
            field
            message
          }
        }
      }
    `);

  if (!data) {
    return <p>No data</p>;
  }

  if (!data.node) {
    return <p>No node</p>;
  }

  const courseAcademicTerm = data.node;

  if (
    !courseAcademicTerm.discountedPrice ||
    !courseAcademicTerm.originalPrice
  ) {
    return <p>No discounted price</p>;
  }

  const hasDiscount =
    parseFloat(courseAcademicTerm.discountedPrice.amount) <
    parseFloat(courseAcademicTerm.originalPrice.amount);

  const isAuthorized = Boolean(courseAcademicTerm.viewerCanOrder);

  const alreadyHasAccess =
    courseAcademicTerm.viewerStudent &&
    (!courseAcademicTerm.viewerStudent.accessExpiresAt ||
      isFuture(parseISO(courseAcademicTerm.viewerStudent.accessExpiresAt)));

  return (
    <>
      {hasDiscount && <Badge variant="default">İndirim</Badge>}
      <div className="flex items-center">
        <p
          className={cn(
            "font-display font-semibold text-2xl xl:text-4xl",
            textColorVariants({ theme }),
          )}
        >
          {formatMoney(courseAcademicTerm.discountedPrice)}
        </p>
        {hasDiscount && (
          <div className="pl-3">
            <p
              className={cn(
                "font-display text-base line-through",
                mutedTextColorVariants({ theme }),
              )}
            >
              {formatMoney(courseAcademicTerm.originalPrice)}
            </p>
            <p className="font-display text-xs text-primary xl:text-sm">
              %
              {Math.round(
                ((parseFloat(courseAcademicTerm.originalPrice.amount) -
                  parseFloat(courseAcademicTerm.discountedPrice.amount)) /
                  parseFloat(courseAcademicTerm.originalPrice.amount)) *
                  100,
              )}{" "}
              indirim
            </p>
          </div>
        )}
      </div>
      {alreadyHasAccess ? (
        <Button size="lg" className="mt-3 w-full" asChild>
          <PiLink
            href={`/${courseAcademicTerm.course?.organization.slug}/dersler/ogren/${courseAcademicTerm.course?.slug}/${courseAcademicTerm?.slug}${courseAcademicTerm?.viewerNextItem ? `/${courseAcademicTerm?.viewerNextItem.slug}` : ""}`}
          >
            Derse Git
          </PiLink>
        </Button>
      ) : isAuthorized ? (
        <Button
          size="lg"
          className="mt-3 w-full"
          onClick={() => {
            if (isCreateOrderInFlight) {
              return;
            }

            commitCreateOrder({
              variables: {
                input: {
                  orderableId: courseAcademicTerm.id,
                  organizationId: organization.id,
                },
              },
              updater: (store, mutationData) => {
                if (!mutationData) {
                  return;
                }

                if (mutationData.createOrder.userErrors.length > 0) {
                  toast({
                    title: "Sipariş oluşturulamadı",
                    description:
                      mutationData.createOrder.userErrors[0]?.message,
                  });

                  return;
                }

                if (mutationData.createOrder.order) {
                  toast({
                    title: "Sipariş sayfasına yönlendiriliyorsunuz...",
                  });

                  const order = store.get(mutationData.createOrder.order.id)!;

                  store.getRoot().setLinkedRecord(order, "order", {
                    slug: mutationData.createOrder.order.slug,
                  });

                  router.push(
                    getPiHref(
                      `/${organization.slug}/siparis/${mutationData.createOrder.order.slug}`,
                    ),
                  );
                }
              },
            });
          }}
          loading={isCreateOrderInFlight}
        >
          Hemen Al
        </Button>
      ) : (
        <Button size="lg" className="mt-3 w-full" asChild>
          <PiLink
            href={`${unauthorizedRedirectUrl}?next=${encodeURIComponent(
              pathname + (searchParams ? `?${searchParams}` : ""),
            )}`}
          >
            Almak İçin Giriş Yap
          </PiLink>
        </Button>
      )}
    </>
  );
};

export default PurchasePriceAndActions;
