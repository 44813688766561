import { ComponentConfig } from "@/website-editor/core";
import { ImageCustomField } from "@/website-editor/custom-fields/image";
import NextImage from "next/image";
import {
  Rounded,
  RoundedField,
  roundedVariants,
} from "@/website-editor/custom-fields/rounded";
import { cn } from "@/lib/utils";
import {
  Margin,
  MarginField,
  marginVariants,
} from "@/website-editor/custom-fields/margin";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import PiLink from "@/components/PiLink";

export type ImageProps = {
  sectionId?: string;
  image: { url: string; alt?: string } | null;
  url?: string;
  width: number | null;
  rounded: Rounded;
  marginTop: Margin;
  marginBottom: Margin;
  marginLeft: Margin;
  marginRight: Margin;
};

export const Image = {
  displayName: "Resim",
  fields: {
    sectionId: SectionIdField,
    image: {
      ...ImageCustomField,
      label: "Image",
    },
    url: {
      type: "text",
      label: "URL",
    },
    width: {
      type: "number",
      label: "Genişlik (px) - Full genişlik için boş bırakın",
    },
    rounded: RoundedField,
    marginTop: {
      ...MarginField,
      label: "Üst Boşluk",
    },
    marginBottom: {
      ...MarginField,
      label: "Alt Boşluk",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
      alt: "",
    },
    width: null,
    rounded: "none",
    marginTop: "none",
    marginBottom: "none",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    sectionId,
    image,
    url,
    width,
    rounded,
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
  }) => {
    if (!image) return <></>;

    if (url) {
      return (
        <PiLink
          href={url}
          id={sectionId || undefined}
          className={cn(
            "flex-1",
            roundedVariants({ rounded }),
            marginVariants({ side: "top", size: marginTop }),
            marginVariants({ side: "bottom", size: marginBottom }),
            marginVariants({ side: "left", size: marginLeft }),
            marginVariants({ side: "right", size: marginRight }),
          )}
        >
          <NextImage
            id={sectionId || undefined}
            src={image.url}
            alt={image.alt ?? ""}
            width={1080}
            height={1080}
            unoptimized={!image.url.includes(".pi.education/")}
            className={cn(roundedVariants({ rounded }))}
            style={{ width: width ? `${width}px` : "100%" }}
          />
        </PiLink>
      );
    }

    return (
      <NextImage
        id={sectionId || undefined}
        src={image.url}
        alt={image.alt ?? ""}
        width={1080}
        height={1080}
        unoptimized={!image.url.includes(".pi.education/")}
        className={cn(
          "object-cover flex-1",
          roundedVariants({ rounded }),
          marginVariants({ side: "top", size: marginTop }),
          marginVariants({ side: "bottom", size: marginBottom }),
          marginVariants({ side: "left", size: marginLeft }),
          marginVariants({ side: "right", size: marginRight }),
        )}
        style={{ width: width ? `${width}px` : "100%" }}
      />
    );
  },
} satisfies ComponentConfig<ImageProps>;
