"use client";

import { useEffect, useMemo, useState } from "react";
import { Gradient } from "@/components/gradient-mesh";
import { hsv2hex, hsv2rgb } from "@/lib/utils";

const BackgroundGradient: React.FC<{
  id: string;
  harmony: Array<{
    hue: number;
    saturation: number;
    value: number;
  }>;
}> = ({ id, harmony }) => {
  const gradient = useMemo(() => new Gradient(), []);
  const [resetToggle, setResetToggle] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gradient.initGradient(`#gradient-canvas-${id}`);
  }, [gradient, id]);

  useEffect(() => {
    if (!gradient.material) {
      setTimeout(() => {
        setResetToggle(!resetToggle);
      }, 1000 / 60);
      return;
    }

    const newColors = harmony.map((color) =>
      hsv2rgb(color.hue, color.saturation, color.value),
    );

    gradient.material.uniforms["u_baseColor"].value = newColors[0];

    for (let index = 0; index < newColors.length; index++) {
      gradient.material.uniforms["u_waveLayers"].value[
        index
      ].value.color.value = newColors[(index + 1) % newColors.length];
    }
  }, [harmony, gradient, resetToggle]);

  return (
    <canvas
      className="absolute inset-0 z-[-1] size-full"
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "--gradient-color-1": harmony?.[0]
          ? hsv2hex(harmony[0].hue, harmony[0].saturation, harmony[0].value)
          : "#aaa",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "--gradient-color-2": harmony?.[1]
          ? hsv2hex(harmony[1].hue, harmony[1].saturation, harmony[1].value)
          : "#ccc",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "--gradient-color-3": harmony?.[2]
          ? hsv2hex(harmony[2].hue, harmony[2].saturation, harmony[2].value)
          : "#ddd",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "--gradient-color-4": harmony?.[0]
          ? hsv2hex(harmony[0].hue, harmony[0].saturation, harmony[0].value)
          : "#fff",
      }}
      id={`gradient-canvas-${id}`}
    />
  );
};

export default BackgroundGradient;
