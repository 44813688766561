"use client";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePuck } from "@/website-editor/core";
import { Button } from "@/components/ui/button";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useOrganization } from "@/student/context/OrganizationContext";
import { type InsertDialogComponentTemplatesQuery as InsertDialogComponentTemplatesQueryType } from "@generated/InsertDialogComponentTemplatesQuery.graphql";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import WebPageComponentTemplate from "@/website-editor/core/components/WebPageComponentTemplate";
import DefaultComponentTemplate from "@/website-editor/core/components/DefaultComponentTemplate";
import DeleteWebPageComponentTemplateDialog from "@/website-editor/core/components/DeleteWebPageComponentTemplateDialog";

export const InsertDialogComponentTemplatesQuery = graphql`
  query InsertDialogComponentTemplatesQuery(
    $slug: String!
    $component: String!
  ) {
    organization(slug: $slug) {
      webPageComponentTemplates(component: $component) {
        id
        title
        content
        ...WebPageComponentTemplate
      }
    }
    publicWebPageComponentTemplates(component: $component) {
      id
      title
      content
      ...WebPageComponentTemplate
    }
  }
`;

const InsertDialog: React.FC<{
  elementToInsert: {
    componentType: string;
    destinationIndex: number;
    destinationZone: string;
  } | null;
  resetElementToInsert: () => void;
}> = ({ elementToInsert, resetElementToInsert }) => {
  return (
    <Dialog
      open={elementToInsert !== null}
      onOpenChange={(open) => {
        if (!open) {
          resetElementToInsert();
        }
      }}
    >
      <DialogContent className="sm:max-w-5xl">
        {elementToInsert && (
          <Suspense fallback="Şablonlar yükleniyor...">
            <InsertDialogContent
              elementToInsert={elementToInsert}
              onInsert={resetElementToInsert}
            />
          </Suspense>
        )}
      </DialogContent>
    </Dialog>
  );
};

const InsertDialogContent: React.FC<{
  elementToInsert: {
    componentType: string;
    destinationIndex: number;
    destinationZone: string;
  };
  onInsert: () => void;
}> = ({ elementToInsert, onInsert }) => {
  const { dispatch } = usePuck();

  const { slug, id } = useOrganization();

  const [removeTemplateDialogOpen, setRemoveTemplateDialogOpen] =
    useState(false);
  const onWebPageComponentTemplateDeleted = useCallback(() => {
    setRemoveTemplateDialogOpen(false);
  }, []);

  const data = useLazyLoadQuery<InsertDialogComponentTemplatesQueryType>(
    InsertDialogComponentTemplatesQuery,
    { slug, component: elementToInsert.componentType },
    { fetchPolicy: "store-or-network" },
  );

  useEffect(() => {
    if (
      data &&
      data.organization?.webPageComponentTemplates.length === 0 &&
      data.publicWebPageComponentTemplates.length === 0
    ) {
      dispatch({
        type: "insert",
        ...elementToInsert,
      });

      dispatch({
        type: "setUi",
        ui: {
          itemSelector: {
            index: elementToInsert.destinationIndex,
            zone: elementToInsert.destinationZone,
          },
        },
        recordHistory: true,
      });
      onInsert();
    }
  }, [data, dispatch, elementToInsert, onInsert]);

  if (!data) {
    return <p>Şablonlar yükleniyor...</p>;
  }

  return (
    <>
      {data.organization?.webPageComponentTemplates.length ? (
        <section className="mb-6">
          <h3 className="mb-4 text-lg font-semibold leading-none tracking-tight">
            Kaydettiğim Şablonlar
          </h3>
          {data.organization.webPageComponentTemplates.map((template) => (
            <React.Fragment key={template.id}>
              <div className="mb-2 flex items-center gap-x-4">
                <h4 className="text-base font-semibold leading-none tracking-tight">
                  {template.title}
                </h4>
                <Button
                  size="sm"
                  onClick={() => {
                    dispatch({
                      type: "insertWithTemplate",
                      ...elementToInsert,
                      template: template.content,
                    });

                    dispatch({
                      type: "setUi",
                      ui: {
                        itemSelector: {
                          index: elementToInsert.destinationIndex,
                          zone: elementToInsert.destinationZone,
                        },
                      },
                      recordHistory: true,
                    });
                    onInsert();
                  }}
                >
                  Bu Şablonu Kullan
                </Button>
                <Button
                  size="sm"
                  variant="destructive"
                  onClick={() => {
                    setRemoveTemplateDialogOpen(true);
                  }}
                >
                  Bu Şablonu Sil
                </Button>
                <Dialog
                  open={removeTemplateDialogOpen}
                  onOpenChange={setRemoveTemplateDialogOpen}
                >
                  <DeleteWebPageComponentTemplateDialog
                    webPageComponentTemplateId={template.id}
                    onWebPageComponentTemplateDeleted={
                      onWebPageComponentTemplateDeleted
                    }
                    componentType={elementToInsert.componentType}
                    organizationId={id}
                  />
                </Dialog>
              </div>
              <WebPageComponentTemplate
                webPageComponentTemplate={template}
                className="rounded-xl border bg-card p-2"
              />
            </React.Fragment>
          ))}
        </section>
      ) : null}

      <section>
        <h3 className="mb-4 text-lg font-semibold leading-none tracking-tight">
          Örnek Şablonlar
        </h3>
        {data.publicWebPageComponentTemplates.map((template) => (
          <React.Fragment key={template.id}>
            <div className="mb-2 flex items-center gap-x-4">
              <h4 className="text-base font-semibold leading-none tracking-tight">
                {template.title}
              </h4>
              <Button
                size="sm"
                onClick={() => {
                  dispatch({
                    type: "insertWithTemplate",
                    ...elementToInsert,
                    template: template.content,
                  });

                  dispatch({
                    type: "setUi",
                    ui: {
                      itemSelector: {
                        index: elementToInsert.destinationIndex,
                        zone: elementToInsert.destinationZone,
                      },
                    },
                    recordHistory: true,
                  });
                  onInsert();
                }}
              >
                Bu Şablonu Kullan
              </Button>
            </div>
            <WebPageComponentTemplate
              webPageComponentTemplate={template}
              className="rounded-xl border bg-card p-2"
            />
          </React.Fragment>
        ))}
        <div className="mb-2 flex items-center gap-x-4">
          <h4 className="text-base font-semibold leading-none tracking-tight">
            Varsayılan Şablon
          </h4>
          <Button
            size="sm"
            onClick={() => {
              dispatch({
                type: "insert",
                ...elementToInsert,
              });

              dispatch({
                type: "setUi",
                ui: {
                  itemSelector: {
                    index: elementToInsert.destinationIndex,
                    zone: elementToInsert.destinationZone,
                  },
                },
                recordHistory: true,
              });
              onInsert();
            }}
          >
            Bu Şablonu Kullan
          </Button>
        </div>
        <DefaultComponentTemplate
          componentType={elementToInsert.componentType}
          className="rounded-xl border bg-card p-2"
        />
      </section>
    </>
  );
};

export default InsertDialog;
