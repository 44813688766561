import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import {
  mutedTextColorVariants,
  textColorVariants,
  Theme,
  ThemeField,
} from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";

import CoachingSessionPlansAndActions from "@/website-editor/blocks/coaching-session-purchase-section/CoachingSessionPlansAndActions";
import ResolveDataComponentHelper from "@/website-editor/components/ResolveDataComponentHelper";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import loadSerializableQuery from "@/relay/loadSerializableQuery";
import CoachingSessionPlansAndActionsQueryNode, {
  CoachingSessionPlansAndActionsQuery,
} from "@generated/CoachingSessionPlansAndActionsQuery.graphql";
import { getCoachingSessionField } from "@/website-editor/custom-fields/coachingSession";
import React from "react";

export type CoachingSessionPurchaseSectionProps = {
  sectionId?: string;
  title: string;
  description: string;
  unauthorizedRedirectUrl: string;
  coachingSession: { id?: string; __preloadedQuery?: any };
  plansStyle: "card" | "borderless";
  theme: Theme;
  paddingLeft: Padding;
  paddingRight: Padding;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export function getCoachingSessionPurchaseSection(organization: {
  slug: string;
}) {
  return {
    displayName: "Birebir Görüşme Satın Alma Bölümü",
    tooltip:
      "Birebir görüşme planlarını ve plandan sipariş oluşturup ödeme sayfasına yönlendiren butonlar içerir.",
    fields: {
      sectionId: SectionIdField,
      coachingSession: getCoachingSessionField(organization),
      title: {
        type: "textarea",
        label: "Başlık",
      },
      description: {
        type: "richtextarea",
        label: "Açıklama",
      },
      unauthorizedRedirectUrl: {
        type: "text",
        label: "Giriş Yapılmamış Kullanıcı Yönlendirme URL'i",
      },
      plansStyle: {
        type: "radio",
        label: "Plan Tarzı",
        options: [
          {
            value: "card",
            label: "Kart",
          },
          {
            value: "borderless",
            label: "Kenarlıksız",
          },
        ],
      },
      theme: ThemeField,
      paddingLeft: {
        ...PaddingField,
        label: "Sol Kenar Boşluğu",
      },
      paddingRight: {
        ...PaddingField,
        label: "Sağ Kenar Boşluğu",
      },
      paddingTop: {
        ...PaddingField,
        label: "Üst Kenar Boşluğu",
      },
      paddingBottom: {
        ...PaddingField,
        label: "Alt Kenar Boşluğu",
      },
    },
    defaultProps: {
      sectionId: "",
      plansStyle: "card",
      theme: "light",
      coachingSession: {},
      unauthorizedRedirectUrl: "/giris",
      title: "Kaliteli eğitim artık bir ayrıcalık değil!",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      paddingLeft: "lg",
      paddingRight: "lg",
      paddingTop: "xl",
      paddingBottom: "xl",
    },
    resolveData: async (data, { changed }) => {
      if (!data.props.coachingSession?.id) {
        return data;
      }

      if (!changed.coachingSession) {
        return data;
      }

      const preloadedQuery = await loadSerializableQuery<
        typeof CoachingSessionPlansAndActionsQueryNode,
        CoachingSessionPlansAndActionsQuery
      >(CoachingSessionPlansAndActionsQueryNode.params, {
        id: data.props.coachingSession.id,
      });

      return {
        ...data,
        props: {
          ...data.props,
          coachingSession: {
            ...data.props.coachingSession,
            __preloadedQuery: preloadedQuery,
          },
        },
      };
    },
    render: ({
      puck: { renderDropZone },
      sectionId,
      title,
      description,
      unauthorizedRedirectUrl,
      plansStyle = "card",
      theme = "light",
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      coachingSession,
    }) => {
      return (
        <section
          id={sectionId || undefined}
          className={cn(
            "bg-background",
            paddingVariants({ side: "top", size: paddingTop }),
            paddingVariants({ side: "bottom", size: paddingBottom }),
            paddingVariants({ side: "left", size: paddingLeft }),
            paddingVariants({ side: "right", size: paddingRight }),
            {
              dark: theme === "dark",
            },
          )}
        >
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2
                className={cn(
                  "text-3xl font-bold tracking-tight sm:text-4xl break-words whitespace-pre-line",
                  textColorVariants({ theme }),
                )}
              >
                {title}
              </h2>
              <div
                className={cn(
                  "mt-2 text-lg leading-8 break-words whitespace-pre-line",
                  mutedTextColorVariants({ theme }),
                )}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <ResolveDataComponentHelper
              data={coachingSession}
              Empty={
                <p
                  className={cn(
                    "text-lg leading-8",
                    textColorVariants({ theme }),
                  )}
                >
                  Bir birebir görüşme seçin
                </p>
              }
              Loading={
                <p
                  className={cn(
                    "text-lg leading-8",
                    mutedTextColorVariants({ theme }),
                  )}
                >
                  Yükleniyor...
                </p>
              }
            >
              <CoachingSessionPlansAndActions
                plansStyle={plansStyle}
                preloadedQuery={coachingSession?.__preloadedQuery}
                unauthorizedRedirectUrl={unauthorizedRedirectUrl}
                theme={theme}
                zones={coachingSession?.__preloadedQuery?.response.data.node.plans.reduce(
                  (zones: any, plan: any) => {
                    zones[plan.id] = renderDropZone({
                      zone: plan.id,
                      className: "mb-4",
                    });

                    return zones;
                  },
                  {},
                )}
              />
            </ResolveDataComponentHelper>
          </div>
        </section>
      );
    },
  } satisfies ComponentConfig<CoachingSessionPurchaseSectionProps>;
}
