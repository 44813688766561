"use client";

import { CustomField } from "@/website-editor/core";
import { Pencil2Icon } from "@radix-ui/react-icons";
import dynamic from "next/dynamic";
import { useOrganization } from "@/student/context/OrganizationContext";
import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogFooter,
} from "@/components/ui/alert-dialog";
import { AlertDialogTrigger } from "@radix-ui/react-alert-dialog";
import { Button } from "@/components/ui/button";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

export type PlateValue = any;

const FieldRenderer: React.FC<
  Parameters<CustomField<PlateValue>["render"]>[0]
> = ({ id, field, name, value, onChange, readOnly }) => {
  const { id: organizationId } = useOrganization();

  return (
    <div>
      <div className="flex items-center">
        <Pencil2Icon className="mr-2 inline size-4 text-gray-400" />
        {field.label}
      </div>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="outline">Güncelle</Button>
        </AlertDialogTrigger>
        <AlertDialogContent className="sm:max-w-5xl">
          <PlateEditor
            initialValue={value}
            hideFloatingOptionsFromFixedToolbar
            {...(readOnly
              ? {
                  readOnly: true,
                }
              : {
                  organizationId: organizationId,
                  onChange: onChange,
                  readOnly: false,
                })}
          />
          <AlertDialogFooter>
            <AlertDialogAction>Kaydet</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export const PlateCustomField = {
  type: "custom",
  label: "Zengin Metin",
  render: (props) => <FieldRenderer {...props} />,
} satisfies CustomField<PlateValue>;
