/**
 * @generated SignedSource<<ad6793c80a59681b015fd446f25ba76a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type WebPageComponentTemplate$data = {
  readonly component: string;
  readonly content: Record<string, any>;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly isPublicTemplate: boolean;
  readonly title: string;
  readonly " $fragmentType": "WebPageComponentTemplate";
};
export type WebPageComponentTemplate$key = {
  readonly " $data"?: WebPageComponentTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"WebPageComponentTemplate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebPageComponentTemplate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "component",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublicTemplate",
      "storageKey": null
    }
  ],
  "type": "WebPageComponentTemplate",
  "abstractKey": null
};

(node as any).hash = "8de1a84a53b3659b7fbca2d6fa45ff9e";

export default node;
