import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";
import { Margin, MarginField } from "@/website-editor/custom-fields/margin";
import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { Gap, GapField, gapVariants } from "@/website-editor/custom-fields/gap";

export type TwoColumnLayoutProps = {
  sectionId?: string;
  mainSide: "left" | "right";
  theme: Theme;
  gapX: Gap;
  gapY: Gap;
  paddingTop: Padding;
  paddingBottom: Padding;
  marginLeft: Margin;
  marginRight: Margin;
};

export const TwoColumnLayout = {
  displayName: "İki Sütunlu Düzen",
  tooltip:
    "Bu düzeni kullanarak iki sütunlu bir düzen oluşturabilirsiniz. Örneğin ders satış sayfasında dersin tanıtımını bir tarafta, ders içeriği ve satış butonunu diğer tarafta göstermek için kullanabilirsiniz.",
  fields: {
    sectionId: SectionIdField,
    theme: ThemeField,
    mainSide: {
      type: "radio",
      label: "Yan Kenar",
      options: [
        { label: "Sol", value: "left" },
        { label: "Sağ", value: "right" },
      ],
    },
    gapX: {
      ...GapField,
      label: "Yatay Boşluk",
    },
    gapY: {
      ...GapField,
      label: "Dikey Boşluk",
    },
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
    marginLeft: {
      ...MarginField,
      label: "Sol Kenar Boşluğu",
    },
    marginRight: {
      ...MarginField,
      label: "Sağ Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    theme: "light",
    mainSide: "left",
    gapX: "md",
    gapY: "md",
    paddingTop: "lg",
    paddingBottom: "lg",
    marginLeft: "none",
    marginRight: "none",
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    mainSide,
    gapX = "md",
    gapY = "md",
    paddingTop,
    paddingBottom,
    marginLeft,
    marginRight,
    theme,
  }) => {
    return (
      <div
        id={sectionId || undefined}
        className={cn(
          "relative isolate overflow-clip bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          paddingVariants({ side: "left", size: marginLeft }),
          paddingVariants({ side: "right", size: marginRight }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div className={cn("mx-auto max-w-7xl")}>
          <div
            className={cn(
              "grid grid-cols md:grid-rows-[auto_minmax(0,_1fr)]",
              gapVariants({ side: "x", size: gapX }),
              gapVariants({ side: "y", size: gapY }),
              {
                "md:grid-cols-[minmax(0,_1fr)_400px] xl:grid-cols-[minmax(0,_1fr)_520px]":
                  mainSide === "left",
                "md:grid-cols-[400px_minmax(0,_1fr)] xl:grid-cols-[520px_minmax(0,_1fr)]":
                  mainSide === "right",
              },
            )}
          >
            {renderDropZone({
              zone: "upper-main",
              className: cn({
                "order-last": mainSide === "right",
              }),
            })}
            {renderDropZone({ zone: "side", className: "md:row-span-2" })}
            {renderDropZone({
              zone: "lower-main",
              className: cn({ "order-last": mainSide === "right" }),
            })}
          </div>
        </div>
      </div>
    );
  },
} satisfies ComponentConfig<TwoColumnLayoutProps>;
