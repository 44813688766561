"use client";

import { motion } from "framer-motion";
import { CTASectionProps } from "@/website-editor/blocks/cta-section/index";
import { cn, hsv2hex } from "@/lib/utils";
import { paddingVariants } from "@/website-editor/custom-fields/padding";
import { fontSizeVariants } from "@/website-editor/custom-fields/fontSize";
import {
  mutedTextColorVariants,
  textColorVariants,
} from "@/website-editor/custom-fields/theme";
import { ButtonsComponent } from "@/website-editor/blocks/button";
import { marginVariants } from "@/website-editor/custom-fields/margin";
import Image from "next/image";
import dynamic from "next/dynamic";

const VideoPlayer = dynamic(() => import("@/components/video-player"), {});

const fadeInAnimationVariants = {
  fromLeft: {
    hidden: { opacity: 0, x: -100 },
    "fade-in-to-center": (index: number = 0) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1 * index,
      },
    }),
  },
  fromRight: {
    hidden: { opacity: 0, x: 100 },
    "fade-in-to-center": (index: number = 0) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1 * index,
      },
    }),
  },
};

const CTASection: React.FC<
  Pick<
    CTASectionProps,
    | "image"
    | "mediaPosition"
    | "contentMediaRatio"
    | "imageOverflowType"
    | "videoUrl"
    | "theme"
    | "title"
    | "titleFontSize"
    | "titleColor"
    | "description"
    | "descriptionColor"
    | "buttons"
    | "buttonsMarginTop"
    | "align"
    | "revealAnimation"
    | "paddingTop"
    | "paddingBottom"
  >
> = ({
  image,
  mediaPosition,
  contentMediaRatio,
  imageOverflowType,
  videoUrl,
  theme,
  title,
  titleFontSize,
  titleColor,
  description,
  descriptionColor,
  buttonsMarginTop,
  buttons,
  align,
  revealAnimation,
  paddingBottom,
  paddingTop,
}) => {
  return (
    <div
      className={cn(
        "mx-auto max-w-7xl",
        paddingVariants({ side: "top", size: paddingTop }),
        paddingVariants({ side: "bottom", size: paddingBottom }),
        {
          "lg:grid lg:gap-x-8": Boolean(image || videoUrl),
          "lg:grid-cols-2":
            Boolean(image || videoUrl) && contentMediaRatio === "1/1",
          "lg:grid-cols-3":
            Boolean(image || videoUrl) &&
            (contentMediaRatio === "1/2" || contentMediaRatio === "2/1"),
          "lg:grid-cols-4":
            Boolean(image || videoUrl) &&
            (contentMediaRatio === "1/3" || contentMediaRatio === "3/1"),
          "lg:grid-cols-5":
            Boolean(image || videoUrl) &&
            (contentMediaRatio === "1/4" ||
              contentMediaRatio === "2/3" ||
              contentMediaRatio === "3/2" ||
              contentMediaRatio === "4/1"),
          "lg:grid-cols-7":
            Boolean(image || videoUrl) &&
            (contentMediaRatio === "3/4" || contentMediaRatio === "4/3"),
        },
      )}
    >
      <motion.div
        className={cn(
          "mx-auto lg:mx-0 lg:flex-auto flex flex-col justify-center",
          {
            "text-center items-center": align === "center",
            "text-left": align === "left",
            "lg:col-span-2":
              Boolean(image || videoUrl) &&
              (contentMediaRatio === "2/3" || contentMediaRatio === "2/1"),
            "lg:col-span-3":
              Boolean(image || videoUrl) &&
              (contentMediaRatio === "3/4" ||
                contentMediaRatio === "3/2" ||
                contentMediaRatio === "3/1"),
            "lg:col-span-4":
              Boolean(image || videoUrl) &&
              (contentMediaRatio === "4/3" || contentMediaRatio === "4/1"),
          },
        )}
        variants={
          !(image || videoUrl) || mediaPosition === "right"
            ? fadeInAnimationVariants.fromLeft
            : fadeInAnimationVariants.fromRight
        }
        initial={revealAnimation === "none" ? undefined : "hidden"}
        whileInView={revealAnimation === "none" ? undefined : revealAnimation}
        viewport={{ once: true }}
      >
        <div
          className={cn(
            "font-bold tracking-tight break-words whitespace-pre-line",
            fontSizeVariants({ fontSize: titleFontSize }),
            {
              [textColorVariants({ theme })]: !titleColor[0],
            },
          )}
          style={
            titleColor[0]
              ? {
                  color: hsv2hex(
                    titleColor[0].hue,
                    titleColor[0].saturation,
                    titleColor[0].value,
                  ),
                }
              : undefined
          }
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {description && (
          <div
            className={cn(
              "mt-6 text-lg leading-8 break-words max-w-prose whitespace-pre-line",
              {
                [mutedTextColorVariants({ theme })]: !descriptionColor[0],
              },
            )}
            style={
              descriptionColor[0]
                ? {
                    color: hsv2hex(
                      descriptionColor[0].hue,
                      descriptionColor[0].saturation,
                      descriptionColor[0].value,
                    ),
                  }
                : undefined
            }
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}

        <ButtonsComponent
          buttons={buttons}
          on={theme}
          className={cn(
            marginVariants({ side: "top", size: buttonsMarginTop }),
            {
              "justify-start": align === "left",
              "justify-center": align === "center",
            },
          )}
        />
      </motion.div>
      {image || videoUrl ? (
        <motion.div
          className={cn("relative flex flex-col justify-center", {
            "items-start lg:order-first lg:ml-0": mediaPosition === "left",
            "lg:mr-0": mediaPosition === "right",
            "lg:col-span-2":
              Boolean(image || videoUrl) &&
              (contentMediaRatio === "1/2" || contentMediaRatio === "3/2"),
            "lg:col-span-3":
              Boolean(image || videoUrl) &&
              (contentMediaRatio === "1/3" ||
                contentMediaRatio === "2/3" ||
                contentMediaRatio === "4/3"),
            "lg:col-span-4":
              Boolean(image || videoUrl) &&
              (contentMediaRatio === "1/4" || contentMediaRatio === "3/4"),
          })}
          variants={
            mediaPosition === "right"
              ? fadeInAnimationVariants.fromRight
              : fadeInAnimationVariants.fromLeft
          }
          initial={revealAnimation === "none" ? undefined : "hidden"}
          whileInView={revealAnimation === "none" ? undefined : revealAnimation}
          viewport={{ once: true }}
        >
          {videoUrl ? (
            <VideoPlayer
              src={videoUrl}
              mode="store"
              className={cn("w-full rounded-md bg-white/5 shadow-2xl ring-1", {
                "ring-white/10": theme === "dark",
                "ring-gray-400/10": theme === "light",
              })}
              muted
            />
          ) : (
            <Image
              src={image!.url}
              alt={image!.alt ?? ""}
              width={1080}
              height={1080}
              unoptimized={!image!.url.includes(".pi.education/")}
              className={cn("rounded-md bg-white/5 shadow-2xl ring-1", {
                "w-full sm:w-[36rem] sm:min-w-[36rem] max-w-none lg:w-[48rem] lg:min-w-[48rem]":
                  imageOverflowType === "overflow",
                "lg:place-self-end":
                  imageOverflowType === "overflow" && mediaPosition === "left",
                "ring-white/10": theme === "dark",
                "ring-gray-400/10": theme === "light",
              })}
            />
          )}
        </motion.div>
      ) : null}
    </div>
  );
};

export default CTASection;
