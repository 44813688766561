"use client";
import React, { useRef } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import Image from "next/image";

export const StickyScroll: React.FC<{
  id: string;
  sections: {
    id: string;
    content?: React.ReactNode;
    image: {
      url: string;
      alt?: string;
    } | null;
    videoUrl: string;
    startColor: string;
    endColor: string;
  }[];
  contentClassName?: string;
}> = ({ id, sections, contentClassName }) => {
  const [activeCard, setActiveCard] = React.useState(0);
  const ref = useRef<any>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    // container: ref,
    offset: ["start start", "end start"],
  });

  const cardLength = sections.length;

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const stickyContent = document.getElementById(
      "sticky-scroll-reveal-sticky-content-" + id,
    );

    const stickyContentTop = stickyContent?.offsetTop || 0;
    const stickyContentHeight = stickyContent?.offsetHeight || 0;

    if (!stickyContentHeight) {
      const currentY = ref.current.offsetHeight * latest;

      const activeCardIndex = sections.findLastIndex(
        ({ id: sectionId }, index) => {
          const cardId = `sticky-scroll-reveal-section-${sectionId}`;
          const card = document.getElementById(cardId);

          if (!card) return index / cardLength <= latest;

          const cardTop = card.offsetTop;

          return cardTop <= currentY;
        },
      );

      if (activeCardIndex !== -1) {
        setActiveCard(activeCardIndex);
      }

      return;
    }

    const activeCardIndex = sections.findLastIndex(
      ({ id: sectionId }, index) => {
        const cardId = `sticky-scroll-reveal-section-${sectionId}`;
        const card = document.getElementById(cardId);

        if (!card) return index / cardLength <= latest;

        const cardTop = card.offsetTop;

        return cardTop <= stickyContentTop;
      },
    );

    setActiveCard(activeCardIndex || 0);
  });

  const activeSection = sections[activeCard];

  return (
    <motion.div className="relative flex space-x-10" ref={ref}>
      <div className="flex-1 px-4">
        {sections.map((section, index) => (
          <motion.div
            key={section.id}
            id={"sticky-scroll-reveal-section-" + section.id}
            animate={{
              opacity: activeCard === index ? 1 : 0.5,
            }}
            className="w-full"
          >
            {section.content}
            {section.image || section.videoUrl ? (
              <div
                className="rounded-md p-8 lg:hidden"
                style={{
                  background: `linear-gradient(to bottom right, ${section.startColor}, ${section.endColor})`,
                }}
              >
                {section.videoUrl ? (
                  <video
                    src={section.videoUrl}
                    autoPlay
                    loop
                    muted
                    className="aspect-video w-full rounded-lg object-cover shadow-xl"
                  />
                ) : (
                  <Image
                    src={section.image!.url}
                    alt={section.image!.alt ?? ""}
                    width={1080}
                    height={1080}
                    unoptimized={!section.image!.url.includes(".pi.education/")}
                    className={cn(
                      "rounded-lg shadow-xl object-cover aspect-video w-full",
                    )}
                  />
                )}
              </div>
            ) : null}
          </motion.div>
        ))}
      </div>
      {activeSection && (activeSection.image || activeSection.videoUrl) ? (
        <motion.div
          id={"sticky-scroll-reveal-sticky-content-" + id}
          animate={{
            background: `linear-gradient(to bottom right, ${activeSection.startColor}, ${activeSection.endColor})`,
          }}
          className={cn(
            "hidden lg:block h-fit w-[30rem] rounded-[calc(var(--radius)+0.5rem)] p-6 flex-shrink-0 top-[calc(50%-9.5rem)] sticky overflow-hidden",
            contentClassName,
          )}
        >
          {activeSection.videoUrl ? (
            <video
              src={activeSection.videoUrl}
              autoPlay
              loop
              muted
              className="aspect-video h-64 rounded-lg object-cover shadow-xl"
            />
          ) : (
            <Image
              src={activeSection.image!.url}
              alt={activeSection.image!.alt ?? ""}
              width={1080}
              height={1080}
              unoptimized={!activeSection.image!.url.includes(".pi.education/")}
              className={cn(
                "rounded-lg shadow-xl object-cover aspect-video h-64",
              )}
            />
          )}
        </motion.div>
      ) : null}
    </motion.div>
  );
};
