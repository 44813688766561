import { ComponentConfig } from "@/website-editor/core";
import { cn } from "@/lib/utils";
import { SectionIdField } from "@/website-editor/custom-fields/sectionId";
import { Slot } from "@radix-ui/react-slot";
import { Theme, ThemeField } from "@/website-editor/custom-fields/theme";
import {
  Padding,
  PaddingField,
  paddingVariants,
} from "@/website-editor/custom-fields/padding";

export type InfiniteScrollCarouselProps = {
  sectionId?: string;
  direction: "right" | "left" | "none";
  theme: Theme;
  paddingTop: Padding;
  paddingBottom: Padding;
};

export const InfiniteScrollCarousel = {
  displayName: "Sonsuz Kaydırma Karoseli",
  tooltip:
    "Bu bloğun içindeki öğeleri sonsuz kaydırma animasyonuyla dönüşümlü olarak gösterebilirsiniz. Örneğin, çok sayıda referansı veya ürünü bu blokla gösterebilirsiniz.",
  fields: {
    sectionId: SectionIdField,
    direction: {
      type: "radio",
      label: "Animasyon Yönü",
      options: [
        {
          value: "right",
          label: "Sağa",
        },
        {
          value: "left",
          label: "Sola",
        },
        {
          value: "none",
          label: "Animasyon Yok",
        },
      ],
    },
    theme: ThemeField,
    paddingTop: {
      ...PaddingField,
      label: "Üst Kenar Boşluğu",
    },
    paddingBottom: {
      ...PaddingField,
      label: "Alt Kenar Boşluğu",
    },
  },
  defaultProps: {
    sectionId: "",
    direction: "none",
    theme: "light",
    paddingTop: "none",
    paddingBottom: "none",
  },
  render: ({
    puck: { renderDropZone },
    sectionId,
    direction,
    theme = "light",
    paddingTop = "none",
    paddingBottom = "none",
  }) => {
    return (
      <section
        id={sectionId || undefined}
        className={cn(
          "w-full bg-background",
          paddingVariants({ side: "top", size: paddingTop }),
          paddingVariants({ side: "bottom", size: paddingBottom }),
          {
            dark: theme === "dark",
          },
        )}
      >
        <div
          className={cn(
            "flex flex-nowrap max-w-7xl mx-auto [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]",
            { "overflow-clip": direction !== "none" },
          )}
        >
          {renderDropZone({
            zone: `zone`,
            direction: "horizontal",
            className: cn(
              "flex max-w-none shrink-0 items-center justify-center md:justify-start",
              {
                "animate-infinite-scroll": direction !== "none",
                "direction-reverse": direction === "right",
              },
            ),
          })}
          {/* Duplicate the list to create the infinite scroll effect */}
          {direction !== "none" &&
            renderDropZone({
              direction: "horizontal",
              zone: `zone`,
              className: cn(
                "flex max-w-none shrink-0 items-center justify-center md:justify-start animate-infinite-scroll",
                {
                  "direction-reverse": direction === "right",
                },
              ),
            })}
        </div>
      </section>
    );
  },
} satisfies ComponentConfig<InfiniteScrollCarouselProps>;
