import { Field } from "@/website-editor/core";
import { courseAcademicTermFetchList } from "@/website-editor/custom-fields/courseAcademicTermFetchList";

export function getCourseAcademicTermField(organization: { slug: string }) {
  return {
    type: "external",
    fetchList: async ({ query, environment }) => {
      if (!environment) {
        return [];
      }

      return courseAcademicTermFetchList({
        query,
        environment,
        organizationSlug: organization.slug,
      });
    },
    mapRow: ({ id, ...other }) => {
      return other;
    },
    mapProp: (item) => {
      return { id: item.id };
    },
    getItemSummary: (item) => {
      if (!item) {
        return "";
      }
      return item.id;
    },
    label: "Ders Akademik Dönemi",
    placeholder: "Seç",
    // showSearch: true,
  } satisfies Field<
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { id: string } | null | undefined
  >;
}
